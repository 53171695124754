import { Tooltip } from 'react-tooltip';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import React from 'react';
import { AiOutlineDownload, AiOutlineFileDone, AiOutlinePicture, AiOutlineVideoCamera } from 'react-icons/ai';
import { LuFileAudio2 } from 'react-icons/lu';

const ResourceCard = ({ resource }) => {
    return <>
        <div className={`${styles.devotional__Card} ${styles.resource__Card}`}>
            {
                resource?.resourceType === 'document' ?
                <AiOutlineFileDone data-tooltip-id={resource._id} data-tooltip-content="Document" />
                :
                resource?.resourceType === 'audio' ?
                <LuFileAudio2 data-tooltip-id={resource._id} data-tooltip-content="Audio" />
                :
                resource?.resourceType === 'picture' ?
                <AiOutlinePicture data-tooltip-id={resource._id} data-tooltip-content="Picture" />
                :
                resource?.resourceType === 'video' ?
                <AiOutlineVideoCamera data-tooltip-id={resource._id} data-tooltip-content="Video" />
                :
                <></>   
            }
            <h4>{resource?.title}</h4>
            {
                resource?.isMultiple ?
                <div className={styles.multiple__Wrapper}>
                    {
                        React.Children.toArray(resource?.itemUrls.map(item => {
                            if (item === 'none') return <></>

                            return <>
                                {
                                    resource?.resourceType === 'document' ?
                                        <>
                                            <div className={styles.single__resource__Item}>
                                                <div className={styles.resource__Doc__Item}>
                                                    <span>{item.split('.')[item.split('.').length - 1]}</span>
                                                </div>
                                                <button className={styles.download__Btn}>
                                                    <AiOutlineDownload />
                                                </button>
                                            </div>
                                        </>
                                    :
                                    resource?.resourceType === 'audio' ?
                                        <>
                                            <div className={styles.single__resource__Item}>
                                                <audio>
                                                    <source 
                                                        src={item} 
                                                        type={`video/${item.split('.')[item.split('.').length - 1]}`}
                                                    />
                                                </audio>
                                                <button className={styles.download__Btn}>
                                                    <AiOutlineDownload />
                                                </button>
                                            </div>
                                        </>
                                    :
                                    resource?.resourceType === 'picture' ?
                                        <>
                                            <div className={styles.single__resource__Item}>
                                                <img src={item} alt={resource.resourceType} />
                                                <button className={styles.download__Btn}>
                                                    <AiOutlineDownload />
                                                </button>
                                            </div>
                                        </>
                                    :
                                    resource?.resourceType === 'video' ?
                                        <>
                                            <div className={styles.single__resource__Item}>
                                                {
                                                    item.split('.')[item.split('.').length - 1]?.toLocaleLowerCase() === 'gif' ?
                                                        <img src={item} alt={resource.resourceType} />
                                                    :
                                                    <video controls>
                                                        <source 
                                                            src={item} 
                                                            type={`video/${item.split('.')[item.split('.').length - 1]}`}
                                                        />
                                                    </video>
                                                }
                                                <button className={styles.download__Btn}>
                                                    <AiOutlineDownload />
                                                </button>
                                            </div>
                                        </>
                                    :
                                    <></> 
                                }
                            </>

                            return <Link to={item} target="_blank">
                                {item.length > 35 ? item.slice(0, 35) + '...' : item}
                            </Link>
                        }))
                    }
                </div>
                :
                resource.singleResourceUrl === 'none' ?
                <></>
                :
                <>
                    {
                        resource?.resourceType === 'document' ?
                            <>
                                <div className={styles.single__resource__Item}>
                                    <div className={styles.resource__Doc__Item}>
                                        <span>{resource.singleResourceUrl.split('.')[resource.singleResourceUrl.split('.').length - 1]}</span>
                                    </div>
                                    <button className={styles.download__Btn}>
                                        <AiOutlineDownload />
                                    </button>
                                </div>
                            </>
                        :
                        resource?.resourceType === 'audio' ?
                            <>
                                <div className={styles.single__resource__Item}>
                                    <audio>
                                        <source 
                                            src={resource.singleResourceUrl} 
                                            type={`video/${resource.singleResourceUrl.split('.')[resource.singleResourceUrl.split('.').length - 1]}`}
                                        />
                                    </audio>
                                    <button className={styles.download__Btn}>
                                        <AiOutlineDownload />
                                    </button>
                                </div>
                            </>
                        :
                        resource?.resourceType === 'picture' ?
                            <>
                                <div className={styles.single__resource__Item}>
                                    <img src={resource.singleResourceUrl} alt={resource.resourceType} />
                                    <button className={styles.download__Btn}>
                                        <AiOutlineDownload />
                                    </button>
                                </div>
                            </>
                        :
                        resource?.resourceType === 'video' ?
                            <>
                                <div className={styles.single__resource__Item}>
                                    {
                                        resource.singleResourceUrl.split('.')[resource.singleResourceUrl.split('.').length - 1]?.toLocaleLowerCase() === 'gif' ?
                                            <img src={resource.singleResourceUrl} alt={resource.resourceType} />
                                        :
                                        <video controls>
                                            <source 
                                                src={resource.singleResourceUrl} 
                                                type={`video/${resource.singleResourceUrl.split('.')[resource.singleResourceUrl.split('.').length - 1]}`}
                                            />
                                        </video>
                                    }
                                    <button className={styles.download__Btn}>
                                        <AiOutlineDownload />
                                    </button>
                                </div>
                            </>
                        :
                        <></> 
                    }
                </>
                // <Link to={resource.singleResourceUrl} target="_blank">
                //     {resource.singleResourceUrl.length > 35 ? resource.singleResourceUrl.slice(0, 35) + '...' : resource.singleResourceUrl}
                // </Link>
            }
            <Tooltip id={resource._id} style={{ fontSize: '0.8rem' }} />
        </div>
    </>
}

export default ResourceCard;