import {
  Flex,
  Title,
  Table,
  Card,
  Modal,
  Text,
  Spoiler,
  Tooltip,
  Image,
  ScrollArea,
  Skeleton
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { toast, truncateStringAtMiddle } from "../utils/helper";
import axiosService from "../services/axiosService";
import { formatDate } from "date-fns";
import { Carousel } from "@mantine/carousel";

const UploadedMinistersOutreaches = () => {
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [outreaches, setOutreaches] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);

  const fetchingOutreaches = async () => {
    try {
      setLoading(true);
      const res = await axiosService.get("/outreaches/admin-outreaches");
      setOutreaches(res?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast(error?.response?.data?.message).error();
    }
  };

  useEffect(() => {
    fetchingOutreaches();
  }, []);

  return (
    <>
      <Flex maw={"100%"} mih={"100vh"} direction={"column"}>
        <Title my={"sm"}>All Outreaches</Title>
        <Card>
          {loading ? (
            <>
              {Array(5)
                .fill(null)
                .map((item) => (
                  <Skeleton w={"100%"} h={40} mb={"xs"} />
                ))}
            </>
          ) : (
            <Table verticalSpacing="sm">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Added by</th>
                  <th>Added on</th>
                </tr>
              </thead>
              <tbody>
                {outreaches?.length > 0 && (
                  <>
                    {outreaches.map((item, key) => (
                      <tr
                        key={key}
                        onClick={() => {
                          setSelectedItem(item);
                          open();
                        }}
                        className="cursor-pointer"
                      >
                        <td>{item?.title || "--"}</td>
                        <td>
                          <Text>
                            {truncateStringAtMiddle(item?.description, 50)}
                          </Text>
                        </td>
                        <td>
                          {item?.addedBy?.name ? (
                            <Tooltip
                              withArrow
                              hidden={item?.addedBy?.name?.length < 40}
                              label={item?.addedBy?.name}
                              arrowPosition="side"
                            >
                              <Text>
                                {truncateStringAtMiddle(
                                  item?.addedBy?.name,
                                  40
                                )}
                              </Text>
                            </Tooltip>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>{formatDate(item?.createdAt, "dd MMMM, yyyy")}</td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          )}
        </Card>
      </Flex>

      {opened && selectedItem && (
        <Modal
          size={"lg"}
          centered
          opened={opened}
          classNames={{
            header: "border-b mb-[15px]",
            // body: "!pb-0",
          }}
          onClose={() => {
            close();
            setSelectedItem({});
          }}
          scrollAreaComponent={ScrollArea.Autosize}
          title={
            <Flex direction={"column"}>
              <Text fw={600}>
                {truncateStringAtMiddle(selectedItem?.title, 40)}
              </Text>
              <Text c={"dimmed"}>{selectedItem?.addedBy?.name || "--"}</Text>
            </Flex>
          }
        >
          {selectedItem?.title?.length > 40 && (
            <Text fw={600} c={"dimmed"} my={"xs"}>
              <span className="text-black">Title: </span>
              <span>{selectedItem?.title || "--"}</span>
            </Text>
          )}
          {selectedItem?.pictures?.length > 1 ? (
            <Carousel withIndicators height={400}>
              {selectedItem?.pictures?.map((item, idx) => (
                <Carousel.Slide key={idx} className="border">
                  <Image
                    src={item}
                    // className="h-full w-full"
                    fit="fill"
                    height={400}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          ) : (
            <Image height={400} src={selectedItem?.pictures[0]} />
          )}

          {/* <Flex h={200} my={"xs"}> */}
          <Spoiler
            maxHeight={100}
            showLabel="Show more"
            hideLabel="Hide"
            my={"md"}
          >
            <Text>Description: {selectedItem?.description}</Text>
          </Spoiler>
          {/* </Flex> */}
        </Modal>
      )}
    </>
  );
};

export default UploadedMinistersOutreaches;
