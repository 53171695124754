export const resources = {
    en: {
        translation: {
            'Welcome': 'Welcome',
            'Dashboard': 'Dashboard',
            'Ministers': 'Ministers',
            'Souls': 'Souls',
            'Won': 'Won',
            'Outreaches': 'Outreaches',
            'View': 'View',
            'all': 'all',
            'This Month': 'This Month',
            'This Year': 'This Year',
            "Today's": "Today's", 
            'Rhapsody': 'Rhapsody',
            'of': 'of',
            'Realities': 'Realities',
            'Read': 'Read',
            'the': 'the',
            'full': 'full',
            'article': 'article',
            'Distribute': 'Distribute',
            'Your': 'Your',
            'devotionals': 'devotionals',
            'Add': 'Add',
            'Loading': 'Loading',
            'failed': 'failed',
            'to': 'to',
            'load': 'load',
            'Please': 'Please',
            'refresh': 'refresh',
            'this': 'this',
            'page': 'page',
            'You': 'You',
            'have': 'have',
            'not': 'not',
            'created': 'created',
            'any': 'any',
            'yet': 'yet',
            'Church Manager': 'Church Manager',
            'Souls Manager': 'Souls Manager',
            'Resources': 'Resources',
            'My network': 'My network',
            'Settings': 'Settings',
            'Devotionals': 'Devotionals',
            'Logout': 'Logout',
            'Contact': 'Contact',
            'Support': 'Support',
            'Referral': 'Referral',
            'Network': 'Network',
            'resources': 'resources',
            'Track': 'Track',
            'Enlist': 'Enlist',
            'ministers': 'ministers',
            'enlisted': 'enlisted',
            'This year': 'This year',
            'Upload': 'Upload',
            'pictures': 'pictures',
            'from': 'from',
            'your': 'your',
            'outreach': 'outreach',
            'Upcoming': 'Upcoming',
            'Missions': 'Missions',
            'Events': 'Events',
            'no': 'no',
            'event': 'event',
            'Now': 'Now',
            'As': 'As', 
            'a': 'a',
            'minister': 'minister', 
            'you': 'you',
            'access': 'access',
            'ministry': 'ministry', 
            'events': 'events',
            'and': 'and',
            'invitations': 'invitations',
            'participate': 'participate',
            'Devotional': 'Devotional',
            'for': 'for',
            'today': 'today',
            'My ministers network': 'My ministers network',
            'Name': 'Name',
            'Email': 'Email',
            'Date': 'Date',
            'joined': 'joined',
        }
    },
    fr: {
        translation: {
            'Welcome': 'Bienvenue',
            'Dashboard': 'Tableau de bord',
            'Ministers': 'Ministres',
            'Souls': 'âmes',
            'Won': 'Gagné',
            'Outreaches': 'Sensibilisation',
            'View': 'Voir',
            'all': 'tous',
            'This Month': 'Cet Mois',
            'This Year': 'Cet Année',
            "Today's": "Aujourd'hui", 
            'Rhapsody': 'Rhapsodie',
            'of': 'de',
            'Realities': 'Réalités',
            'Read': 'Lire',
            'the': 'les',
            'full': 'entier',
            'article': 'article',
            'Distribute': 'Distribuer',
            'Your': 'Ton',
            'devotionals': 'dévotions',
            'Add': 'Ajouter',
            'Loading': 'Chargement',
            'failed': 'échoué',
            'to': 'à',
            'load': 'charger',
            'Please': "S'il te plaît",
            'refresh': 'rafraîchir',
            'this': 'cet',
            'page': 'page',
            'You': 'Toi',
            'have': 'avoir',
            'not': 'pas',
            'created': 'créé',
            'any': "n'importe lequel",
            'yet': 'encore',
            'Church Manager': "Responsable de l'église",
            'Souls Manager': 'Gestionnaire des âmes',
            'Resources': 'Ressources',
            'My network': 'Mon réseau',
            'Settings': 'Paramètres',
            'Devotionals': 'Dévotions',
            'Logout': 'Se déconnecter',
            'Contact': 'Contact',
            'Support': 'Soutien',
            'Referral': 'Référence',
            'Network': 'Réseau',
            'resources': 'ressources',
            'Track': 'Piste',
            'Enlist': 'Enrôler',
            'ministers': 'ministres',
            'enlisted': 'enrôlé',
            'This year': 'Cette année',
            'Upload': 'Télécharger',
            'pictures': 'des photos',
            'from': 'depuis',
            'your': 'ton',
            'outreach': 'sensibilisation',
            'Upcoming': 'A venir',
            'Missions': 'missions',
            'Events': 'Événements',
            'no': 'non',
            'event': 'événement',
            'Now': 'Maintenant',
            'As': 'Comme', 
            'a': 'un',
            'minister': 'ministre', 
            'you': 'toi',
            'access': 'accéder',
            'ministry': 'ministère', 
            'events': 'événements',
            'and': 'et',
            'invitations': 'invitations',
            'participate': 'participer',
            'Devotional': 'Dévotion',
            'for': 'pour',
            'today': "aujourd'hui",
            'My ministers network': 'Mon ministres réseau',
            'Name': 'Nom',
            'Email': 'Email',
            'Date': 'Date',
            'joined': 'rejointe',
        }
    },
    cm: {
        translation: {
            'Welcome': '欢迎',
            'Dashboard': '仪表板',
            'Ministers': '部长',
            'Souls': '灵魂',
            'Won': '韩元',
            'Outreaches': '外展事工',
            'View': 'View',
            'all': 'all',
            'This Month': 'This Month',
            'This Year': 'This Year',
            "Today's": "Today's", 
            'Rhapsody': 'Rhapsody',
            'of': 'of',
            'Realities': 'Realities',
            'Read': 'Read',
            'the': 'the',
            'full': 'full',
            'article': 'article',
            'Distribute': 'Distribute',
            'Your': 'Your',
            'devotionals': 'devotionals',
            'Add': 'Add',
            'Loading': 'Loading',
            'failed': 'failed',
            'to': 'to',
            'load': 'load',
            'Please': 'Please',
            'refresh': 'refresh',
            'this': 'this',
            'page': 'page',
            'You': '你',
            'have': 'have',
            'not': 'not',
            'created': 'created',
            'any': 'any',
            'yet': 'yet',
            'Church Manager': 'Church Manager',
            'Souls Manager': 'Souls Manager',
            'Resources': 'Resources',
            'My network': 'My network',
            'Settings': 'Settings',
            'Devotionals': 'Devotionals',
            'Logout': 'Logout',
            'Contact': 'Contact',
            'Support': 'Support',
            'Referral': 'Referral',
            'Network': 'Network',
            'resources': 'resources',
            'Track': 'Track',
            'Enlist': 'Enlist',
            'ministers': 'ministers',
            'enlisted': 'enlisted',
            'This year': 'This year',
            'Upload': 'Upload',
            'pictures': 'pictures',
            'from': 'from',
            'your': 'your',
            'outreach': 'outreach',
            'Upcoming': 'Upcoming',
            'Missions': 'Missions',
            'Events': 'Events',
            'no': 'no',
            'event': 'event',
            'Now': 'Now',
            'As': 'As', 
            'a': 'A',
            'minister': '部长', 
            'you': '你',
            'access': '使用权',
            'ministry': '部', 
            'events': '事件',
            'and': '和',
            'invitations': '邀请函',
            'participate': '参加',
            'Devotional': '虔诚的',
            'for': '为了',
            'today': '今天',
            'My ministers network': '我的部长网络',
            'Name': '姓名',
            'Email': '电子邮件',
            'Date': '日期',
            'joined': '加入',
        }
    },
    hi: {
        translation: {
            'Welcome': 'Welcome',
            'Dashboard': 'Dashboard',
            'Ministers': 'Ministers',
            'Souls': 'Souls',
            'Won': 'Won',
            'Outreaches': 'Outreaches',
            'View': 'View',
            'all': 'all',
            'This Month': 'This Month',
            'This Year': 'This Year',
            "Today's": "Today's", 
            'Rhapsody': 'Rhapsody',
            'of': 'of',
            'Realities': 'Realities',
            'Read': 'Read',
            'the': 'the',
            'full': 'full',
            'article': 'article',
            'Distribute': 'Distribute',
            'Your': 'Your',
            'devotionals': 'devotionals',
            'Add': 'Add',
            'Loading': 'Loading',
            'failed': 'failed',
            'to': 'to',
            'load': 'load',
            'Please': 'Please',
            'refresh': 'refresh',
            'this': 'this',
            'page': 'page',
            'You': 'आप',
            'have': 'have',
            'not': 'not',
            'created': 'created',
            'any': 'any',
            'yet': 'yet',
            'Church Manager': 'Church Manager',
            'Souls Manager': 'Souls Manager',
            'Resources': 'Resources',
            'My network': 'My network',
            'Settings': 'Settings',
            'Devotionals': 'Devotionals',
            'Logout': 'Logout',
            'Contact': 'Contact',
            'Support': 'Support',
            'Referral': 'Referral',
            'Network': 'Network',
            'resources': 'resources',
            'Track': 'Track',
            'Enlist': 'Enlist',
            'ministers': 'ministers',
            'enlisted': 'enlisted',
            'This year': 'This year',
            'Upload': 'Upload',
            'pictures': 'pictures',
            'from': 'from',
            'your': 'your',
            'outreach': 'outreach',
            'Upcoming': 'Upcoming',
            'Missions': 'Missions',
            'Events': 'Events',
            'no': 'no',
            'event': 'event',
            'Now': 'Now',
            'As': 'As', 
            'a': 'ए',
            'minister': 'मंत्री', 
            'you': 'आप',
            'access': 'पहुँच',
            'ministry': 'मंत्रालय', 
            'events': 'आयोजन',
            'and': 'और',
            'invitations': 'आमंत्रण',
            'participate': 'हिस्सा लेना',
            'Devotional': 'धार्मिक',
            'for': 'के लिए',
            'today': 'आज',
            'My ministers network': 'मेरे मंत्रियों का नेटवर्क',
            'Name': 'Name',
            'Email': 'Email',
            'Date': 'Date',
            'joined': 'joined',
        }
    },
    es: {
        translation: {
            'Welcome': 'Welcome',
            'Dashboard': 'Dashboard',
            'Ministers': 'Ministers',
            'Souls': 'Souls',
            'Won': 'Won',
            'Outreaches': 'Outreaches',
            'View': 'View',
            'all': 'all',
            'This Month': 'This Month',
            'This Year': 'This Year',
            "Today's": "Today's", 
            'Rhapsody': 'Rhapsody',
            'of': 'of',
            'Realities': 'Realities',
            'Read': 'Read',
            'the': 'the',
            'full': 'full',
            'article': 'article',
            'Distribute': 'Distribute',
            'Your': 'Your',
            'devotionals': 'devotionals',
            'Add': 'Add',
            'Loading': 'Loading',
            'failed': 'failed',
            'to': 'to',
            'load': 'load',
            'Please': 'Please',
            'refresh': 'refresh',
            'this': 'this',
            'page': 'page',
            'You': 'Tú',
            'have': 'have',
            'not': 'not',
            'created': 'created',
            'any': 'any',
            'yet': 'yet',
            'Church Manager': 'Church Manager',
            'Souls Manager': 'Souls Manager',
            'Resources': 'Resources',
            'My network': 'My network',
            'Settings': 'Settings',
            'Devotionals': 'Devotionals',
            'Logout': 'Logout',
            'Contact': 'Contact',
            'Support': 'Support',
            'Referral': 'Referral',
            'Network': 'Network',
            'resources': 'resources',
            'Track': 'Track',
            'Enlist': 'Enlist',
            'ministers': 'ministers',
            'enlisted': 'enlisted',
            'This year': 'This year',
            'Upload': 'Upload',
            'pictures': 'pictures',
            'from': 'from',
            'your': 'your',
            'outreach': 'outreach',
            'Upcoming': 'Upcoming',
            'Missions': 'Missions',
            'Events': 'Events',
            'no': 'no',
            'event': 'event',
            'Now': 'Now',
            'As': 'As', 
            'a': 'un',
            'minister': 'ministro', 
            'you': 'tú',
            'access': 'acceso',
            'ministry': 'ministerio', 
            'events': 'eventos',
            'and': 'y',
            'invitations': 'invitaciones',
            'participate': 'participar',
            'Devotional': 'Piadoso',
            'for': 'para',
            'today': 'hoy',
            'My ministers network': 'Mi red de ministros',
            'Name': 'Name',
            'Email': 'Email',
            'Date': 'Date',
            'joined': 'joined',
        }
    },
    ar: {
        translation: {
            'Welcome': 'Welcome',
            'Dashboard': 'Dashboard',
            'Ministers': 'Ministers',
            'Souls': 'Souls',
            'Won': 'Won',
            'Outreaches': 'Outreaches',
            'View': 'View',
            'all': 'all',
            'This Month': 'This Month',
            'This Year': 'This Year',
            "Today's": "Today's", 
            'Rhapsody': 'Rhapsody',
            'of': 'of',
            'Realities': 'Realities',
            'Read': 'Read',
            'the': 'the',
            'full': 'full',
            'article': 'article',
            'Distribute': 'Distribute',
            'Your': 'Your',
            'devotionals': 'devotionals',
            'Add': 'Add',
            'Loading': 'Loading',
            'failed': 'failed',
            'to': 'to',
            'load': 'load',
            'Please': 'Please',
            'refresh': 'refresh',
            'this': 'this',
            'page': 'page',
            'You': 'أنت',
            'have': 'have',
            'not': 'not',
            'created': 'created',
            'any': 'any',
            'yet': 'yet',
            'Church Manager': 'Church Manager',
            'Souls Manager': 'Souls Manager',
            'Resources': 'Resources',
            'My network': 'My network',
            'Settings': 'Settings',
            'Devotionals': 'Devotionals',
            'Logout': 'Logout',
            'Contact': 'Contact',
            'Support': 'Support',
            'Referral': 'Referral',
            'Network': 'Network',
            'resources': 'resources',
            'Track': 'Track',
            'Enlist': 'Enlist',
            'ministers': 'ministers',
            'enlisted': 'enlisted',
            'This year': 'This year',
            'Upload': 'Upload',
            'pictures': 'pictures',
            'from': 'from',
            'your': 'your',
            'outreach': 'outreach',
            'Upcoming': 'Upcoming',
            'Missions': 'Missions',
            'Events': 'Events',
            'no': 'no',
            'event': 'event',
            'Now': 'Now',
            'As': 'As', 
            'a': 'أ',
            'minister': 'وزير', 
            'you': 'أنت',
            'access': 'وصول',
            'ministry': 'الوزارة', 
            'events': 'الأحداث',
            'and': 'و',
            'invitations': 'دعوات',
            'participate': 'يشارك',
            'Devotional': 'عبادي',
            'for': 'ل',
            'today': 'اليوم',
            'My ministers network': 'شبكة وزرائي',
            'Name': 'Name',
            'Email': 'Email',
            'Date': 'Date',
            'joined': 'joined',
        }
    },
}