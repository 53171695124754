import { useNavigate } from "react-router-dom";
import { useDevotionalContext } from "../../context/DevotionalContext";
import { useUserContext } from "../../context/UserContext";
import {
  createNewDevotional,
  getDevotionalsForAdmin,
} from "../../services/devotionalService";
import styles from "./styles.module.css";
import React, { useEffect } from "react";
import SingleDevotionalCard from "./components/SingleDevotionalCard";
import { toast } from "../../utils/helper";
import { useState } from "react";
import { useRef } from "react";
import {
  AiOutlineCalendar,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlinePlus,
} from "react-icons/ai";
import {
  Button,
  Flex,
  Modal,
  Space,
  Text,
  FileInput,
  TextInput,
  Popover,
  Title,
  Paper,
} from "@mantine/core";
import { MonthPicker } from "@mantine/dates";
import { FiUploadCloud } from "react-icons/fi";
import { useDisclosure } from "@mantine/hooks";
import { formatDate } from "date-fns";
import axios from "../../services/axiosService";

const AllDevotionalsPage = ({ translateComponent }) => {
  const navigate = useNavigate();
  const {
    devotionals,
    setDevotionals,
    devotionalsLoading,
    setDevotionalsLoading,
    devotionalsLoaded,
    setDevotionalsLoaded,
    bibles,
    setBibles,
    biblesLoading,
    setBiblesLoading,
    biblesLoaded,
    setBiblesLoaded,
    bibleSelected,
    setBibleSelected,
    bookSelected,
    setBookSelected,
    chapterSelected,
    setChapterSelected,
    verseSelected,
    setVerseSelected,
    passageSelected,
    setPassageSelected,
    availableBooks,
    setAvailableBooks,
    availableBooksLoading,
    setAvailableBooksLoading,
    availableChapters,
    setAvailableChapters,
    availableChaptersLoading,
    setAvailableChaptersLoading,
    availableVerses,
    setAvailableVerses,
    availableVersesLoading,
    setAvailableVersesLoading,
    passageContentLoading,
    setPassageContentLoading,
    resetStateForNewDevotionalPopup,
  } = useDevotionalContext();
  const { user } = useUserContext();
  const [devotionalPopupOpen, setDevotionalPopupOpen] = useState(false);
  const [newDevotional, setNewDevotional] = useState(initialNewDevotionalState);
  const dateInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [showMonthSelector, setShowMonthSelector] = useState(false);
  const [fullDevotionDate, setFullDevotionDate] = useState(new Date());
  const [fullDevotionFile, setFullDevotionFile] = useState(null);
  const [totalViews, setTotalViews] = useState(0);
  const [today, todayFormatted] = [
    new Date(),
    new Date().toISOString().split("T")[0],
  ];

  useEffect(() => {
    if (user?.accountType !== "ADMIN") return navigate("/dashboard");

    if (devotionals.length < 1) {
      setDevotionalsLoading(true);

      getDevotionalsForAdmin()
        .then((res) => {
          const devotionalsFetched = res.data;

          setDevotionals(devotionalsFetched);
          setDevotionalsLoading(false);
          setDevotionalsLoaded(true);
        })
        .catch((err) => {
          console.log(err);
          setDevotionalsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    axios
      .get("/devotionals/total-view")
      .then((res) => {
        setTotalViews(res?.data?.totalViews || 0);
      })
      .catch((err) => {
        toast(err?.response?.data?.message).error();
      });
  }, []);

  // useEffect(() => {

  //     if (!bibleSelected) return

  //     setAvailableBooksLoading(true);

  //     getBooksInBible(bibleSelected.id).then(res => {
  //         setAvailableBooks(res?.data?.data);
  //         setAvailableBooksLoading(false);
  //     }).catch(err => {
  //         console.log(err);
  //         setAvailableBooksLoading(false);
  //     })

  // }, [bibleSelected])

  // useEffect(() => {

  //     if (!bibleSelected || !bookSelected) return

  //     setAvailableChaptersLoading(true);

  //     getBibleChapters(bibleSelected.id, bookSelected.id).then(res => {
  //         setAvailableChapters(res?.data?.data);
  //         setAvailableChaptersLoading(false);
  //     }).catch(err => {
  //         console.log(err);
  //         setAvailableChaptersLoading(false);
  //     })

  // }, [bookSelected])

  // useEffect(() => {

  //     if (!bibleSelected || !bookSelected || !chapterSelected) return

  //     setAvailableVersesLoading(true);

  //     getBibleVerses(bibleSelected.id, chapterSelected.id).then(res => {
  //         setAvailableVerses(res?.data?.data);
  //         setAvailableVersesLoading(false);
  //     }).catch(err => {
  //         console.log(err);
  //         setAvailableVersesLoading(false);
  //     })

  // }, [chapterSelected])

  // useEffect(() => {

  //     if (!bibleSelected || !bookSelected || !chapterSelected || !verseSelected) return

  //     setPassageContentLoading(true);

  //     getBibleVerseContent(bibleSelected.id, verseSelected.id).then(res => {
  //         setPassageSelected(res?.data?.data);
  //         setPassageContentLoading(false);
  //     }).catch(err => {
  //         console.log(err);
  //         setPassageContentLoading(false);
  //     })

  // }, [verseSelected])

  const updateNewDevotionalContent = (name, val) => {
    setNewDevotional((prevValue) => {
      return {
        ...prevValue,
        [name]: val,
      };
    });
  };

  const handleCreateNewDevotional = async () => {
    if (newDevotional.title.length < 1)
      return toast("Please add a title for the devotional").error();
    // if (!bibleSelected) return toast('Please select a bible the devotional').error();
    // if (!bookSelected) return toast('Please select a book for the devotional').error();
    // if (!chapterSelected) return toast(`Please select a chapter from ${bookSelected?.name} for the devotional`).error();
    // if (!verseSelected) return toast(`Please select a verse from ${bookSelected?.name} ${chapterSelected.number} for the devotional`).error();
    if (newDevotional.dateForDevotional.length < 1)
      return toast("Please add a date for the devotional").error();
    if (newDevotional.devotionalContent.length < 1)
      return toast("Please add some content for the devotional").error();
    if (newDevotional.bibleVerse.length < 1)
      return toast("Please add a bible verse for the devotional").error();
    if (newDevotional.bibleVerseContent.length < 1)
      return toast("Please add the contents of the bible verse").error();

    const copyOfNewDevotional = { ...newDevotional };

    // copyOfNewDevotional.book = bookSelected?.name;
    // copyOfNewDevotional.chapter = chapterSelected?.number;
    // copyOfNewDevotional.verse = verseSelected?.reference?.split(':')[1];
    // copyOfNewDevotional.passageContent = passageSelected?.content;

    setLoading(true);
    try {
      const res = (await createNewDevotional(copyOfNewDevotional)).data;

      const copyOfDevotionals = devotionals.slice();
      copyOfDevotionals.unshift({ ...res, comments: [] });

      setDevotionals(copyOfDevotionals);
      setNewDevotional(initialNewDevotionalState);
      resetStateForNewDevotionalPopup();
      setDevotionalPopupOpen(false);
      setLoading(false);

      toast("Successfully created new devotional").success();
    } catch (error) {
      console.log(error);
      setLoading(false);

      toast("An error occured when trying to create your devotional").error();
    }
  };

  const handleUploadFullDevotion = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("date", fullDevotionDate);
      formData.append("file", fullDevotionFile);
      const res = await axios.post("/devotionals/full-devotion", formData);
      setDevotionals([{ ...res.data?.data }, ...devotionals]);
      close();
      setLoading(false);
      return toast("Uploaded successfully!").success();
    } catch (error) {
      setLoading(false);
      toast(error?.response.data?.message).error();
    }
  };

  return (
    <>
      <main className={styles.devotionals__Content}>
        <section>
          <Flex justify={"space-between"} maw={"100%"} className="flex-col lg:flex-row" align={"flex-start"}>
            <Flex direction={"column"} className="lg:w-[50%]">
              <Title>
                {translateComponent("All")} {translateComponent("devotionals")}
              </Title>
              <Flex maw={500} className="mb-3 lg:mb-0">
                <Paper p={"md"} shadow="sm" mt={"sm"} w={"100%"}>
                  <Text fw={600} size={"sm"}>
                    Total Views:
                  </Text>
                  <Text c={"dimmed"} size={"2.5rem"}>
                    {totalViews}
                  </Text>
                </Paper>
              </Flex>
            </Flex>

            <Flex>
              <Button
                onClick={open}
                radius={"md"}
                className="bg-color-1 hover:bg-color-1"
                leftIcon={<FiUploadCloud />}
              >
                Upload
              </Button>
              <Space mx={"xs"} />
              <Button
                onClick={() => setDevotionalPopupOpen(true)}
                radius={"md"}
                className="bg-color-1 hover:bg-color-1"
                leftIcon={<AiOutlinePlus />}
              >
                Add
              </Button>
              {/* <button
                className={styles.add__New__Btn}
                onClick={() => setDevotionalPopupOpen(true)}
              >
                <AiOutlinePlus />
                <span>{translateComponent("Add")}</span>
              </button> */}
            </Flex>
          </Flex>

          <>
            {devotionalsLoading ? (
              <p style={{ fontSize: "0.8rem" }}>
                {translateComponent("Loading")}...
              </p>
            ) : !devotionalsLoaded ? (
              <p style={{ fontSize: "0.8rem" }}>
                {translateComponent("Devotionals")}{" "}
                {translateComponent("failed")} {translateComponent("to")}{" "}
                {translateComponent("load")}. {translateComponent("Please")}{" "}
                {translateComponent("refresh")} {translateComponent("this")}{" "}
                {translateComponent("page")}
              </p>
            ) : devotionals.length < 1 ? (
              <>
                <p style={{ fontSize: "0.8rem" }}>
                  {translateComponent("You")} {translateComponent("have")}{" "}
                  {translateComponent("not")} {translateComponent("created")}{" "}
                  {translateComponent("any")}{" "}
                  {translateComponent("devotionals")}{" "}
                  {translateComponent("yet")}
                </p>
              </>
            ) : (
              <>
                <div className={styles.devotionals__Wrapper}>
                  {React.Children.toArray(
                    devotionals.map((devotional) => {
                      return <SingleDevotionalCard devotional={devotional} />;
                    })
                  )}
                </div>
              </>
            )}
          </>
        </section>
      </main>

      {devotionalPopupOpen && (
        <div className={styles.add__Devotional__Wrapper}>
          <div className={styles.modal__Container}>
            <div
              className={styles.modal__Close__Container}
              onClick={() => setDevotionalPopupOpen(false)}
            >
              <AiOutlineClose className={styles.modal__Close__Icon} />
            </div>
            <div className={styles.modal__Content}>
              <h2 className={styles.modal__Content__Header}>
                Add new devotional
              </h2>
              <div className={styles.single__Modal__Item}>
                <p className={styles.input__Title}>Add title for devotional</p>
                <input
                  type="text"
                  name="title"
                  value={newDevotional.title}
                  onChange={({ target }) =>
                    updateNewDevotionalContent(target.name, target.value)
                  }
                />
              </div>
              {/* <div className={`${styles.select__Verse__Content} ${styles.single__Modal__Item}`}>
                            <p className={styles.input__Title}>Select verse for devotional</p>
                            
                            <div>
                                {
                                    biblesLoading ? <p className={styles.input__Title} style={{ fontSize: '0.7rem' }}>Loading bibles...</p>
                                    :
                                    !bibleSelected ? <>
                                        <select 
                                            className={styles.select__Item}
                                            defaultValue={''}
                                            onChange={({ target }) => setBibleSelected(JSON.parse(target.value))}
                                        >
                                            <option value={''} selected disabled>Select bible version</option>
                                            {
                                                React.Children.toArray(bibles.map(bible => {
                                                    return <option value={JSON.stringify(bible)}>{bible.name}</option>
                                                }))
                                            }
                                        </select> 
                                    </>:
                                    availableBooksLoading ? <p className={styles.input__Title} style={{ fontSize: '0.7rem' }}>Loading books in {bibleSelected?.name}...</p>
                                    :
                                    <>
                                        <div className={styles.flex__Devotional__Item}>
                                            <button 
                                                className={`${styles.distribute__Btn} ${styles.verse__Btn} ${styles.select__Date__Btn}`}
                                                onClick={() => { 
                                                    setBibleSelected(null)
                                                    setBookSelected(null);
                                                    setChapterSelected(null);
                                                    setVerseSelected(null);
                                                    setPassageSelected(null);
                                                }}
                                            >
                                                <AiOutlineEdit />
                                            </button>
                                            <p>
                                                <span>Bible selected: {bibleSelected?.name}</span>
                                            </p>
                                        </div>
                                        <div className={styles.flex__Devotional__Item} style={{ margin: '0.5rem 0' }}>
                                            {
                                                !bookSelected ? <select 
                                                    className={styles.select__Item}
                                                    defaultValue={''}
                                                    onChange={({ target }) => setBookSelected(JSON.parse(target.value))}
                                                >
                                                    <option value={''} selected disabled>Select book</option>
                                                    {
                                                        React.Children.toArray(availableBooks.map(book => {
                                                            return <option value={JSON.stringify(book)}>{book.name}</option>
                                                        }))
                                                    }
                                                </select> :
                                                <>
                                                    <button 
                                                        className={`${styles.distribute__Btn} ${styles.verse__Btn} ${styles.select__Date__Btn}`}
                                                        onClick={() => {
                                                            setBookSelected(null);
                                                            setChapterSelected(null);
                                                            setVerseSelected(null);
                                                            setPassageSelected(null);
                                                        }}
                                                    >
                                                        <AiOutlineEdit />
                                                    </button>
                                                    <p>
                                                        <span>Book selected: {bookSelected?.name}</span>
                                                    </p>
                                                    {
                                                        availableChaptersLoading ? <p className={styles.input__Title} style={{ fontSize: '0.7rem' }}>Loading chapters in {bookSelected?.name}...</p> :
                                                        <>
                                                        {
                                                            !chapterSelected ? <select 
                                                                className={styles.select__Item}
                                                                defaultValue={''}
                                                                onChange={({ target }) => setChapterSelected(JSON.parse(target.value))}
                                                            >
                                                                <option value={''} selected disabled>Select chapter</option>
                                                                {
                                                                    React.Children.toArray(availableChapters.filter(chapter => chapter.number !== 'intro').map(chapter => {
                                                                        return <option value={JSON.stringify(chapter)}>{chapter.number}</option>
                                                                    }))
                                                                }
                                                            </select> : <></>
                                                        } 
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <>
                                            {
                                                chapterSelected && <>
                                                    <div className={styles.flex__Devotional__Item}>
                                                        {
                                                            availableVersesLoading ? <p className={styles.input__Title} style={{ fontSize: '0.7rem' }}>Loading verses in {bookSelected?.name} {chapterSelected?.number}...</p> :
                                                            <>
                                                                <button 
                                                                    className={`${styles.distribute__Btn} ${styles.verse__Btn} ${styles.select__Date__Btn}`}
                                                                    onClick={() => {
                                                                        setChapterSelected(null);
                                                                        setVerseSelected(null);
                                                                        setPassageSelected(null);
                                                                    }}
                                                                >
                                                                    <AiOutlineEdit />
                                                                </button>
                                                                <p>
                                                                    <span>Chapter selected: {chapterSelected?.number}</span>
                                                                </p>
                                                            {
                                                                !verseSelected ? <select 
                                                                    className={styles.select__Item}
                                                                    defaultValue={''}
                                                                    onChange={({ target }) => setVerseSelected(JSON.parse(target.value))}
                                                                >
                                                                    <option value={''} selected disabled>Select verse</option>
                                                                    {
                                                                        React.Children.toArray(availableVerses.map(verse => {
                                                                            return <option value={JSON.stringify(verse)}>{verse.reference}</option>
                                                                        }))
                                                                    }
                                                                </select> : 
                                                                <>
                                                                
                                                                </>
                                                            }
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }
                                            {
                                                verseSelected && <>
                                                    <div className={styles.flex__Devotional__Item} style={{ margin: '0.5rem 0' }}>
                                                        {
                                                            passageContentLoading ? <p className={styles.input__Title} style={{ fontSize: '0.7rem' }}>Loading content for {verseSelected?.reference}...</p> :
                                                            <>
                                                                <button 
                                                                    className={`${styles.distribute__Btn} ${styles.verse__Btn} ${styles.select__Date__Btn}`}
                                                                    onClick={() => {
                                                                        setVerseSelected(null);
                                                                        setPassageSelected(null);
                                                                    }}
                                                                >
                                                                    <AiOutlineEdit />
                                                                </button>
                                                                <p>
                                                                    <span>Verse selected: {verseSelected?.reference}</span>
                                                                </p>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }
                                            {
                                                passageSelected && 
                                                <div className={styles.flex__Devotional__Item}>
                                                    <p>
                                                        Passage content: <span
                                                            dangerouslySetInnerHTML={{ __html: passageSelected.content }}
                                                        >
                                                        </span>
                                                    </p>
                                                </div>
                                            }
                                        </>
                                    </>
                                }
                                
                            </div>
                        </div> */}

              <div className={styles.single__Modal__Item}>
                <p className={styles.input__Title}>
                  Add bible verse for devotional
                </p>
                <input
                  type="text"
                  name="bibleVerse"
                  value={newDevotional.bibleVerse}
                  onChange={({ target }) =>
                    updateNewDevotionalContent(target.name, target.value)
                  }
                />
              </div>

              <div className={styles.single__Modal__Item}>
                <p className={styles.input__Title}>Add bible verse content</p>
                <textarea
                  type="text"
                  name="bibleVerseContent"
                  value={newDevotional.bibleVerseContent}
                  onChange={({ target }) =>
                    updateNewDevotionalContent(target.name, target.value)
                  }
                ></textarea>
              </div>

              <div
                className={`${styles.select__Verse__Content} ${styles.single__Modal__Item}`}
              >
                <p className={styles.input__Title}>
                  Select date for devotional
                </p>
                <div className={styles.flex__Devotional__Item}>
                  <button
                    className={`${styles.distribute__Btn} ${styles.verse__Btn} ${styles.select__Date__Btn}`}
                    onClick={() => dateInputRef.current.showPicker()}
                  >
                    {newDevotional.dateForDevotional.length < 1 ? (
                      <AiOutlineCalendar />
                    ) : (
                      <AiOutlineEdit />
                    )}
                  </button>
                  <input
                    type="date"
                    name="dateForDevotional"
                    value={newDevotional.dateForDevotional}
                    onChange={({ target }) =>
                      updateNewDevotionalContent(target.name, target.value)
                    }
                    className={styles.hidden}
                    ref={dateInputRef}
                    min={todayFormatted}
                  />
                  {newDevotional.dateForDevotional.length > 0 && (
                    <p>
                      <span>
                        This devotional will be shown on{" "}
                        {new Date(
                          newDevotional.dateForDevotional
                        ).toDateString()}
                      </span>
                    </p>
                  )}
                </div>
              </div>

              <div className={`${styles.single__Modal__Item}`}>
                <p className={styles.input__Title}>Enter devotional content</p>
                <textarea
                  type="text"
                  name="devotionalContent"
                  value={newDevotional.devotionalContent}
                  onChange={({ target }) =>
                    updateNewDevotionalContent(target.name, target.value)
                  }
                  rows={6}
                ></textarea>
              </div>

              <button
                className={`${styles.distribute__Btn} ${styles.verse__Btn}`}
                onClick={handleCreateNewDevotional}
                disabled={loading}
              >
                {loading ? "Please wait..." : "Create"}
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal
        // onClick={() => setShowMonthSelector(false)}
        shadow="sm"
        opened={opened}
        onClose={() => {
          close();
          if (showMonthSelector) {
            setShowMonthSelector(false);
          }
        }}
        title={<Text fw={600}>Upload full devotion</Text>}
        centered
      >
        <FileInput
          multiple={false}
          placeholder="Pick file"
          label="Devotion"
          withAsterisk
          mb={"sm"}
          value={fullDevotionFile}
          onChange={(payload) => {
            if (!payload) return;
            if (!payload.type.includes("pdf")) {
              return toast("Uploaded file should be a pdf!").error();
            }
            setFullDevotionFile(payload);
          }}
        />
        <Popover
          withinPortal
          position="top"
          opened={showMonthSelector}
          closeOnClickOutside
          closeOnEscape
        >
          <Popover.Target>
            <TextInput
              required
              label="Select date"
              mb={"sm"}
              readOnly
              value={formatDate(fullDevotionDate, "MMMM, yyyy")}
              onClick={(e) => {
                e.stopPropagation();
                setShowMonthSelector(!showMonthSelector);
              }}
            />
          </Popover.Target>
          <Popover.Dropdown>
            <MonthPicker
              minDate={new Date(new Date().getFullYear(), 1, 1)}
              maxDate={new Date(new Date().getFullYear(), 12, 31)}
              value={fullDevotionDate}
              onChange={(value) => {
                if (!value) return;
                setFullDevotionDate(value);
                setShowMonthSelector(false);
              }}
            />
          </Popover.Dropdown>
        </Popover>
        <Button
          loading={loading}
          onClick={handleUploadFullDevotion}
          className="bg-color-1 hover:bg-color-1 justify-end"
        >
          Proceed
        </Button>
      </Modal>
    </>
  );
};

const initialNewDevotionalState = {
  title: "",
  devotionalContent: "",
  dateForDevotional: "",
  bibleVerseContent: "",
  bibleVerse: "",
};

export default AllDevotionalsPage;
