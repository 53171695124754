import { createContext, useContext, useState } from "react";

const OutreachContext = createContext({});

export const useOutreachContext = () => useContext(OutreachContext);

export const OutreachContextProvider = ({ children }) => {
    const [ outreaches, setOutreaches ] = useState([]);
    const [ outreachesLoading, setOutreachesLoading ] = useState(true);
    const [ outreachesLoaded, setOutreachesLoaded ] = useState(false);

    return <>
        <OutreachContext.Provider value={{
            outreaches,
            setOutreaches,
            outreachesLoading,
            setOutreachesLoading,
            outreachesLoaded,
            setOutreachesLoaded,
        }}>
            { children }
        </OutreachContext.Provider>
    </>
}