import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Text,
  Flex,
  TextInput,
  Select,
  Textarea,
  Group,
  Image,
  Button,
  ScrollArea,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { Country, State } from "country-state-city";
import {
  inputStyles,
  objectHasNullProperty,
  outreachType,
  toast,
} from "../../utils/helper";
import PhoneInput from "react-phone-number-input";
import { FaCalendarAlt } from "react-icons/fa";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { FcCancel } from "react-icons/fc";
import { useUserContext } from "../../context/UserContext";
import { accountSetup } from "../../services/userService";
import { USER_PROFILE } from "../../context/UserContext";

// phone: "",
// gender: "",
// dob: "",
// ministryName: "",
// ministryCountry: "",
// ministryState: "",
// description: "",
// dateFounded: "",
// ministryType: ""
const DashboardModal = () => {
  const { user } = useUserContext();

  const [opened, { open, close }] = useDisclosure(
    user?.accountSetup ? false : true
  );
  const [selectedCountryStates, setSelectedCountryStates] = useState([]);

  const [ministryCountry, setMinistryCountry] = useState("");
  const [ministryName, setMinistryName] = useState("");
  const [ministryState, setMinistryState] = useState("");
  const [ministryType, setMinistryType] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [dateFounded, setDateFounded] = useState(null);
  const [dob, setDob] = useState(null);
  const [uploadedImg, setUploadedImg] = useState(null);

  const [loading, setLoading] = useState(false);

  const { userTokenDetailsDispatch } = useUserContext();

  const openRef = useRef(null);

  useEffect(() => {
    if (!ministryCountry) return;
    const { isoCode } = JSON.parse(ministryCountry);
    setSelectedCountryStates(State.getStatesOfCountry(isoCode));
  }, [ministryCountry]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      objectHasNullProperty({
        ministryState,
        ministryCountry,
        phone,
        gender,
        dob,
        description,
        dateFounded,
        ministryName,
        ministryType,
        uploadedImg,
      })
    )
      return toast("Fill all values!").error();
    if (!phone) return toast("Phone is requred!").error();
    setLoading(true);
    const formdata = new FormData();
    formdata.append("ministryName", ministryName);
    formdata.append("phone", phone);
    formdata.append("gender", gender);
    formdata.append("dob", dob);
    formdata.append("ministryCountry", ministryCountry);
    formdata.append("ministryState", ministryState);
    formdata.append("description", description);
    formdata.append("dateFounded", dateFounded);
    formdata.append("ministryType", ministryType);
    formdata.append("image", uploadedImg);

    accountSetup(formdata)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        toast(res.data.message).success();
        userTokenDetailsDispatch({
          type: USER_PROFILE,
          payload: res.data.data,
        });
        close();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast(err?.response?.data?.message).error();
      });
  };

  return (
    <>
      <Modal
        // className="!h-[500px]"
        mt={20}
        opened={opened}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
        onClose={close}
        title=""
        // centered
        size={{ md: "70vw", sm: "100vw" }}
      >
        {/* Modal content */}
        <ScrollArea className="flex w-full flex-col min-h-[550px] overflow-x-hidden">
          <Text className="md:text-center text-start font-2 font-medium text-sm md:text-xl">
            Your account is almost ready! Complete the details below to proceed
          </Text>
          <form onSubmit={handleSubmit}>
            <Flex className="flex-col md:flex-row justify-around mt-4">
              <Flex className="flex-col w-full md:w-[45%]">
                <Text className="font-medium text-base text-color-2 mb-2">
                  Ministry Affiliation
                </Text>
                <TextInput
                  required
                  value={ministryName}
                  onChange={({ target }) => setMinistryName(target.value)}
                  type="text"
                  size="md"
                  placeholder="Name of ministry"
                  sx={{
                    input: inputStyles,
                  }}
                />

                <Flex className="md:flex-row flex-col justify-between mt-5">
                  <Select
                    required
                    size="md"
                    className="md:w-[48%] w-full"
                    placeholder="Country"
                    searchable
                    nothingFound="No options"
                    clearable
                    value={ministryCountry}
                    onChange={setMinistryCountry}
                    data={[
                      ...Country.getAllCountries().map((data) => {
                        return {
                          value: JSON.stringify({
                            isoCode: data.isoCode,
                            name: data.name,
                          }),
                          label: data.name,
                        };
                      }),
                    ]}
                    sx={{
                      input: inputStyles,
                    }}
                  />
                  <Select
                    required
                    size="md"
                    className="md:w-[48%] w-full mt-5 md:mt-0"
                    placeholder="State"
                    searchable
                    nothingFound="No options"
                    clearable
                    value={ministryState}
                    onChange={setMinistryState}
                    data={[
                      ...selectedCountryStates.map((data) => {
                        return {
                          value: JSON.stringify({
                            isoCode: data.isoCode,
                            name: data.name,
                          }),
                          label: data?.name,
                        };
                      }),
                    ]}
                    sx={{
                      input: inputStyles,
                    }}
                  />
                </Flex>

                <DatePickerInput
                  required
                  mt={20}
                  placeholder="Year Founded"
                  size="md"
                  sx={{
                    button: inputStyles,
                  }}
                  icon={<FaCalendarAlt />}
                  value={dateFounded}
                  onChange={setDateFounded}
                />

                <Select
                  required
                  mt={20}
                  placeholder="Ministry Type"
                  value={ministryType}
                  onChange={setMinistryType}
                  size="md"
                  sx={{
                    input: inputStyles,
                  }}
                  data={outreachType}
                />

                <Textarea
                  required
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                  mt={20}
                  sx={{
                    textarea: { ...inputStyles, height: "150px" },
                  }}
                  placeholder="Description of Ministry"
                />
              </Flex>

              <Flex className="w-full md:w-[45%] flex-col md:mt-0 mt-5">
                <Text className="font-medium text-base text-color-2 mb-2">
                  Personal Info
                </Text>
                <PhoneInput
                  placeholder="Enter phone"
                  value={phone}
                  limitMaxLength
                  onChange={setPhone}
                />

                <Select
                  required
                  value={gender}
                  onChange={setGender}
                  placeholder="Gender"
                  mt={20}
                  sx={{
                    input: inputStyles,
                  }}
                  size="md"
                  data={[
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                  ]}
                />

                <DatePickerInput
                  required
                  mt={20}
                  placeholder="Date of birth"
                  size="md"
                  sx={{
                    input: inputStyles,
                  }}
                  icon={<FaCalendarAlt />}
                  value={dob}
                  onChange={setDob}
                />

                <Dropzone
                  openRef={openRef}
                  mt={20}
                  className="border border-solid w-full md:w-[50%] mx-auto h-[150px] rounded-md"
                  onDrop={(files) => {
                    setUploadedImg(files[0]);
                    console.log("accepted files", files);
                  }}
                  onReject={(files) => console.log("rejected files", files)}
                  maxSize={3 * 1024 ** 2}
                  accept={IMAGE_MIME_TYPE}
                >
                  <Group position="center" spacing="xl">
                    <Dropzone.Accept>
                      <FcCancel size={"3.2rem"} className="text-[red]" />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <FcCancel size={"3.2rem"} className="text-[red]" />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      {!uploadedImg ? (
                        <FiUploadCloud
                          size={"3.2rem"}
                          className="text-[gray] absolute bottom-10"
                        />
                      ) : (
                        <Image
                          src={URL.createObjectURL(uploadedImg)}
                          height={120}
                          className=""
                        />
                      )}
                    </Dropzone.Idle>
                  </Group>
                </Dropzone>
                <Flex className="flex-col mt-2">
                  <Text className="font-2 font-medium text-sm md:text-xl text-center">
                    Upload your profile picture
                  </Text>
                  <Text className="font-normal text-xs md:text-sm text-center">
                    Use a clear photo of yourself{" "}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <div className=" text-center">
              <Button
                loading={loading}
                loaderPosition="center"
                type="submit"
                className="w-[250px] !mx-auto bg-color-1 font-normal 
text-sm md:text-lg mt-7 text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:bg-color-1"
              >
                Update & proceed
              </Button>
            </div>
          </form>
        </ScrollArea>
      </Modal>
    </>
  );
};

export default DashboardModal;
