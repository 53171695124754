import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFileExtension,
  toast,
  truncateStringAtMiddle,
} from "../../utils/helper";
import {
  Flex,
  Title,
  Grid,
  Card,
  Image,
  Group,
  Text,
  ActionIcon,
  Tooltip,
  Skeleton,
  Modal,
} from "@mantine/core";
import axiosService from "../../services/axiosService";
import { Carousel } from "@mantine/carousel";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useDisclosure } from "@mantine/hooks";

// import { Tooltip } from "react-tooltip";

const ResourceItem = ({ item, handleSelectedFile }) => {
  const resourceType = item?.resourceType;
  const isVideo = resourceType === "video";
  const isImage = resourceType === "picture";
  const isDocument = resourceType === "document";
  const isAudio = resourceType === "audio";
  const multipleFiles = item?.isMultiple;
  const [downloadingFile, setDownloadFile] = useState(false);

  const handleDownloadSingleResource = async (uri = "") => {
    if (!uri) return;
    try {
      setDownloadFile(true);
      const response = await fetch(uri);
      const blob = await response.blob();

      const extension = getFileExtension(uri);
      const filename = `${item?.title}_${resourceType}.${extension}`;

      // Use FileSaver.js to save the file
      saveAs(blob, filename);
      setDownloadFile(false);
    } catch (error) {
      setDownloadFile(false);
      toast("Failed to download resource!").error();
    }
  };

  const handleDownloadMultipleResources = async (uris = []) => {
    if (!Array.isArray(uris) || uris.length === 0) return;
    try {
      setDownloadFile(true);
      const zip = new JSZip();
      for (const uri of uris) {
        const response = await fetch(uri);
        const blob = await response.blob();
        const extension = getFileExtension(uri);
        const filename = `${resourceType}_${
          uris.indexOf(uri) + 1
        }.${extension}`;

        // Add the file to the ZIP archive
        zip.file(filename, blob);
      }
      // Generate the ZIP file and trigger the download
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${item?.title}_${resourceType}.zip`);

      setDownloadFile(false);
    } catch (error) {
      setDownloadFile(false);
      toast("Failed to download resources!").error();
    }
  };

  const handleResourceClick = (uri, fileIndex) =>
    handleSelectedFile(uri || item?.singleResourceUrl, fileIndex || 0);

  return (
    <Grid.Col xs={12} md={6} lg={4}>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section>
          {multipleFiles ? (
            <Carousel maw={"100%"} mx="auto" withIndicators height={200}>
              {/* {(isImage || isVideo) && ( */}
              <>
                {item?.itemUrls?.map((item, index) => (
                  <Carousel.Slide
                    className="border"
                    onClick={() => handleResourceClick(item, index)}
                  >
                    {isImage && (
                      <Image src={item} className="h-full object-fill w-full" />
                    )}

                    {isVideo && (
                      <video src={item} className="h-full w-full" controls />
                    )}

                    {(isDocument || isAudio) && (
                      <Flex
                        bg="#D9DCFF"
                        direction={"column"}
                        justify={"center"}
                        w={"100%"}
                        onClick={() => {
                          if (!isAudio) return;
                          handleResourceClick(item, index);
                        }}
                        align={"center"}
                        h={200}
                      >
                        <Text fw={600} size={"lg"}>
                          {!isAudio ? `PDF-${index + 1}` : `Audio-${index + 1}`}
                        </Text>
                      </Flex>
                    )}

                    <Tooltip label={`Download-${resourceType}`}>
                      <ActionIcon
                        onClick={() => handleDownloadSingleResource(item)}
                        className="absolute bottom-0 right-0 bg-gray-300 hover:bg-gray-300"
                        loading={downloadingFile}
                      >
                        <FiDownload color="#F39B17" size={20} />
                      </ActionIcon>
                    </Tooltip>
                  </Carousel.Slide>
                ))}
              </>
              {/* )} */}
            </Carousel>
          ) : (
            <>
              {isImage && (
                <Image
                  height={200}
                  onClick={() => handleResourceClick()}
                  src={item?.singleResourceUrl}
                  fit="cover"
                />
              )}

              {isVideo && (
                <Flex h={200}>
                  <video
                    onClick={() => handleResourceClick()}
                    controls
                    height={"100%"}
                    //   height={200}
                    src={item?.singleResourceUrl}
                  />
                </Flex>
              )}

              {isDocument && (
                <Flex
                  bg="#D9DCFF"
                  direction={"column"}
                  justify={"center"}
                  w={"100%"}
                  onClick={() => handleResourceClick()}
                  align={"center"}
                  h={200}
                >
                  <Text fw={600} size={"lg"}>
                    PDF
                  </Text>
                </Flex>
              )}

              {isAudio && (
                <Flex
                  bg="#D9DCFF"
                  direction={"column"}
                  justify={"center"}
                  w={"100%"}
                  align={"center"}
                  h={200}
                >
                  <Text fw={600} size={"lg"}>
                    Audio
                  </Text>
                </Flex>
              )}
              
            </>
          )}
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
          <Tooltip label={item?.title} hidden={item?.title < 20} withArrow>
            <Text weight={500}>{truncateStringAtMiddle(item?.title, 20)}</Text>
          </Tooltip>

          <Tooltip
            label={multipleFiles?`Download all ${resourceType}s`:`Download ${resourceType}`}
            withArrow
          >
            <ActionIcon
              onClick={() => {
                if (multipleFiles) {
                  handleDownloadMultipleResources(item?.itemUrls || []);
                } else {
                  handleDownloadSingleResource(item?.singleResourceUrl || null);
                }
              }}
              loading={downloadingFile}
            >
              <FiDownload />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Card>
    </Grid.Col>
  );
};

const MinisterResourcesPage = () => {
  const navigate = useNavigate();
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    uri: "",
    type: "",
    title: "",
    multipleFiles: false,
    fileIndex: 0,
    uris: null,
  });
  const [opened, { open, close }] = useDisclosure(false);

  const videos = [
    {
      title: "RHAPATHON THURSDAY MORNING PART A",
      src: "https://d1ent1.loveworldcloud.com/~rorm/TRANSMISSION%20JOSEPH%20JESSE/THURSDAY%20MORNING%20REBROADCAST%20PART%20A.mp4",
    },
    {
      title: "RHAPATHON THURSDAY MORNING PART B",
      src: "https://d1ent1.loveworldcloud.com/~rorm/TRANSMISSION%20JOSEPH%20JESSE/THURSDAY%20MORNING%20REBROADCAST%20PART%20B.mp4",
    },
    {
      title: "RHAPATHON THURSDAY MORNING PART C",
      src: "https://d1ent1.loveworldcloud.com/~rorm/TRANSMISSION%20JOSEPH%20JESSE/THURSDAY%20MORNING%20REBROADCAST%20PART%20C.mp4",
    },
    {
      title: "RHAPATHON FRIDAY PART A",
      src: "https://d1ent1.loveworldcloud.com/~rorm/TRANSMISSION%20JOSEPH%20JESSE/Rhapthon%20Friday%20MORNING%20PART%20A.mp4",
    },
    {
      title: "RHAPATHON FRIDAY MORNING PART B",
      src: "https://d1ent1.loveworldcloud.com/~rorm/TRANSMISSION%20JOSEPH%20JESSE/Rhapthon%20Friday%20MORNING%20PART%20B.mp4",
    },
    {
      title: "RHAPATHON FRIDAY MORNING PART C",
      src: "https://d1ent1.loveworldcloud.com/~rorm/TRANSMISSION%20JOSEPH%20JESSE/Rhapthon%20Friday%20MORNING%20PART%20C.mp4",
    },
    // ... other video objects
  ];

  const fetchMinisterResources = async () => {
    try {
      setLoading(true);
      const res = await axiosService.get("/resources/minister-resources");
      setResources(res?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast(error?.response?.data?.message).error();
    }
  };

  const [downloadingFile, setDownloadFile] = useState(false);
  const [downloadingFiles, setDownloadFiles] = useState(false);

  const handleDownloadSingleResource = async (uri = "", title, type) => {
    if (!uri) return;
    try {
      setDownloadFile(true);
      const response = await fetch(uri);
      const blob = await response.blob();

      const extension = getFileExtension(uri);
      const filename = `${title}_${type}.${extension}`;

      // Use FileSaver.js to save the file
      saveAs(blob, filename);
      setDownloadFile(false);
    } catch (error) {
      setDownloadFile(false);
      toast("Failed to download resource!").error();
    }
  };

  const handleDownloadMultipleResources = async (uris = [], title, type) => {
    if (!Array.isArray(uris) || uris.length === 0) return;
    try {
      setDownloadFiles(true);
      const zip = new JSZip();
      for (const uri of uris) {
        const response = await fetch(uri);
        const blob = await response.blob();
        const extension = getFileExtension(uri);
        const filename = `${type}_${uris.indexOf(uri) + 1}.${extension}`;

        // Add the file to the ZIP archive
        zip.file(filename, blob);
      }
      // Generate the ZIP file and trigger the download
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${title}_${type}.zip`);

      setDownloadFiles(false);
    } catch (error) {
      setDownloadFiles(false);
      toast("Failed to download resources!").error();
    }
  };
  useEffect(() => {
    fetchMinisterResources();
  }, []);

  return (
    <>
      <Flex maw={"100%"} mih={"100vh"} direction={"column"}>
        <Title my={"sm"}>All resources</Title>

        {!loading ? (
          <>
            {resources?.length > 0 ? (
              <Grid>
                {resources?.map((item, key) => (
                  <ResourceItem
                    handleSelectedFile={(uri, fileIndex) => {
                      if (item?.resourceType === "audio") return;
                      setSelectedFile({
                        type: item?.resourceType,
                        title: item?.title,
                        uri,
                        uris: item?.itemUrls || null,
                        multipleFiles: item?.isMultiple,
                        fileIndex,
                      });
                      //open modal
                      open();
                    }}
                    key={key}
                    item={item}
                  />
                ))}
              </Grid>
            ) : (
              <Text>No Resources</Text>
            )}
          </>
        ) : (
          <Grid>
            {Array(6)
              .fill(null)
              .map((_, key) => (
                <Grid.Col xs={12} md={6} lg={4} key={key}>
                  <Skeleton h={250} w={"100%"} />
                </Grid.Col>
              ))}
          </Grid>
        )}
      </Flex>
      {opened && selectedFile?.uri ? (
        <Modal
          size={"xl"}
          opened={opened}
          onClose={() => {
            close();
            setSelectedFile({ title: "", type: "", uri: "", fileIndex: 0 });
          }}
          title={
            <Text fw={600}>
              {truncateStringAtMiddle(selectedFile?.title, 20)}
            </Text>
          }
          centered
        >
          {selectedFile?.multipleFiles ? (
            <>
              <Carousel
                maw={"100%"}
                mx="auto"
                withIndicators
                height={500}
                initialSlide={selectedFile.fileIndex}
              >
                <>
                  {selectedFile?.uris?.map((item, index) => (
                    <Carousel.Slide
                    //  className="border"
                    >
                      <Flex direction={"column"}>
                        {selectedFile?.type === "video" && (
                          <video
                            className="w-full h-[450px]"
                            src={item}
                            controls
                          />
                        )}

                        {selectedFile?.type === "picture" && (
                          <Image
                            src={item}
                            className="w-full h-[500px]"
                            withPlaceholder
                          />
                        )}

                        {selectedFile?.type === "document" && (
                          <iframe
                            src={item}
                            className="w-full h-[500px]"
                          ></iframe>
                        )}

                        <ActionIcon
                          onClick={() =>
                            handleDownloadSingleResource(
                              selectedFile?.uri || null,
                              selectedFile?.title,
                              selectedFile?.type
                            )
                          }
                          className="absolute bottom-0 right-0 bg-gray-300 hover:bg-gray-300"
                          loading={downloadingFile}
                        >
                          <FiDownload color="#F39B17" size={20} />
                        </ActionIcon>
                      </Flex>
                    </Carousel.Slide>
                  ))}
                </>
              </Carousel>
            </>
          ) : (
            <>
              <Flex
                h={selectedFile?.type === "document" ? 500 : 450}
                w={"100%"}
                direction={"column"}
                justify={"space-between"}
              >
                {selectedFile?.type === "picture" && (
                  <Image src={selectedFile?.uri} className="h-full w-full" />
                )}

                {selectedFile?.type === "video" && (
                  <div>
                    <video
                      controls
                      src={selectedFile?.uri}
                      className="border"
                    />
                  </div>
                )}

                {selectedFile?.type === "document" && (
                  <iframe
                    src={selectedFile?.uri}
                    className="w-full h-full"
                  ></iframe>
                )}

                <Flex justify={"flex-end"}>
                  <Tooltip
                    label={`Download resource${
                      selectedFile?.multipleFiles ? "s" : ""
                    }`}
                    withArrow
                  >
                    <ActionIcon
                      variant="filled"
                      onClick={() => {
                        if (selectedFile?.multipleFiles) {
                          handleDownloadMultipleResources(
                            selectedFile?.uris || [],
                            selectedFile?.title,
                            selectedFile?.type
                          );
                        } else {
                          handleDownloadSingleResource(
                            selectedFile?.uri || null,
                            selectedFile?.title,
                            selectedFile?.type
                          );
                        }
                      }}
                      loading={downloadingFile}
                    >
                      <FiDownload color="#F39B17" />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </Flex>
            </>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default MinisterResourcesPage;
