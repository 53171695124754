/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Flex, Select, Text, TextInput, Textarea } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { inputStyles, toast } from "../../utils/helper";
import { useUserContext, USER_PROFILE } from "../../context/UserContext";
import { FaCalendarAlt } from "react-icons/fa";
import { outreachType } from "../../utils/helper";
import { updateMinistry } from "../../services/userService";
import { isJSONStringRegex } from "../../utils/helper";
import { Country, State } from "country-state-city";

function MinistrySettings({ loading, setLoading }) {
  const { user, userDispatch } = useUserContext();
  const [ministry, setMinistry] = useState({
    name: "",
    country: "",
    state: "",
    dateFounded: "",
    type: "",
    description: "",
  });
  const [dateFounded, setDateFounded] = useState(null);
  const [selectedCountryStates, setSelectedCountryStates] = useState([]);
  // const [selectedStateCities, setSelectedStateCities] = useState([])
  const handleInputchange = (name, value) =>
    setMinistry({ ...ministry, [name]: value });

  useEffect(() => {
    if (user?.ministry?.name) {
      setMinistry(user?.ministry);
    }
  }, [user?.ministry?.name]);

  useEffect(() => {
    if (!ministry.country) return;
    if (!isJSONStringRegex(ministry.country)) return;
    const { isoCode } = JSON.parse(ministry.country);
    setSelectedCountryStates(State.getStatesOfCountry(isoCode));
  }, [ministry.country]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateMinistry({
      name: ministry.name,
      state: ministry.state,
      dateFounded: ministry.dateFounded,
      type: ministry.type,
      description: ministry.description,
      country: ministry.country,
    })
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        userDispatch({
          type: USER_PROFILE,
          payload: res.data.data,
        });
        toast(res.data?.message).success();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast(err?.response?.data?.message).error();
      });
  };

  return (
    <Flex
      direction={"column"}
      justify={"space-between"}
      mt={20}
      className="px-5 w-[400px]"
    >
      <Text className="fon-medium text-xl font-2 mb-3">Ministry Settings</Text>
      <form onSubmit={handleFormSubmit}>
        <Text className="font-normal text-xs text-color-2 mb-2">
          Name of Ministry
        </Text>
        <TextInput
          onChange={({ target: { value } }) => handleInputchange("name", value)}
          required
          placeholder="Name of Ministry"
          value={ministry.name}
          size="md"
          sx={{
            input: inputStyles,
          }}
        />

        <Flex className="flex-row w-full justify-between">
          <Flex direction={"column"} className="md:w-[48%] w-full">
            <Text className="font-normal text-xs text-color-2 my-2">
              Country
            </Text>
            {/* <TextInput
                            required
                            placeholder="Country"
                            value={ministry.country}
                            size='md'
                            sx={{
                                input: inputStyles
                            }}
                        /> */}

            <Select
              required
              size="md"
              placeholder="Country"
              searchable
              nothingFound="No options"
              clearable
              value={ministry.country}
              onChange={(value) => handleInputchange("country", value)}
              data={[
                ...Country.getAllCountries().map((data) => {
                  return {
                    value: JSON.stringify({
                      isoCode: data.isoCode,
                      name: data.name,
                    }),
                    label: data.name,
                  };
                }),
              ]}
              sx={{
                input: inputStyles,
              }}
            />
          </Flex>

          <Flex direction={"column"} className="md:w-[48%] w-full">
            <Text className="font-normal text-xs text-color-2 my-2">
              Country
            </Text>

            <Select
              required
              size="md"
              placeholder="State"
              searchable
              nothingFound="No options"
              clearable
              value={ministry.state}
              onChange={(value) => handleInputchange("state", value)}
              data={[
                ...selectedCountryStates.map((data) => {
                  return {
                    value: JSON.stringify({
                      isoCode: data.isoCode,
                      name: data.name,
                    }),
                    label: data?.name,
                  };
                }),
              ]}
              sx={{
                input: inputStyles,
              }}
            />
          </Flex>
        </Flex>

        {/* {ministry.dateFounded && ( */}
        <>
          <Text className="font-normal text-xs text-color-2 my-2">
            Date Founded
          </Text>
          <DatePickerInput
            required
            placeholder="Year Founded"
            size="md"
            sx={{
              button: inputStyles,
            }}
            icon={<FaCalendarAlt />}
            value={
              dateFounded
                ? dateFounded
                : new Date(ministry.dateFounded || new Date())
            }
            onChange={(value) => handleInputchange("dateFounded", value)}
          />
        </>
        {/* )} */}

        <Text className="font-normal text-xs text-color-2 my-2">
          Ministry Type{" "}
        </Text>
        <Select
          required
          size="md"
          sx={{
            input: inputStyles,
          }}
          data={outreachType}
          searchable
          nothingFound="No options"
          clearable
          value={ministry.type}
          onChange={(value) => handleInputchange("type", value)}
          placeholder="Ministry Type"
        />

        <Text className="font-normal text-xs text-color-2 my-2">
          Description
        </Text>
        <Textarea
          required
          value={ministry.description}
          onChange={({ target }) =>
            handleInputchange("description", target.value)
          }
          sx={{
            textarea: { ...inputStyles, height: "150px" },
          }}
          placeholder="Description of Ministry"
        />

        <Button
          loading={loading}
          loaderPosition="center"
          // fullWidth
          mt="xl"
          size="md"
          type="submit"
          radius={"md"}
          className="
                w-[150px]
                bg-color-1 
                font-normal
                 text-lg
                  text-white 
                  transition 
                  duration-300 
                  ease-in-out 
                  hover:bg-opacity-80
                   hover:bg-color-1"
        >
          Update
        </Button>
      </form>
    </Flex>
  );
}

export default MinistrySettings;
