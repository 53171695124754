import axios from "./axiosService";

export const addNewResource = async (data, type) => {
    return await axios.post(`/resources/new-resource?type=${type}`, data)
}

export const getAdminResources = async () => {
    return await axios.get(`/resources/admin-resources`)
}

export const getMinisterResources = async () => {
    return await axios.get(`/resources/minister-resources`)
}

export const getSingleResource = async (resourceId) => {
    return await axios.get(`/resources/resource/${resourceId}`)
}
