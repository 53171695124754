import React from 'react';
import {
    BackgroundImage, Flex, Container,
    Button, Paper, Group, Image
} from '@mantine/core';
import layerImg from "../images/reon-1.png"
import logo from "../images/logo.png";

function FormLayer({ children, moveUp }) {
    return (
        <BackgroundImage src={layerImg} className='bg-cover bg-no-repeat'>
<Flex direction={"column"} h={"100vh"} p={0} m={0} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>

                <Container size={400}
                    className='w-[100%]'>
                    <Paper shadow="md"
                        p={30}
                        className={`mt-10 ${!moveUp && "lg:mt-[15vh]"} min-h-[500px]`}
                        // mt={40}
                        radius="lg">
                        <Group position='center'>
                            <Image src={logo} width={200} />
                        </Group>

                        {children}
                    </Paper>
                </Container>
            </Flex>
        </BackgroundImage>
    );
}

export default FormLayer;