import {
  Flex,
  Title,
  Table,
  Card,
  Button,
  Modal,
  Text,
  TextInput,
  Textarea,
  Skeleton,
  Spoiler,
  ActionIcon,
  ScrollArea,
  Tooltip,
} from "@mantine/core";
import { useDisclosure, useClipboard } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { objectHasNullProperty, toast } from "../utils/helper";
import { DateTimePicker } from "@mantine/dates";
import { addNewEvent, getAdminEvents } from "../services/eventServices";
import { formatDate } from "date-fns";
import { IoCopyOutline } from "react-icons/io5";

const UploadedEvents = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const clipboard = useClipboard({ timeout: 500 });

  const fetchEvents = async () => {
    try {
      setLoadingEvents(true);
      const res = await getAdminEvents();
      setEvents(res?.data);
      setLoadingEvents(false);
    } catch (error) {
      setLoadingEvents(false);
      toast(error?.response?.data?.message).error();
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const [newEvent, setNewEvent] = useState({
    title: "",
    details: "",
    date: null,
    link: "",
  });

  const handleInputChange = (name, value) =>
    setNewEvent({ ...newEvent, [name]: value });

  const handleAddEvent = async (e) => {
    try {
      e?.preventDefault();
      if (objectHasNullProperty(newEvent)) {
        return toast("Fill all values!").error();
      }
      setLoading(true);
      const res = await addNewEvent(newEvent);
      setLoading(false);
      setEvents([res.data, ...events]);
      toast("Event created successfully!").success();
      close();
    } catch (error) {
      setLoading(false);
      toast(error?.response?.data?.message).error();
    }
  };

  return (
    <>
      <Flex maw={"100%"} mih={"100vh"} direction={"column"}>
        <Flex justify={"space-between"} align={"center"}>
          <Title my={"sm"}>Upcoming events</Title>
          <Button
            leftIcon={<MdAdd size={20} color="white" className="text-white" />}
            size="xs"
            onClick={open}
            className="bg-color-1 hover:bg-color-1"
          >
            Add
          </Button>
        </Flex>

        <Card shadow="sm" mih={200} mah={500} component={ScrollArea}>
          {loadingEvents ? (
            <>
              {Array(5)
                .fill(null)
                .map((item) => (
                  <Skeleton w={"100%"} h={40} mb={"xs"} />
                ))}
            </>
          ) : (
            <>
              <Table striped highlightOnHover verticalSpacing={"sm"}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Link/Address</th>
                  </tr>
                </thead>
                <tbody>
                  {events?.length > 0 && (
                    <>
                      {events.map((item) => (
                        <tr>
                          <td>{item?.title}</td>
                          <td>
                            {formatDate(item?.date, "dd MMMM, yyyy")} at{" "}
                            {formatDate(item?.date, "hh:mma")}
                          </td>
                          <td>
                            <Spoiler
                              maxHeight={50}
                              showLabel="Show more"
                              hideLabel="Hide"
                            >
                              <Text>{item?.details}</Text>
                            </Spoiler>
                          </td>
                          <td>
                            <Flex align={"center"}>
                              <Text>{item?.link || "--"}</Text>
                              <Tooltip
                                withArrow
                                label={!clipboard.copied ? "Copy" : "Copied!"}
                              >
                                <ActionIcon
                                  variant="transparent"
                                  onClick={() => clipboard.copy(item?.link)}
                                >
                                  <IoCopyOutline />
                                </ActionIcon>
                              </Tooltip>
                            </Flex>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
            </>
          )}
        </Card>
      </Flex>

      <Modal
        opened={opened}
        onClose={close}
        title={<Text fw={600}>Add New Event</Text>}
        centered
      >
        <form onSubmit={handleAddEvent}>
          <TextInput
            withAsterisk
            label="Title"
            required
            placeholder=""
            value={newEvent.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
            mb={"sm"}
          />
          <DateTimePicker
            dropdownType="modal"
            withAsterisk
            label="Date"
            placeholder=""
            value={newEvent.date}
            onChange={(date) => handleInputChange("date", date)}
            mb={"sm"}
          />
          <TextInput
            required
            mb={"sm"}
            label={"Link or Address"}
            value={newEvent?.link}
            onChange={(e) => handleInputChange("link", e.target.value)}
          />
          <Textarea
            mb={"sm"}
            maxRows={10}
            label="Description"
            value={newEvent.description}
            withAsterisk
            onChange={(e) => handleInputChange("details", e.target.value)}
          />
          <Flex justify={"flex-end"}>
            <Button
              type="submit"
              size="xs"
              loading={loading}
              className="bg-color-1 hover:bg-color-1"
            >
              Proceed
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  );
};

export default UploadedEvents;
