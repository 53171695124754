import axios from "./axiosService";

export const addNewEvent = async (data) => {
    return await axios.post(`/events/new-event`, data)
}

export const getAdminEvents = async () => {
    return await axios.get(`/events/admin-events`)
}

export const getMinisterEvents = async () => {
    return await axios.get(`/events/minister-events`)
}