import React, { useState, useEffect } from "react";
import {
  MediaQuery,
  Burger,
  Header,
  Flex,
  Select,
  Space,
  Avatar,
  Menu,
} from "@mantine/core";
import { BsFillBellFill } from "react-icons/bs";
import { useUserContext, LOGOUT_USER, USER_PROFILE} from "../../context/UserContext";
import { AiFillHome } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { getInitials } from "../../utils/helper";

function DashboardHeader({ setOpened, opened, theme, translateComponent }) {
  const { user } = useUserContext();
  const { pathname } = useLocation();
  const [currentPathName, setCurrentpathName] = useState(null);
  const navigate = useNavigate();
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng") || i18next.language
  );

  const { userDispatch, userTokenDetailsDispatch } = useUserContext();

  const logout = () => {
    userTokenDetailsDispatch({
      type: LOGOUT_USER,
    });

    userDispatch({
      type: USER_PROFILE,
      payload: {},
    });
    navigate("/login");
  };

  useEffect(() => {
    setCurrentpathName(null);
    const updatedPathName = pathsDict[pathname];
    if (updatedPathName) setCurrentpathName(updatedPathName);
  }, [pathname]);

  useEffect(() => {
    i18next.on("languageChanged", (lng) => {
      setCurrentLanguage(lng);
      localStorage.setItem("i18nextLng", lng);
    });
  }, []);

  useEffect(() => {
    i18next.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleLanguageChange = (lang) => {
    i18next.changeLanguage(lang);
    setCurrentLanguage(lang);
  };

  return (
    <Header height={{ base: 80, md: 100 }} p="md">
      <Flex style={{ height: "100%" }} align="center" justify="space-between">
        <Flex align="center">
          <MediaQuery smallerThan="sm">
            <Burger
              className="mobile-burger-menu"
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              size="sm"
              color={theme?.colors?.gray[6]}
              mr="xl"
            />
          </MediaQuery>
          <AiFillHome
            style={{ color: "#F39B17", cursor: "pointer" }}
            onClick={currentPathName ? () => navigate(-1) : () => {}}
          />
          {currentPathName && (
            <>
              <p style={{ visibility: "hidden", fontSize: "0.8rem" }}>{"s"}</p>
              <p style={{ fontSize: "0.8rem", letterSpacing: "0.04em" }}>
                {"/ " + translateComponent(currentPathName)}
              </p>
            </>
          )}
        </Flex>

        <Flex align="center">
          <Select
            className="w-[120px]"
            styles={{
              input: {
                backgroundColor: "#FEF5EA",
                ":focus": {
                  border: "none",
                },
              },
            }}
            placeholder={
              currentLanguage && languageDict[currentLanguage]
                ? languageDict[currentLanguage]
                : ""
            }
            data={[
              { value: "en", label: "English" },
              { value: "fr", label: "French" },
              { value: "cm", label: "Chinese Mandarin" },
              { value: "hi", label: "Hindi" },
              { value: "es", label: "Spanish" },
              { value: "ar", label: "Arabic" },
            ]}
            value={
              currentLanguage
                ? {
                    value: currentLanguage,
                    label: languageDict[currentLanguage]
                      ? languageDict[currentLanguage]
                      : "",
                  }
                : {}
            }
            onChange={(val) => handleLanguageChange(val)}
          />
          <Space mx={5} />
          <BsFillBellFill className="text-color-1 cursor-pointer" size={20} />
          <Space mx={5} />

          <Menu shadow="md" width={150}>
            <Menu.Target>
              <Avatar
                className="cursor-pointer"
                radius={"50%"}
                size={"lg"}
                src={user?.image}
              >
                {getInitials(user?.name)}
              </Avatar>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={()=>navigate("/admin-settings")}>Settings</Menu.Item>
              <Menu.Item onClick={logout}>Logout</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Flex>
    </Header>
  );
}

const pathsDict = {
  "/admin-devotional/all": "Devotionals",
  "/dashboard/resources": "Resources",
  "/dashboard/my-network": "Network",
  "/dashboard/settings": "Settings",
  "/admin-resources/all": "Resources",
  "/admin-settings": "Settings",
  "/dashboard/resources/all": "All resources",
  "/dashboard/souls": "Souls",
  "/admin-souls/all": "Souls",
  "/admin-ministers/all": "Ministers",
  "/dashboard/outreaches": "Outreaches",
};

const languageDict = {
  en: "English",
  fr: "French",
  cm: "Chinese Mandarin",
  hi: "Hindi",
  es: "Spanish",
  ar: "Arabic",
};

export default DashboardHeader;
