import React, { useState } from 'react';
import CardWrapper from './CardWrapper';
import { Button, Flex, Select, Text } from '@mantine/core';
import { PiCaretRightBold } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import styles from './styles.module.css';
import { toast } from '../../utils/helper';
import { addNewOutreach } from '../../services/outreachServices';
import { useOutreachContext } from '../../context/OutreachContext';

function Card({ title, number, view, showUpload = false, path = "", translateComponent }) {
  const navigate = useNavigate();
  const [ showOutreachModal, setShowAddOutreachModal ] = useState(false);
  const [ newOutreachObj, setNewOutreachObj ] = useState({
    title: '',
    description: '',
  });
  const [ loading, setLoading ] = useState(false);
  const [ filesAdded, setFilesAdded ] = useState(null);
  const {
    outreaches,
    setOutreaches,
  } = useOutreachContext();

  const handleUpdateNewOutreachObj = (name, val) => {
    setNewOutreachObj((prevVal) => {
      return {
        ...prevVal,
        [name]: val,
      }
    })
  }

  const handleAddFiles = (val) => {
    if (val.length > 20) return toast("You can only upload up to 20 files").error()
    setFilesAdded(val);
  }

  const handleCreateNewOutreach = async () => {

    if (newOutreachObj.title.length < 1) return toast('Please enter a value for title').error();
    if (newOutreachObj.description.length < 1) return toast('Please enter a description').error();
    if (!filesAdded) return toast('Please add a file').error();

    setLoading(true);

    const formData = new FormData();
    Array.from(filesAdded).forEach(file => {
      formData.append('picture', file);
    })
    Object.keys(newOutreachObj || {}).forEach(key => {
      formData.append(`${key}`, newOutreachObj[key]);
    })

    const currentOutreaches = outreaches.slice();

    try {
      const res = (await addNewOutreach(formData)).data;
      
      currentOutreaches.unshift(res);
      setOutreaches(currentOutreaches);

      setLoading(false);
      setShowAddOutreachModal(false);
      toast('Successfully uploaded outreach!').success();

    } catch (error) {
      console.log(error);
      setLoading(false);
      toast(
          error.response ?
              error.response.status === 500 ?
                  'Something went wrong while trying to add your outreach'
              :
              error.response.data.message
          :
          'Something went wrong while trying to add your outreach'
      ).error();
    }
  }

  return (
    <CardWrapper bg={showUpload ? 'bg-[#045ED7]' : ""}>
      {!showUpload && <Flex direction={'row'} justify={'space-between'}>
        <Flex direction={'column'} justify={'space-between'}>
          <Text className='font-2 text-[#45464E] mt-0 sm:mt-3'>{title}</Text>
          <Text className='absolute bottom-10 sm:bottom-5 font-2 text-5xl text-[#45464E]'>{number}</Text>
        </Flex>

        <Flex direction={'column'} justify={'space-between'}>
          <Select
            className='w-[100px] border-none'
            placeholder={translateComponent && typeof translateComponent === 'function' ? translateComponent('This year') : 'This year'}
            data={[
              { value: "This year", label: translateComponent && typeof translateComponent === 'function' ? translateComponent('This year') : "This year" },
              { value: "This year", label: translateComponent && typeof translateComponent === 'function' ? translateComponent('This year') : "This year" },
              { value: "This year", label: translateComponent && typeof translateComponent === 'function' ? translateComponent('This year') : "This year" },
            ]} />

          <Flex className='absolute bottom-1 right-6 items-center cursor-pointer' onClick={() => navigate(path)}>
            <Text className=' text-color-1'>{view}</Text> &nbsp;
            <PiCaretRightBold className='text-color-1' />
          </Flex>
        </Flex>
      </Flex>}

      {showUpload &&
        <Flex direction={'column'} justify={'center'} className='items-center my-auto'>
          <Text className='w-[70%] text-white font-normal'>
            {
              translateComponent && typeof translateComponent === 'function' ? 
              translateComponent('Upload') + ' ' + translateComponent('pictures') +  ' ' + translateComponent('from') +  ' ' + translateComponent('your') +  ' ' + translateComponent('outreach') 
              :
              'Upload pictures from your outreach'
            }
          </Text>
          <Button className="border bg-white text-black w-[70%] font-2 mt-3 hover:text-white" onClick={() => setShowAddOutreachModal(true)}>
            {
              translateComponent && typeof translateComponent === 'function' ? 
              translateComponent('Upload') +  ' ' + translateComponent('Now')
              :
              'Upload Now'
            }
          </Button>
        </Flex>}
      {
        showOutreachModal && <div className={styles.add__Outreach__Wrapper}>
          <div className={styles.modal__Container}>
            <div className={styles.modal__Close__Container} onClick={() => setShowAddOutreachModal(false)}>
              <AiOutlineClose className={styles.modal__Close__Icon} />
            </div>
            <div className={styles.modal__Content}>
              <h2 className={styles.modal__Content__Header}>Upload new outreach</h2>
              <div className={styles.single__Modal__Item}>
                <p className={styles.input__Title}>Add outreach title</p>
                <input 
                  type="text" 
                  name="title"
                  value={newOutreachObj.title}
                  onChange={({ target }) => handleUpdateNewOutreachObj(target.name, target.value)}
                />
              </div>
              <div className={styles.single__Modal__Item}>
                <p className={styles.input__Title}>Add description</p>
                <textarea 
                  type="text" 
                  name="description"
                  value={newOutreachObj.description}
                  onChange={({ target }) => handleUpdateNewOutreachObj(target.name, target.value)}
                  rows={4}
                ></textarea>
              </div>
              <div className={styles.single__Modal__Item}>
                <p className={styles.input__Title}>
                  {
                    filesAdded ? 
                    `Added files (${filesAdded.length})`
                    :
                    "Select files"
                  }
                </p>
                {
                  filesAdded ? <div className={styles.file__Added__Wrapper}>
                      <AiOutlineClose className={styles.cancel__Icon} onClick={() => setFilesAdded(null)} />
                      <>
                          <div className={styles.file__Added__Content}>
                              {
                                  React.Children.toArray(Array.from(filesAdded).map(file => {
                                      return <>
                                          <p>{file.name}</p>
                                      </>
                                  }))
                              }
                          </div>
                      </>
                  </div> 
                  :
                  <input 
                    type='file'
                    name="files"
                    disabled={loading ? true : false}
                    onChange={({ target }) => handleAddFiles(target.files)}
                    accept={
                      'image/*'
                    }
                    multiple
                  />
                }
              </div>
              <button 
                className={styles.modal__Btn} 
                onClick={handleCreateNewOutreach}
                disabled={loading}
              >
                {
                  loading ? 'Please wait...' : 'Upload'
                }
              </button>
            </div>
          </div>
        </div>
      }
    </CardWrapper>
  );
}

export default Card;