import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineShareAlt } from "react-icons/ai";
import styles from "../adminDashboard/styles.module.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNewDevotionalComment,
  getSingleDevotionalContent,
  getSingleDevotionalContentForPublicUser,
} from "../../services/devotionalService";
import { toast } from "../../utils/helper";
import { useUserContext } from "../../context/UserContext";
import { useDisclosure, useClipboard } from "@mantine/hooks";
import { Button, Modal, Text, Flex, Tooltip, ActionIcon } from "@mantine/core";
import { FaRegCopy } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { formatDate } from "date-fns";

const SingleDevotionalPage = ({ normalUser, publicUser }) => {
  const { id } = useParams();
  const [currentDevotional, setCurrentDevotional] = useState(null);
  const [devotionalLoading, setDevotionalLoading] = useState(true);
  const [newComment, setNewComment] = useState(initialNewCommentState);
  const [loading, setLoading] = useState(false);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const clipboard = useClipboard({ timeout: 500 });
  const [downloadingFile, setDownloadingFile] = useState(false);

  useEffect(() => {
    if (publicUser) {
      getSingleDevotionalContentForPublicUser(id)
        .then((res) => {
          const foundDevotional = res.data;
          setDevotionalLoading(false);
          setCurrentDevotional(foundDevotional);
        })
        .catch((err) => {
          console.log(err);
          setDevotionalLoading(false);
        });
    }

    if (!publicUser) {
      getSingleDevotionalContent(id)
        .then((res) => {
          const foundDevotional = res.data;
          setDevotionalLoading(false);
          setCurrentDevotional(foundDevotional);
        })
        .catch((err) => {
          console.log(err);
          setDevotionalLoading(false);
        });
    }
  }, []);

  const handleCreateNewComment = async () => {
    if (newComment.comment.length < 1) return;

    const copyOfComment = { ...newComment };
    copyOfComment.devotional_id = currentDevotional._id;

    setLoading(true);

    try {
      const res = (await createNewDevotionalComment(copyOfComment)).data;

      const copyOfCurrentDevotional = structuredClone(currentDevotional);
      copyOfCurrentDevotional.comments.push({ ...res, user: user?.name });
      setCurrentDevotional(copyOfCurrentDevotional);
      setNewComment(initialNewCommentState);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

      toast("An error occured when trying to add your comment").error();
    }
  };

  const handleShareDevotional = async (devotional) => {
    const link = `${window.location.origin}/public/devotional/${devotional._id}`;

    try {
      await navigator.clipboard.writeText(link);
      toast("Successfully copied share link to clipboard!").success();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async () => {
    try {
      setDownloadingFile(true);
      const response = await fetch(currentDevotional?.file); // Replace with your Cloudinary URL
      const blob = await response.blob();

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `Devotion_${formatDate(
        currentDevotional?.dateForDevotional,
        "MMMM_yyyy"
      )}.pdf`;
      document.body.appendChild(link);
      link.click();

      // Clean up and revoke the object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setDownloadingFile(false);
    } catch (error) {
      setDownloadingFile(false);
      toast("Counldn't fetch full devotion").error();
    }
  };

  return (
    <>
      <main className={styles.admin__Content}>
        {devotionalLoading ? (
          <section>
            <p style={{ fontSize: "0.8rem" }}>Loading...</p>
          </section>
        ) : !currentDevotional ? (
          <section>
            <h1>Devotional not found</h1>
            <div className={styles.button__Wrapper}>
              <button
                className={styles.go__Back__Btn}
                onClick={() => navigate(-1)}
              >
                <span>Go home</span>
              </button>
            </div>
          </section>
        ) : (
          <section className={styles.devotional__Wrapper}>
            {!publicUser && (
              <div className={styles.button__Wrapper}>
                <button
                  className={styles.go__Back__Btn}
                  onClick={() => navigate(-1)}
                >
                  <AiOutlineArrowLeft />
                  <span>Go back</span>
                </button>
              </div>
            )}
            <div className={styles.devotional__Img}>
              <img
                src={
                  "https://img.freepik.com/free-vector/party-silhouettes-light-background_1048-5144.jpg?size=626&ext=jpg&ga=GA1.1.225976907.1673277028&semt=country_rows_v1"
                }
                alt="devotional"
              />
            </div>
            {!publicUser && (
              <div className={styles.button__Wrapper}>
                <button
                  className={`${styles.distribute__Btn} ${styles.single__Distribute}`}
                  onClick={
                    open
                    // () => handleShareDevotional(currentDevotional)
                  }
                >
                  <AiOutlineShareAlt />
                  <span>Distribute</span>
                </button>
              </div>
            )}
            <h3 className={styles.devotional__Header}>
              Today's Rhapsody of Realities
            </h3>
            <p className={styles.date}>
              {new Date(currentDevotional?.dateForDevotional).toDateString()}
            </p>
            <div className={styles.devotional__Content__Wrapper}>
              <h4>{currentDevotional.title}</h4>
              <p className={styles.devotional__Verse}>
                {currentDevotional.newDevotionalStructure ? (
                  <span>{currentDevotional.bibleVerseContent}</span>
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentDevotional?.passageContent,
                    }}
                  ></span>
                )}

                {currentDevotional.newDevotionalStructure ? (
                  <span>({currentDevotional.bibleVerse})</span>
                ) : (
                  <span>
                    ({currentDevotional.book} {currentDevotional.chapter}:
                    {currentDevotional.verse})
                  </span>
                )}
              </p>
              <p className={styles.devotional__Text}>
                {currentDevotional.devotionalContent}
              </p>

              <div className={styles.comment__Wrapper}>
                {normalUser && (
                  <div className={styles.new__Comment__Container}>
                    <textarea
                      value={newComment.comment}
                      onChange={({ target }) =>
                        setNewComment((prevValue) => {
                          return { ...prevValue, comment: target.value };
                        })
                      }
                      placeholder="Comment"
                    ></textarea>
                    {newComment.comment.length > 0 && (
                      <button
                        className={styles.comment__Btn}
                        onClick={handleCreateNewComment}
                        disabled={loading}
                      >
                        <span>{loading ? "Creating" : "Add comment"}</span>
                      </button>
                    )}
                  </div>
                )}
                <p
                  className={styles.devotional__Text}
                  style={{ marginTop: "2rem" }}
                >
                  Comments
                </p>
                {currentDevotional?.comments?.length < 1 ? (
                  <p className={styles.no__Comment__Text}>
                    No comments has been made yet on this devotional
                  </p>
                ) : (
                  React.Children.toArray(
                    currentDevotional?.comments.map((comment) => {
                      return (
                        <div className={styles.single__Comment__Item}>
                          {comment?.userProfileImage &&
                          comment?.userProfileImage.length > 0 ? (
                            <img
                              className={styles.user__Image__Item}
                              src={comment.userProfileImage}
                              alt="user profile"
                            />
                          ) : (
                            <span className={styles.user__Item}></span>
                          )}
                          <div className={styles.single__Comment__Detail}>
                            <h4>{comment.user}</h4>
                            <p>{comment.comment}</p>
                          </div>
                        </div>
                      );
                    })
                  )
                )}
              </div>
            </div>
          </section>
        )}
      </main>
      <Modal
        opened={opened}
        onClose={close}
        title={<Text fw={600}>Distribute</Text>}
        centered
      >
        <Flex maw={300} align={"center"}>
          <a
            className="break-all"
            // href={`${window.location.origin}/portal/public/devotional/${currentDevotional?._id}`}
            // target="_blank"
          >
            {`${window.location.origin}/public/devotional/${currentDevotional?._id}`}{" "}
          </a>
          <Tooltip
            label={clipboard.copied ? "Copied!" : "Copy"}
            withArrow
            position="right"
          >
            <ActionIcon
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/public/devotional/${currentDevotional?._id}`
                )
              }
            >
              <FaRegCopy size={20} />
            </ActionIcon>
          </Tooltip>
        </Flex>

        {!!currentDevotional?.file && (
          <Tooltip label={"Download full devotion"} withArrow>
            <Button
              size="xs"
              onClick={handleDownload}
              loading={downloadingFile}
              leftIcon={<HiDownload />}
              className="bg-color-1 hover:bg-color-1"
              my={"xs"}
            >
              Full Devotion
            </Button>
          </Tooltip>
        )}
      </Modal>
    </>
  );
};

const initialNewCommentState = {
  comment: "",
};

export default SingleDevotionalPage;
