import {
  Flex,
  Title,
  Card,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  Tooltip,
  Spoiler,
  Modal,
} from "@mantine/core";
import axiosService from "../services/axiosService";
import { useEffect, useState } from "react";
import { toast, truncateStringAtMiddle } from "../utils/helper";
import { format, formatDate } from "date-fns";
import { useDisclosure } from "@mantine/hooks";

const UploadedChurches = () => {
  const [loading, setLoading] = useState(false);
  const [churches, setChurches] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedItem, setSeletedItem] = useState({});

  const fetchChurches = async () => {
    try {
      setLoading(true);
      const res = await axiosService.get("/outreaches/churches/all");
      setChurches(res?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast(error?.response?.data?.message).error();
    }
  };

  useEffect(() => {
    fetchChurches();
  }, []);

  return (
    <>
      <Flex maw={"100%"} mih={"100vh"} direction={"column"}>
        <Title my={"sm"}>All Churches</Title>

        <Card shadow="sm" mih={200} mah={500} component={ScrollArea}>
          {loading ? (
            <>
              {Array(5)
                .fill(null)
                .map((item) => (
                  <Skeleton w={"100%"} h={40} mb={"xs"} />
                ))}
            </>
          ) : (
            <>
              <Table striped highlightOnHover verticalSpacing={"sm"}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date started</th>
                    <th>Description</th>
                    <th>Added by</th>
                    <th>Added on</th>
                  </tr>
                </thead>
                <tbody>
                  {churches.map((item, idx) => (
                    <tr
                      key={idx}
                      className="cursor-pointer"
                      onClick={() => {
                        setSeletedItem(item);
                        open();
                      }}
                    >
                      <td>{truncateStringAtMiddle(item?.title, 40) || "--"}</td>
                      <td>{formatDate(item?.date, "dd MMM, yyyy")}</td>
                      <td>
                        <Text>
                          {truncateStringAtMiddle(item?.details, 50) || "--"}
                        </Text>
                      </td>
                      <td>
                        <Tooltip
                          withArrow
                          hidden={item?.user?.name?.length < 40}
                          label={item?.user?.name}
                        >
                          <Text>
                            {truncateStringAtMiddle(item?.user?.name, 40)}
                          </Text>
                        </Tooltip>
                      </td>

                      <td>{formatDate(item?.createdAt, "dd MMM, yyyy")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Card>
      </Flex>

      {opened && selectedItem && (
        <Modal
          centered
          opened={opened}
          onClose={() => {
            close();
            setSeletedItem({});
          }}
          classNames={{
            header: "border-b mb-[15px]",
          }}
          title={
            <Flex direction={"column"}>
              <Text fw={600}>
                {truncateStringAtMiddle(selectedItem?.title, 40)}
              </Text>
              <Text c={"dimmed"}>
                {truncateStringAtMiddle(selectedItem?.user?.name, 40)}
              </Text>
            </Flex>
          }
        >
          <Text fw={600} c={"dimmed"} mb={"xs"}>
            <span className="text-black">Date started: </span>
            <span>
              {formatDate(selectedItem?.date, "dd MMM, yyyy") || "--"}
            </span>
          </Text>
          <Spoiler maxHeight={50} showLabel="Show more" hideLabel="Hide" mb={"xs"}>
          <Text fw={600} c={"dimmed"} >
            <span className="text-black">Description: </span>
            <span>{selectedItem?.details || "--"}</span>
          </Text>
          </Spoiler>
        </Modal>
      )}
    </>
  );
};

export default UploadedChurches;
