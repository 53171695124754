import React, { useEffect, useState } from "react";
import {
  getMinisterOutreaches,
  addNewOutreach,
} from "../../../services/outreachServices";
import {
  Flex,
  Button,
  Title,
  Grid,
  Skeleton,
  Card,
  Image,
  Center,
  Text,
  Group,
  Tooltip,
  Spoiler,
  Modal,
  ScrollArea,
  TextInput,
  FileInput,
  Textarea,
  Select,
} from "@mantine/core";
import { MdAdd } from "react-icons/md";
import { Carousel } from "@mantine/carousel";
import {
  toast,
  truncateStringAtMiddle,
  objectHasNullProperty,
} from "../../../utils/helper";
import { formatDate } from "date-fns";
import { useViewportSize, useDisclosure } from "@mantine/hooks";

const ResourceItem = ({ item, handleSelectItem }) => {
  const images = [...item?.pictures];
  const details = item?.description;
  const title = item?.title;
  const createdAt = item?.createdAt;
  const multiFiles = images?.length > 1;
  const [showAll, setShowAll] = useState(false);
  const { width } = useViewportSize();
  const isSmallerScreen = width <= 500;

  return (
    <Grid.Col xs={12} md={6} lg={4}>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        className="cursor-pointer"
        h={!isSmallerScreen ? 350 : 400}
        onClick={handleSelectItem}
      >
        <Card.Section>
          {!multiFiles ? (
            <Image height={200} src={images[0]} fit="fill" />
          ) : (
            <Carousel withIndicators height={200}>
              {images.map((item, idx) => (
                <Carousel.Slide className="border">
                  <Image
                    className="h-full w-full"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectItem(idx);
                    }}
                    src={item}
                    fit="fill"
                    height={200}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          )}
        </Card.Section>

        <Flex direction={"column"} h={"100%"}>
          <Group position="apart" mt="md" mb="xs">
            <Tooltip label={title} hidden={title < 20} withArrow>
              <Text weight={600}>{truncateStringAtMiddle(title, 20)}</Text>
            </Tooltip>
            <Text c={"dimmed"} size={"xs"}>
              {formatDate(createdAt, "dd MMM,  yyyy")}
            </Text>
          </Group>
          <Text>{truncateStringAtMiddle(details, 100)}</Text>
        </Flex>
      </Card>
    </Grid.Col>
  );
};

const OutreachManager = ({ translateComponent }) => {
  const [loadingOutreaches, setLoadingOutreaches] = useState(false);
  const [outreaches, setOutreaches] = useState([]);
  const [allOutreaches, setAllOutreaches] = useState([]);
  const [selectOption, setSelectOption] = useState("all");
  const [selectedItem, setSelectedItem] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const [newOutreach, setNewOutreach] = useState({
    title: "",
    description: "",
    pictures: null,
  });

  const fetchOutreaches = async () => {
    try {
      setLoadingOutreaches(true);
      const res = await getMinisterOutreaches();
      setOutreaches(res.data?.outreaches || []);
      setAllOutreaches(res?.data?.allOutreaches || []);
      setLoadingOutreaches(false);
    } catch (error) {
      setLoadingOutreaches(false);
      toast(error?.response?.data?.message).error();
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    try {
      if (objectHasNullProperty(newOutreach)) {
        return toast("All values are required!").error();
      }
      setLoading(true);
      const formData = new FormData();
      Object.entries(newOutreach).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            formData.append("picture", item);
          });
        } else {
          formData.append(key, value);
        }
      });
      const res = await addNewOutreach(formData);
      setOutreaches([res?.data, ...outreaches]);
      setLoading(false);
      close();
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast(error?.response?.data?.message).error();
    }
  };

  useEffect(() => {
    fetchOutreaches();
  }, []);

  const handleFileInput = (name, value) =>
    setNewOutreach({ ...newOutreach, [name]: value });

  const data = selectOption !== "all" ? outreaches : allOutreaches;
  const outreachesIsNotEmpty = outreaches?.length > 0;
  return (
    <>
      <Flex maw={"100%"} mih={"100vh"} direction={"column"}>
        <Flex justify={"space-between"} align={"center"}>
          <Flex direction={"column"}>
            <Title my={"sm"}>Outreaches</Title>
            <Flex w={170} mb={"md"}>
              <Tooltip label="Filter outreaches" withArrow>
                <Select
                  // size="xs"
                  value={selectOption}
                  onChange={setSelectOption}
                  allowDeselect={false}
                  data={[
                    { label: "All Outreaches", value: "all" },
                    { label: "Your Outreaches", value: "yours" },
                  ]}
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Button
            onClick={() => {
              if (selectedItem) {
                setSelectedItem(null);
              }
              open();
            }}
            leftIcon={<MdAdd size={20} color="white" className="text-white" />}
            size="xs"
            // onClick={open}
            className="bg-color-1 hover:bg-color-1"
          >
            Add
          </Button>
        </Flex>

        {loadingOutreaches ? (
          <Grid>
            {Array(6)
              .fill(null)
              .map((_, key) => (
                <Grid.Col xs={12} md={6} lg={4} key={key}>
                  <Skeleton h={250} w={"100%"} />
                </Grid.Col>
              ))}
          </Grid>
        ) : (
          <>
            {outreachesIsNotEmpty ? (
              <Grid>
                {data.map((item, idx) => (
                  <ResourceItem
                    item={item}
                    key={idx}
                    handleSelectItem={(fileIndex) => {
                      setSelectedItem({
                        ...item,
                        details: item?.description,
                        index: fileIndex,
                      });
                      open();
                    }}
                  />
                ))}
              </Grid>
            ) : (
              <Center>
                <Text>No data found</Text>
              </Center>
            )}
          </>
        )}
      </Flex>

      {opened && (
        <Modal
          scrollAreaComponent={ScrollArea.Autosize}
          size={selectedItem ? "lg" : "md"}
          centered
          opened={opened}
          onClose={() => {
            close();
            if (!selectedItem) {
              setSelectedItem(null);
            }
          }}
          classNames={{
            header: "border-b mb-[15px]",
          }}
          title={
            <Text fw={600}>
              {selectedItem ? (
                <>{truncateStringAtMiddle(selectedItem?.title, 40)}</>
              ) : (
                <>Upload new Outreach</>
              )}
            </Text>
          }
        >
          {selectedItem ? (
            <>
              {selectedItem?.pictures?.length > 1 ? (
                <Carousel
                  withIndicators
                  height={400}
                  initialSlide={selectedItem?.index || 0}
                >
                  {selectedItem?.pictures?.map((item) => (
                    <Carousel.Slide className="border">
                      <Image
                        className="h-full w-full"
                        src={item}
                        fit="fill"
                        height={400}
                      />
                    </Carousel.Slide>
                  ))}
                </Carousel>
              ) : (
                <Image
                  height={400}
                  //   fit="fill"
                  src={selectedItem?.pictures[0]}
                />
              )}

              <Spoiler
                maxHeight={100}
                my={"sm"}
                showLabel="Show more"
                hideLabel="Hide"
              >
                <Text>{selectedItem?.description}</Text>
              </Spoiler>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <TextInput
                onChange={(e) => handleFileInput("title", e.target.value)}
                label="Title"
                mb={"xs"}
                required
                withAsterisk
                value={newOutreach?.title}
              />
              <Textarea
                required
                withAsterisk
                onChange={(e) => handleFileInput("description", e.target.value)}
                label="Description"
                mb={"xs"}
                value={newOutreach?.description}
              />
              <FileInput
                required
                withAsterisk
                value={newOutreach?.pictures}
                onChange={(payload) => handleFileInput("pictures", payload)}
                classNames={{}}
                maw={"100%"}
                mb={"xs"}
                label="Select files"
                placeholder="Upload files"
                multiple
              />
              <Button
                type="submit"
                loading={loading}
                size="xs"
                className="bg-color-1 hover:bg-color-1"
              >
                Upload
              </Button>
            </form>
          )}
        </Modal>
      )}
    </>
  );
};

export default OutreachManager;
