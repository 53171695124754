import { notifications } from "@mantine/notifications";

export const inputStyles = {
  backgroundColor: "#EFF1F9",
  // fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 400,
  ":focus": {
    border: "1px solid #F39B17",
  },
};

export const toast = (message, title) => {
  return {
    success: () =>
      notifications.show({
        color: "green",
        title: title ? title : "Success",
        message,
        withBorder: true,
        // autoClose: 5000
      }),
    error: () =>
      notifications.show({
        color: "red",
        title: title ? title : "Failed",
        message,
        withBorder: true,
      }),
  };
};

export const outreachType = [
  {
    value: "Non-denominational Ministry",
    label: "Non-denominational Ministry",
  },
  { value: "Evangelistic Outreach", label: "Evangelistic Outreach" },
  { value: "	Youth Ministry", label: "	Youth Ministry" },
  { value: "Children’s Ministry", label: "Children’s Ministry" },
  { value: "Missions Organization", label: "Missions Organization" },
  { value: "Community Outreach", label: "Community Outreach" },
  { value: "Discipleship Group", label: "Discipleship Group" },
  { value: "Prayer Ministry", label: "Prayer Ministry" },
  { value: "Counseling Ministry", label: "Counseling Ministry" },
  { value: "Media Ministry", label: "Media Ministry" },
  { value: "Women’s Ministry", label: "Women’s Ministry" },
  { value: "Men’s Ministry", label: "Men’s Ministry" },
  { value: "Bible Study Group", label: "Bible Study Group" },
  { value: "Home Fellowship", label: "Home Fellowship" },
  { value: "Technology Ministry", label: "Technology Ministry" },
  { value: "Sports Ministry", label: "Sports Ministry" },
  { value: "Drama Ministry", label: "Drama Ministry" },
  { value: "Leadership Development", label: "Leadership Development" },
  { value: "Humanitarian Aid", label: "Humanitarian Aid" },
  { value: "Evangelism Training", label: "Evangelism Training" },
  { value: "Online Ministry", label: "Online Ministry" },
  { value: "Church Ministry", label: "Church Ministry" },
];

export const userTitles = [
  { value: "Bishop", label: "Bishop" },
  { value: "Reverend", label: "Reverend" },
  { value: "Pastor", label: "Pastor" },
  { value: "Deacon", label: "Deacon" },
  { value: "Deaconess", label: "Deaconess" },
  { value: "Doctor", label: "Doctor" },
  { value: "Brother", label: "Brother" },
  { value: "Sister", label: "Sister" },
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
];

export function isJSONStringRegex(str) {
  return /^[\],:{}\s]*$/.test(
    str
      .replace(/\\["\\\/bfnrtu]/g, "@")
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        "]"
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
  );
}

export function getInitials(name) {
  const firstName = name.toUpperCase()?.split(" ")[0];
  const lastName = name.toUpperCase()?.split(" ")[1];
  if (firstName && lastName) return firstName[0] + lastName[0];
  return firstName[0] + firstName[1];
}

// function convertToCSV(data: []) {
//     const header = Object.keys(data[0]).join(',') + '\n';

//     const rows = data.map(item => {
//         const values = Object.values(item).map(value => {
//             // If the value is an object and has a "name" property, return its "name"
//             if (typeof value === 'object' && value !== null && value.name) {
//                 return value.name;
//             }
//             // If the value is a string in JSON format, parse it and return its "name" property
//             if (typeof value === 'string') {
//                 try {
//                     const parsedValue = JSON.parse(value);
//                     if (parsedValue && parsedValue.name) {
//                         return parsedValue.name;
//                     }
//                 } catch (error) {
//                     // JSON parsing failed, ignore and return the original value
//                 }
//             }
//             // Otherwise, return the value as is
//             return value;
//         });
//         return values.join(',') + '\n';
//     }).join('');

//     // console.log("header", header);
//     // console.log("rows", rows);
//     return header + rows;
// }

// export function downloadCSV(data, filename = "data") {
//     const csv = convertToCSV(data);
//     const blob = new Blob([csv], { type: 'text/csv' });
//     const url = window.URL.createObjectURL(blob);

//     const a = document.createElement('a');
//     a.style.display = 'none';
//     a.href = url;
//     a.download = filename;
//     document.body.appendChild(a);
//     a.click();
//     window.URL.revokeObjectURL(url);
// }

function convertToCSV(data = [], excludeKeys = []) {
  const header =
    Object.keys(data[0])
      .filter((key) => !excludeKeys.includes(key))
      .join(",") + "\n";

  const rows = data
    .map((item) => {
      const values = Object.entries(item)
        .map(([key, value]) => {
          if (excludeKeys.includes(key)) {
            return null;
          }

          // If the value is an object and has a "name" property, return its "name"
          if (typeof value === "object" && value !== null && value.name) {
            return value.name;
          }

          // If the value is a string in JSON format, parse it and return its "name" property
          if (typeof value === "string") {
            try {
              const parsedValue = JSON.parse(value);
              if (parsedValue && parsedValue.name) {
                return parsedValue.name;
              }
            } catch (error) {
              // JSON parsing failed, ignore and return the original value
            }
          }

          // Otherwise, return the value as is
          return value;
        })
        .filter((value) => value !== null); // Remove null values for excluded keys

      return values.join(",") + "\n";
    })
    .join("");

  return header + rows;
}

export function downloadCSV(data, filename = "data", excludeKeys = []) {
  const csv = convertToCSV(data, excludeKeys);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

export function truncateStringAtMiddle(text, maxLength) {
  if (!text) return;
  // If the text length is less than or equal to the max length, return the original text
  if (text.length <= maxLength) {
    return text;
  }

  // Calculate how many characters to show from the start and end
  const partLength = Math.floor((maxLength - 3) / 2);

  // Get the start and end parts of the text
  const start = text.substring(0, partLength);
  const end = text.substring(text.length - partLength);

  // Return the truncated text with ellipsis in the middle
  return `${start}...${end}`;
}

export function getFileExtension(url) {
  return url.split(".").pop().split(/\#|\?/)[0];
}

export function objectHasNullProperty(obj) {
  return Object.values(obj).some((value) => value === null);
}
