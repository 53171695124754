import React, { useEffect, useState } from "react"
import { Card, Text, Flex } from "@mantine/core"
import AccountSettings from "../../components/settings/AccountSettings";
import MinistrySettings from "../../components/settings/MinistrySettings";
import SecuritySettings from "../../components/settings/SecuritySettings";
import { useNavigate } from "react-router-dom";
import { dashboardRoutes } from "../../utils/routes"

const Settings = ({ isAdmin }) => {

    const settingsOptions = isAdmin ? [
        { name: "Account", path: "account" },
        { name: "Security", path: "security" }
    ] :
    [
        { name: "Account", path: "account" },
        { name: "Ministry Affilation", path: "ministry-affilation" },
        { name: "Security", path: "security" }
    ];
    const [currentSettings, setCurrentSettings] = useState(settingsOptions[0].path)
    const navigate = useNavigate()

    const settingType = new URLSearchParams(window.location.search).get("type")

    useEffect(() => {
        if (settingType) {
            const correctTypes = [settingsOptions[0]?.path, settingsOptions[1]?.path, settingsOptions[2]?.path]
            if (correctTypes.indexOf(settingType) !== -1) {
                setCurrentSettings(settingType)
            }
        }
    }, [settingType])

    const [loading, setLoading] = useState(false)


    const handleSelectSettings = (path) => {
        if (loading) return;
        if (isAdmin) {
            navigate(`/admin-settings?type=${path}`)
        }else {
            navigate(`${dashboardRoutes.settings}?type=${path}`)
        }
        setCurrentSettings(path)
    }
    return (
        <>
            <Card className="min-h-[500px]">
                <Flex direction={'row'} justify={'space-around'} className="w-[60%]">
                    {settingsOptions.map(option => (
                        <Text
                            onClick={() => handleSelectSettings(option.path)}
                            className={`border-t-0 border-r-0 border-l-0 font-normal text-base ${currentSettings === option.path && "border-color-1 border-[3px]"} cursor-pointer pb-1 ${currentSettings === option.path ? 'text-black' : 'text-color-2'}`}>{option.name}</Text>
                    ))}
                </Flex>

                {
                    isAdmin ? <>
                        {currentSettings === settingsOptions[0]?.path && <AccountSettings
                        loading={loading} setLoading={setLoading} />}

                        {currentSettings === settingsOptions[1]?.path && <SecuritySettings
                            loading={loading} setLoading={setLoading} />}
                    </> :
                    <>
                        {currentSettings === settingsOptions[0]?.path && <AccountSettings
                            loading={loading} setLoading={setLoading} />}

                        {currentSettings === settingsOptions[1]?.path && <MinistrySettings
                            loading={loading} setLoading={setLoading} />}

                        {currentSettings === settingsOptions[2]?.path && <SecuritySettings
                            loading={loading} setLoading={setLoading} />}   
                    </>
                }
            </Card>
        </>
    );
}

export default Settings;