import React from 'react';
import { Card, Image, Flex, Text, Button } from '@mantine/core';
import { BsShare } from "react-icons/bs";
import raphsody from "../../images/raphsody.png";
import { useLocation } from 'react-router-dom';
import { dashboardRoutes } from "../../utils/routes"
import UsersComment from './UsersComment';
// bg-white px-5 pt-[7.5px] pb-5 sm:px-[7.5px]

const ShareBtn = () => {
    return (
        <>
            <Button
                leftIcon={<BsShare />}
                className='bg-[#219653]  w-[80%]
                            mt-5
                            sm:mt-0
                             sm:w-[35%]
                            font-semibold
                            h-11
                            font-3
                            text-base
                             rounded-2xl
                             hover:opacity-80
                             transition duration-300 ease-in-out
                             hover:bg-[#219653]
                             '
            >Distribute</Button>
        </>
    )
}


function Raphsody(props) {
    const { pathname } = useLocation()
    return (
        <Card
            padding={10}
            className="col-span-12 rounded-xl xl:col-span-8">
            <Image
                className='sm:px-4 pt-2'
                src={raphsody}
                height={200}
                radius={10}
            />

            <Flex
                direction={"column"} justify={'center'} className='items-center m-5'>
                {pathname === dashboardRoutes.resources &&
                    <>
                        <ShareBtn />
                        <br />
                    </>
                }
                <Text className='font-2 text-[#45464E] text-2xl font-medium'>Today’s Rhapsody Of Realities</Text>
                <Text className='font-medium text-color-1 mt-2 text-base'>TUE AUG 08 2023</Text>
            </Flex>

            <Flex
                bg={'#FAFAFA'}
                className='px-1 md:px-10 py-5 items-center'
                direction={"column"}
                justify={'center'}
            >
                <Text className='text-[#545153] font-bold text-xl w-[90%] sm:text-center sm:w-full'>CHRIST—OUR RIGHT-STANDING BEFORE THE FATHER</Text>

                <Flex direction={'column'} className='my-5' justify={'space-around'}>
                    <Text className={`text-[#3A3A3A] 
                   text-lg
                    font-3
                    font-bold
                    mx-auto
                    w-[90%]
                  
                    italic`}>But of him are ye in Christ Jesus, who of God is made unto us wisdom, and righteousness, and sanctification, and redemption (1 Corinthians 1:30).</Text>



                    {pathname === dashboardRoutes.resources ?
                        <>
                            <Text className='text-[#030849] font-normal text-lg mx-auto mt-3
                    w-[90%]
                    sm:w-[100%] font-3'>
                                I’ve often said that the biggest subject in all of God’s Word is righteousness. Righteousness is the nature of God that defines His character...
                            </Text>

                            <Text className='text-[#030849] font-normal text-lg mx-auto mt-3
                    w-[90%]
                    sm:w-[100%] font-3'>
                                I’ve often said that the biggest subject in all of God’s Word is righteousness. Righteousness is the nature of God that defines His character...
                            </Text>
                        </>
                        : <Text className='text-[#030849] font-normal text-lg mx-auto mt-3
                    w-[90%]
                    sm:w-[80%] font-3'>
                            I’ve often said that the biggest subject in all of God’s Word is righteousness. Righteousness is the nature of God that defines His character...
                        </Text>}

                    <Flex
                        mt={15}
                        // direction={'row'}
                        className='flex-col sm:flex-row items-center'
                        justify={'space-around'}
                    // wrap={'wrap'}
                    >

                        {pathname === dashboardRoutes.resources ?
                            <UsersComment />
                            :
                            <>
                                <Button
                                    className='bg-[#C50909]
                            w-[80%]
                             sm:w-[55%]
                            h-11
                            font-3
                            font-bold
                             rounded-2xl 
                             hover:opacity-80
                             transition duration-300 ease-in-out
                              hover:bg-[#C50909]'
                                >READ THE FULL ARTICLE</Button>
                                <ShareBtn />
                            </>}


                    </Flex>
                </Flex>

            </Flex>
        </Card>
    );
}

export default Raphsody;


// Helvetica

// Nunito