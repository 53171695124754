import axios from "./axiosService";

export const addNewOutreach = async (data) => {
    return await axios.post(`/outreaches/new-outreach`, data)
}

export const getAdminOutreaches = async () => {
    return await axios.get(`/outreaches/admin-outreaches`)
}

export const getMinisterOutreaches = async () => {
    return await axios.get(`/outreaches/minister-outreaches`)
}