import {
  Flex,
  Title,
  Button,
  Table,
  Card,
  ScrollArea,
  Text,
  Spoiler,
  Modal,
  Center,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "../../utils/helper";
import axiosClient from "../../services/axiosService";
import { MdAdd } from "react-icons/md";
import { formatDate } from "date-fns";
import { useDisclosure } from "@mantine/hooks";
import { DateTimePicker, DateInput } from "@mantine/dates";

const ChurchManager = () => {
  const [loadingChurches, setLoadingChurches] = useState(false);
  const [loading, setLoading] = useState(false);
  const [churches, setChurches] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [newChurch, setNewChurch] = useState({
    title: "",
    date: new Date(),
    details: "",
  });

  const fetchChurches = async () => {
    try {
      setLoadingChurches(true);
      const res = await axiosClient.get("/outreaches/churches");
      setChurches(res?.data || []);
      setLoadingChurches(false);
    } catch (error) {
      setLoadingChurches(false);
      toast(error?.response.data?.message).error();
    }
  };

  useEffect(() => {
    fetchChurches();
  }, []);

  const handleSubmit = async (e) => {
    try {
      e?.preventDefault();
      setLoading(true);
      await axiosClient.post("/outreaches/churches", newChurch);
      setLoading(false);
      close();
    } catch (error) {
      setLoading(false);
      toast(error?.response?.data?.message).error();
    }
  };

  const handleInputChange = (name, value) =>
    setNewChurch({ ...newChurch, [name]: value });

  return (
    <>
      <Flex maw={"100%"} mih={"100vh"} direction={"column"}>
        <Flex justify={"space-between"}>
          <Title my={"sm"}>Church Manager</Title>
          <Button
            leftIcon={<MdAdd size={20} color="white" className="text-white" />}
            size="xs"
            onClick={open}
            className="bg-color-1 hover:bg-color-1"
          >
            Add
          </Button>
        </Flex>

        <Card shadow="sm" mih={200} mah={500} component={ScrollArea}>
          <Table striped highlightOnHover verticalSpacing={"sm"}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {churches?.length > 0 && (
                <>
                  {churches.map((item, key) => (
                    <tr key={key}>
                      <td>{item?.title}</td>
                      <td>
                        {formatDate(item?.date || new Date(), "dd MMMM, yyyy")}{" "}
                        at {formatDate(item?.date, "hh:mma")}
                      </td>
                      <td>{formatDate(item?.createdAt, "dd MMMM, yyyy")}</td>
                      <td>
                        <Spoiler
                          maxHeight={50}
                          showLabel="Show more"
                          hideLabel="Hide"
                        >
                          <Text>{item?.details}</Text>
                        </Spoiler>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>

          {churches?.length === 0 && (
            <Center my={"xl"}>
              <Text>No data found</Text>
            </Center>
          )}
        </Card>
      </Flex>

      <Modal
        opened={opened}
        onClose={() => {
          close();
          setNewChurch({
            title: "",
            date: "",
            details: "",
          });
        }}
        title={<Text fw={600}>Add Church</Text>}
        centered
      >
        {/* Modal content */}
        <form onSubmit={handleSubmit}>
          <TextInput
            withAsterisk
            label="Title"
            required
            placeholder=""
            value={newChurch.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
            mb={"sm"}
          />
          <DateInput
            required
            withAsterisk
            popoverProps={{ withinPortal: true, position: "top" }}
            value={newChurch.date}
            onChange={(date) => handleInputChange("date", date)}
            label="Date"
            mb={"sm"}
          />
          <Textarea
            mb={"sm"}
            maxRows={10}
            label="Description"
            value={newChurch.details}
            withAsterisk
            onChange={(e) => handleInputChange("details", e.target.value)}
          />
          <Flex justify={"flex-end"}>
            <Button
              type="submit"
              size="xs"
              loading={loading}
              className="bg-color-1 hover:bg-color-1"
            >
              Proceed
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  );
};

export default ChurchManager;
