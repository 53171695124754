import React, { useState } from 'react';

import { Flex, Button, Text, PasswordInput, TextInput } from '@mantine/core';
import { IoMailOutline } from "react-icons/io5";
import { BiLockAlt } from "react-icons/bi"
import FormLayer from '../components/FormLayer';
import { inputStyles, toast } from "../utils/helper";
import { login } from '../services/userService'
import { USER_PROFILE, USER_TOKEN, useUserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
// import { toast } from "react-toastify";


const Login = (props) => {
    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    const [loading, setLoading] = useState(false)

    const { userDispatch, userTokenDetailsDispatch } = useUserContext();

    const handleChange = ({ target: { name, value } }) => setUser({ ...user, [name]: value })

    const navigate = useNavigate()

    const redirectUrl = new URLSearchParams(window.location.search).get("redirectUrl")

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        login(user).then(res => {
            console.log(res.data)
            setLoading(false)
            toast(res.data.message).success()
            userDispatch({
                type: USER_PROFILE,
                payload: res.data.data
            })
            userTokenDetailsDispatch({
                type: USER_TOKEN,
                payload: res.headers["auth-token"]
            })

            if (res.data.data.accountType === 'ADMIN') return navigate('/admin-dashboard');
            
            navigate(redirectUrl ? redirectUrl : "/dashboard")
            // window.location.href = "/dashboard"
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
            // toast.error(err?.response?.data?.message)
        })
    }

    return (
        <FormLayer>
            <Text
                color="dark"
                align="center"
                my={2}
                className='font-bold text-lg font-2'
            >
                Welcome back!
            </Text>
            <Text size="sm" fw={200} align="center" my={2} className='text-color-2 font-normal'>
                Login to your account
            </Text>
            <form onSubmit={handleSubmit}>
                <Flex
                    mt={15}
                    direction={"column"}
                >
                    <TextInput
                        name='email'
                        value={user.email}
                        onChange={handleChange}
                        size='md'
                        // className='!bg-color-4 focus:!border-color-1 !border-none'
                        styles={{
                            input: inputStyles
                        }}
                        placeholder='Email Address'
                        icon={<IoMailOutline className='text-lg' />} />


                    <PasswordInput
                        name='password'
                        value={user.password}
                        onChange={handleChange}
                        mt={20}
                        size='md'
                        styles={{
                            select: inputStyles
                        }}
                        placeholder='Password'
                        icon={<BiLockAlt />} />

                    <Text className='text-color-1 mt-2 cursor-pointer text-right'>Reset password</Text>
                    <Text className='text-center mt-7 text-color-2'>Don’t have an account? <span className='text-color-1 cursor-pointer' onClick={() => navigate("/register")}>Sign Up</span></Text>

                    <Button
                        loading={loading}
                        loaderPosition='center'
                        fullWidth
                        mt="xl"
                        size="md"
                        type="submit"
                        radius={"md"}
                        className="bg-color-1 font-normal text-lg text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:bg-color-1"
                    >
                        Login
                    </Button>
                </Flex>

            </form>
        </FormLayer>
    );
}

export default Login;