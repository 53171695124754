

export const dashboardRoutes = {
    '/': '/dashboard',
    "resources": "/dashboard/resources",
    "my_network": "/dashboard/my-network",
    "settings": "/dashboard/settings",
    "all_resources": "/dashboard/resources/all",
    "souls": "/dashboard/souls",
    "outreaches": "/dashboard/outreaches",
}