import React, { useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import styles from "./styles.module.css";
import { useDevotionalContext } from "../../context/DevotionalContext";
import { useNavigate } from "react-router-dom";
import { getDevotionalsForAdmin } from "../../services/devotionalService";
import { useUserContext } from "../../context/UserContext";
import DevotionalCard from "../../components/DevotionalCard/DevotionalCard";
import { useResourcesContext } from "../../context/ResourcesContext";
import { getAdminResources } from "../../services/resourcesService";
import { getAdminSouls } from "../../services/soulServices";
import { getAdminEvents } from "../../services/eventServices";
import { useEventsContext } from "../../context/EventContext";
import { useOutreachContext } from "../../context/OutreachContext";
import { useSoulsContext } from "../../context/SoulsContext";
import { getAdminOutreaches } from "../../services/outreachServices";
import { getAllMinisters } from "../../services/userService";
import { useMinistersContext } from "../../context/MinisterContext";

const AdminDashboard = ({ translateComponent }) => {
    const [ currentDevotional, setCurrentDevotional ] = useState(null);
    const navigate = useNavigate();
    const { 
        devotionals,
        setDevotionals,
        devotionalsLoading,
        setDevotionalsLoading,
        devotionalsLoaded,
        setDevotionalsLoaded,
    } = useDevotionalContext();
    const { user } = useUserContext();
    const {
        setResources,
        setResourcesLoading,
        resourcesLoaded,
        setResourcesLoaded,
    } = useResourcesContext();

    const {
        setEvents,
        setEventsLoading,
        eventsLoaded,
        setEventsLoaded,
    } = useEventsContext();

    const {
        outreaches,
        setOutreaches,
        setOutreachesLoading,
        outreachesLoaded,
        setOutreachesLoaded,
    } = useOutreachContext();

    const {
        souls,
        setSouls,
        setSoulsLoading,
        soulsLoaded,
        setSoulsLoaded,
    } = useSoulsContext();

    const {
        ministers,
        setMinisters,
        ministersLoading,
        setMinistersLoading,
        ministersLoaded,
        setMinistersLoaded,
    } = useMinistersContext();

    const [ today, todayFormatted ] = [
        new Date(),
        new Date().toISOString().split('T')[0],
    ];

    useEffect(() => {

        if (user?.accountType !== 'ADMIN') return navigate('/dashboard');
        
        // if (!biblesLoaded) {
        //     setBiblesLoading(true);
        //     getBibleVersions().then(res => {
        //         setBibles(res?.data?.data?.sort((a, b) => a?.name?.localeCompare(b?.name)));
        //         setBiblesLoaded(true);
        //         setBiblesLoading(false);
        //     }).catch(err => {
        //         console.log(err);
        //         setBiblesLoading(false);
        //     })
        // }

        if (!devotionalsLoaded) {
            setDevotionalsLoading(true);

            getDevotionalsForAdmin().then(res => {
                const devotionalsFetched = res.data;
    
                setDevotionals(devotionalsFetched);
                setDevotionalsLoading(false);
                setDevotionalsLoaded(true);
        
                const dailyDevotionalIndex = devotionalsFetched.findIndex(
                    devotional => 
                        devotional.dateForDevotional && 
                        !(new Date(devotional.dateForDevotional) == 'Invalid Date') &&
                        new Date(devotional.dateForDevotional).toDateString() === today.toDateString()
                );
                if (dailyDevotionalIndex === -1) return
                
                setCurrentDevotional(dailyDevotionalIndex);
            }).catch(err => {
                console.log(err);
                setDevotionalsLoading(false);
            })
        }

        if (!resourcesLoaded) {
            setResourcesLoading(true);

            getAdminResources().then(res => {
                setResourcesLoading(false);
                setResourcesLoaded(true);

                setResources(res.data);
            }).catch(err => {
                console.log(err);
                setResourcesLoading(false);
            })
        }

        if (!soulsLoaded) {
            getAdminSouls().then(res => {
                setSouls(res.data);

                setSoulsLoaded(true);
                setSoulsLoading(false);
            }).catch(err => {
                console.log(err);
                setSoulsLoading(false);
            })
        }

        if (!eventsLoaded) {
            getAdminEvents().then(res => {
                setEvents(res.data);

                setEventsLoaded(true);
                setEventsLoading(false);
            }).catch(err => {
                console.log(err);
                setEventsLoading(false);
            })
        }

        if (!outreachesLoaded) {
            getAdminOutreaches().then(res => {
                setOutreaches(res.data);

                setOutreachesLoaded(true);
                setOutreachesLoading(false);
            }).catch(err => {
                console.log(err);
                setOutreachesLoading(false);
            })
        }
        
        if (!ministersLoaded) {
            setMinistersLoading(true);

            getAllMinisters().then(res => {
                console.log(res.data);
                setMinistersLoading(false);
                setMinistersLoaded(true);

                setMinisters(res.data   );
            }).catch(err => {
                console.log(err);
                setMinistersLoading(false);
            })
        }

    }, [])

    
    useEffect(() => {

        if (!devotionalsLoaded || currentDevotional) return

        const dailyDevotionalIndex = devotionals?.findIndex(
            devotional => 
                devotional.dateForDevotional && 
                !(new Date(devotional.dateForDevotional) == 'Invalid Date') &&
                new Date(devotional.dateForDevotional).toDateString() === today.toDateString()
        );
        if (dailyDevotionalIndex === -1) return
        
        setCurrentDevotional(dailyDevotionalIndex);

    }, [devotionalsLoaded, devotionals, currentDevotional])

    if (devotionalsLoading) return <>
        <main className={styles.admin__Content}>
            <p>Please wait...</p>        
        </main>
    </>
    
    return <>
        <main className={styles.admin__Content}>
            <h1 className={styles.welcome__Text}>{translateComponent('Welcome')} {user?.name},</h1>
            <section className={styles.top__Admin__Content}>
                <div className={styles.card__Item}>
                    <select className={styles.select__Date}>
                        {
                            React.Children.toArray(selections.map(selection => {
                                return <option value={selection}>{translateComponent(selection)}</option>
                            }))
                        }
                    </select>
                    <h4>{translateComponent('Ministers')}</h4>
                    <div className={styles.stat__Item}>
                        <h1>{ministers.length}</h1>
                        <button onClick={() => navigate('/admin-ministers/all')}>
                            <span>{translateComponent('View')} {translateComponent('all')}</span>
                            <span><AiOutlineRight /></span>
                        </button>
                    </div>
                </div>
                <div className={styles.card__Item}>
                    <select className={styles.select__Date}>
                        {
                            React.Children.toArray(selections.map(selection => {
                                return <option value={selection}>{translateComponent(selection)}</option>
                            }))
                        }
                    </select>
                    <h4>{translateComponent('Souls')} {translateComponent('Won')}</h4>
                    <div className={styles.stat__Item}>
                        <h1>{souls.length}</h1>
                        <button onClick={() => navigate('/admin-souls/all')}>
                            <span>{translateComponent('View')} {translateComponent('all')}</span>
                            <span><AiOutlineRight /></span>
                        </button>
                    </div>
                </div>
                <div className={styles.card__Item}>
                    <select className={styles.select__Date}>
                        {
                            React.Children.toArray(selections.map(selection => {
                                return <option value={selection}>{translateComponent(selection)}</option>
                            }))
                        }
                    </select>
                    <h4>{translateComponent('Outreaches')}</h4>
                    <div className={styles.stat__Item}>
                        <h1>{outreaches.length}</h1>
                        <button onClick={() => {}}>
                            <span>{translateComponent('View')} {translateComponent('all')}</span>
                            <span><AiOutlineRight /></span>
                        </button>
                    </div>
                </div>
            </section>
            <DevotionalCard 
                devotional={devotionals[currentDevotional]}
                isAdminUser={true}
                devotionalLoading={devotionalsLoading}
                translateComponent={translateComponent}
            />
        </main>
    </>
}

const selections = [
    'This Month',
    'This Year'
]

export default AdminDashboard;
