import { createContext, useContext, useState } from "react";

const MinistersContext = createContext({});

export const useMinistersContext = () => useContext(MinistersContext);

export const MinistersContextProvider = ({ children }) => {
    const [ ministers, setMinisters ] = useState([]);
    const [ ministersLoading, setMinistersLoading ] = useState(true);
    const [ ministersLoaded, setMinistersLoaded ] = useState(false);

    return <>
        <MinistersContext.Provider value={{
            ministers,
            setMinisters,
            ministersLoading,
            setMinistersLoading,
            ministersLoaded,
            setMinistersLoaded,
        }}>
            { children }
        </MinistersContext.Provider>
    </>
}