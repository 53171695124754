import axios from "./axiosService";

export const createNewDevotional = async (data) => {
    return await axios.post('devotionals/new-devotional', data)
}

export const createNewDevotionalComment = async (data) => {
    return await axios.post('devotionals/new-comment', data)
}

export const getDevotionalsForAdmin = async () => {
    return await axios.get('devotionals/admin-devotionals')
}

export const getDevotionalsForMinister = async () => {
    return await axios.get('devotionals/minister-devotionals')
}

export const getSingleDevotionalContent = async (id) => {
    return await axios.get(`devotionals/devotional/${id}`)
}

export const getSingleDevotionalContentForPublicUser = async (id) => {
    return await axios.get(`devotionals/devotional/${id}?view=public`)
}