import { AiOutlineShareAlt } from 'react-icons/ai';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { toast } from '../../utils/helper';
import { useDisclosure, useClipboard } from "@mantine/hooks";
import { Button, Modal, Text, Flex, Tooltip, ActionIcon } from "@mantine/core";
import { useState } from 'react';
import { FaRegCopy } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { formatDate } from "date-fns";


const DevotionalCard = ({ className, devotional, isAdminUser, devotionalLoading, translateComponent }) => {
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const clipboard = useClipboard({ timeout: 500 });
    const [downloadingFile, setDownloadingFile] = useState(false);

    const handleShareDevotional = async (devotional) => {
        const link = `${window.location.origin}/public/devotional/${devotional._id}`;

        try {
            await navigator.clipboard.writeText(link);
            toast('Successfully copied share link to clipboard!').success();
        } catch (error) {
            console.log(error);
        }
    }

    const handleDownload = async () => {
        try {
          setDownloadingFile(true);
          const response = await fetch(devotional?.file); // Replace with your Cloudinary URL
          const blob = await response.blob();
    
          // Create a temporary URL for the Blob
          const url = URL.createObjectURL(blob);
    
          // Create a link element and trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = `Devotion_${formatDate(
            devotional?.dateForDevotional,
            "MMMM_yyyy"
          )}.pdf`;
          document.body.appendChild(link);
          link.click();
    
          // Clean up and revoke the object URL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          setDownloadingFile(false);
        } catch (error) {
          setDownloadingFile(false);
          toast("Counldn't fetch full devotion").error();
        }
      };

    if (!devotional) return <>
        <section className={`${styles.devotional__Wrapper} ${className}`}>
            {
                devotionalLoading ?
                <h3 className={styles.devotional__Header}>{translateComponent('Loading')}...</h3>
                :
                <h3 className={styles.devotional__Header}>{translateComponent('No')} {translateComponent('devotional')} {translateComponent('set')} {translateComponent('for')} {translateComponent('today')} {translateComponent('yet')}</h3>
            }
        </section>
    </>

    return <>    
        <section className={`${styles.devotional__Wrapper} ${className}`}>
            
            <div className={styles.devotional__Img}>
                <img src={'https://img.freepik.com/free-vector/party-silhouettes-light-background_1048-5144.jpg?size=626&ext=jpg&ga=GA1.1.225976907.1673277028&semt=country_rows_v1'} alt="devotional" />
            </div>
            <h3 className={styles.devotional__Header}>{translateComponent("Today's")} {translateComponent('Rhapsody')} {translateComponent('of')} {translateComponent('Realities')}</h3>
            <p className={styles.date}>{new Date(devotional.dateForDevotional).toDateString()}</p>
            <div className={styles.devotional__Content__Wrapper}>
                <h4>{devotional.title}</h4>
                <p className={styles.devotional__Verse}>
                    {
                        devotional.newDevotionalStructure ? 
                        <span>
                            {devotional.bibleVerseContent}
                        </span> 
                        :
                        <span
                            dangerouslySetInnerHTML={{ __html: devotional?.passageContent }}
                        >

                        </span>
                    }
                    {
                        devotional.newDevotionalStructure ? 
                        <span>({devotional.bibleVerse})</span>
                        :
                        <span>({devotional.book} {devotional.chapter}:{devotional.verse})</span>
                    }
                </p>
            {devotional?.devotionalContent && <p className={styles.devotional__Text}>
                    {
                        devotional.devotionalContent.length > 250 ? 
                        devotional.devotionalContent.slice(devotional, 250) + '...' 
                        :
                        devotional.devotionalContent
                    }
                </p>}
                <div className={styles.button__Wrapper}>
                    <button 
                        className={styles.read__Btn} 
                        onClick={
                            () => navigate(
                                isAdminUser ? 
                                `/admin-devotional/${devotional?._id}`
                                :
                                `/devotional/${devotional?._id}`)
                        }
                    >
                        {translateComponent('Read')} {('the')} {translateComponent('full')} {translateComponent('article')}
                    </button>
                    <button className={styles.distribute__Btn} 
                     onClick={() => open()}>
                        <AiOutlineShareAlt />
                        <span>{translateComponent('Distribute')}</span>
                    </button>
                </div>
            </div>
        </section>

        <Modal
        opened={opened}
        onClose={close}
        title={<Text fw={600}>Distribute</Text>}
        centered
      >
        <Flex maw={300} align={"center"}>
          <a
            className="break-all"
            // href={`${window.location.origin}/portal/public/devotional/${currentDevotional?._id}`}
            // target="_blank"
          >
            {`${window.location.origin}/public/devotional/${devotional?._id}`}{" "}
          </a>
          <Tooltip
            label={clipboard.copied ? "Copied!" : "Copy"}
            withArrow
            position="right"
          >
            <ActionIcon
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/public/devotional/${devotional?._id}`
                )
              }
            >
              <FaRegCopy size={20} />
            </ActionIcon>
          </Tooltip>
        </Flex>

        {devotional?.file && (
          <Tooltip label={"Download full devotion"} withArrow>
            <Button
              size="xs"
              onClick={handleDownload}
              loading={downloadingFile}
              leftIcon={<HiDownload />}
              className="bg-color-1 hover:bg-color-1"
              my={"xs"}
            >
              Full Devotion
            </Button>
          </Tooltip>
        )}
      </Modal>
    </>
}

export default DevotionalCard;