import { AiOutlineArrowRight, AiOutlinePlus } from "react-icons/ai";
import styles from "./styles.module.css";
import React, { useState } from "react";
import { useEffect } from "react";
import NewSoulModal from "./NewSoulModal";
import { Avatar, Table } from "@mantine/core";
import { IoPersonOutline } from "react-icons/io5";
import peopleImg from "../../images/raphsody.png";
import { getAdminSouls } from "../../services/soulServices";
import { useSoulsContext } from "../../context/SoulsContext";
import { toast } from "../../utils/helper";
import TrackSoulModal from "../../components/TrackSoulModal/TrackSoulModal";

const AdminSoulManager = ({ translateComponent }) => {
  const [showmodal, setShowModal] = useState(false);
  const [showTrackModal, setShowTrackModal] = useState(false);
  const [soulToTrack, setSoulToTrack] = useState(null);

  const {
    souls,
    setSouls,
    soulsLoading,
    setSoulsLoading,
    soulsLoaded,
    setSoulsLoaded,
  } = useSoulsContext();

  useEffect(() => {
    if (!soulsLoaded) {
      setSoulsLoading(true);

      getAdminSouls()
        .then((res) => {
          setSoulsLoading(false);
          setSoulsLoaded(true);

          setSouls(res.data);
        })
        .catch((err) => {
          console.log(err);
          setSoulsLoading(false);
        });
    }
  }, []);

  const handleTrackSoul = (soul) => {
    console.log(soul);

    setShowTrackModal(true);
    setSoulToTrack(soul);
  };

  return (
    <>
      <main className={styles.main__Content}>
        <section>
          <div className={styles.top__Content}>
            <h1>
              {translateComponent("Souls")} {translateComponent("Dashboard")}
            </h1>
            <button
              className={styles.add__New__Btn}
              onClick={() => setShowModal(true)}
            >
              <AiOutlinePlus />
              <span>
                {translateComponent("Add")} {translateComponent("New")}{" "}
                {translateComponent("Soul")}
              </span>
            </button>
          </div>
          {soulsLoading ? (
            <p style={{ fontSize: "0.8rem" }}>
              {translateComponent("Loading")} {translateComponent("souls")}...
            </p>
          ) : !soulsLoaded ? (
            <p style={{ fontSize: "0.8rem" }}>
              {translateComponent("Souls")} {translateComponent("failed")}{" "}
              {translateComponent("to")} {translateComponent("load")}.{" "}
              {translateComponent("Please")} {translateComponent("refresh")}{" "}
              {translateComponent("this")} {translateComponent("page")}
            </p>
          ) : souls.length < 1 ? (
            <>
              <p style={{ fontSize: "0.8rem" }}>
                {translateComponent("You")} {translateComponent("have")}{" "}
                {translateComponent("not")} {translateComponent("added")}{" "}
                {translateComponent("any")} {translateComponent("souls")}{" "}
                {translateComponent("yet")}
              </p>
            </>
          ) : (
            <>
              <div className={styles.stat__Wrapper}>
                <div className={styles.stat__Card}>
                  <div className={styles.person}>
                    <IoPersonOutline />
                  </div>
                  <div className={styles.stats}>
                    <div>
                      <p>All souls</p>
                      <h1>{souls.length}</h1>
                    </div>
                    <div>
                      <p>Saved</p>
                      <h1>
                        {
                          souls.filter((soul) => soul.status === "Delivered")
                            .length
                        }
                      </h1>
                    </div>
                    <div>
                      <p>Won</p>
                      <h1>
                        {souls.filter((soul) => soul.status === "Won").length}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className={styles.stat__Img}>
                  <img src={peopleImg} alt="" />
                </div>
              </div>

              <Table
                verticalSpacing="sm"
                highlightOnHover
                className={styles.soul__Table}
              >
                <thead>
                  <tr>
                    <td>Full Name</td>
                    <td>Email</td>
                    <td>Phone</td>
                    <td>Date Saved</td>
                    <td>Status</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {React.Children.toArray(
                    souls.map((soul) => {
                      return (
                        <tr>
                          <td>
                            <div className={styles.name__Item}>
                              {soul.photo ? (
                                <Avatar
                                  src={soul.photo}
                                  alt="soul"
                                  radius={"xl"}
                                />
                              ) : (
                                <Avatar color="cyan" radius={"xl"}>
                                  {soul?.fullName?.slice(0, 1) +
                                    soul?.fullName?.split(" ")[1]?.slice(0, 1)}
                                </Avatar>
                              )}
                              <span>{soul.fullName}</span>
                            </div>
                          </td>
                          <td>{soul.email}</td>
                          <td>{soul.phoneNumber}</td>
                          <td>{new Date(soul.dateSaved).toDateString()}</td>
                          <td>
                            {/* <span className={soul.status === 'Won' ? styles.orange__Highlight :  styles.green__Highlight}>{soul.status}</span> */}
                            <span
                              className={
                                soul.status === "Won"
                                  ? styles.orange__Highlight
                                  : styles.green__Highlight
                              }
                            >
                              {soul.status !== "Won" ? "Saved" : soul?.status}
                            </span>
                          </td>
                          <td>
                            <button
                              className={styles.track__Btn}
                              onClick={() => handleTrackSoul(soul)}
                            >
                              <span>Track Soul</span>
                              <AiOutlineArrowRight />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </>
          )}

          <NewSoulModal
            opened={showmodal}
            closeModal={() => setShowModal(false)}
          />

          <TrackSoulModal
            opened={showTrackModal}
            closeModal={() => setShowTrackModal(false)}
            soul={soulToTrack}
            isAdminUser={true}
          />
        </section>
      </main>
    </>
  );
};

export default AdminSoulManager;
