/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyAccount } from '../services/userService';
import AppLoader from "../components/Loader"
import { toast } from "../utils/helper"
import { useUserContext } from '../context/UserContext';
import { USER_PROFILE, USER_TOKEN } from '../context/UserContext';

const VerifyAccount = (props) => {
    const { verificationCode } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)

    const { userTokenDetailsDispatch, userDispatch } = useUserContext()

    useEffect(() => {
        if (!verificationCode) return navigate("/login")
        verifyAccount(verificationCode).then(res => {
            console.log(res.data)
            userTokenDetailsDispatch({
                type: USER_TOKEN,
                payload: res.headers["auth-token"]
            })
            userDispatch({
                type: USER_PROFILE,
                payload: res.data.data
            })
            toast(res.data?.message).success()
            setLoading(false)
            navigate("/dashboard")
            // navigate("*")
        }).catch(err => {
            console.log(err)
            toast(
                err?.response?.data?.message ?
                    err?.response?.data?.message
                :
                'Account verification failed'
            ).error()
            setLoading(false)
            navigate("/login")
        })
    }, [verificationCode])
    // }, [verificationCode, userTokenDetailsDispatch, userDispatch, navigate]);

    return (
        <>
            {loading &&
                <AppLoader />
            }
        </>
    );
}

export default VerifyAccount;