import React, { useState } from 'react';
import { Flex, PasswordInput, Button, Text } from "@mantine/core"
import { inputStyles, toast } from '../../utils/helper';
import { BiLockAlt } from 'react-icons/bi';
import { updatePassword } from '../../services/userService';

function SecuritySettings({ loading, setLoading }) {
    const [password, setPassword] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    })

    const handleInputchange = ({ target: { name, value } }) => setPassword({ ...password, [name]: value })

    const handleSubmit = (e) => {
        e.preventDefault()
        if (password.confirmPassword !== password.newPassword) {
            toast("Passwords don't match").error()
        }
        setLoading(true)
        updatePassword({ currentPassword: password.currentPassword, newPassword: password.newPassword })
            .then(res => {
                console.log(res.data)
                setLoading(false)
                toast(res.data?.message).success()
            }).catch(err => {
                console.log(err)
                setLoading(false)
                toast(err?.response?.data?.message).error()
            })
    }
    return (
        <Flex direction={'column'} justify={'space-between'} mt={20} className="px-5 w-[400px]">
            <Text className='fon-medium text-xl font-2 mb-3'>Password Settings</Text>
            <form onSubmit={handleSubmit}>


                <Text className='font-normal text-xs text-color-2 mb-2'>Current Password</Text>
                <PasswordInput
                    name='currentPassword'
                    value={password.currentPassword}
                    onChange={handleInputchange}
                    // mt={20}
                    size='md'
                    styles={{
                        input: inputStyles
                    }}
                    placeholder='Password'
                    icon={<BiLockAlt />} />

                <Text className='font-normal text-xs text-color-2 my-2'>New Password</Text>
                <PasswordInput
                    name='newPassword'
                    value={password.newPassword}
                    onChange={handleInputchange}
                    size='md'
                    styles={{
                        input: inputStyles
                    }}
                    placeholder='Password'
                    icon={<BiLockAlt />} />

                <Text className='font-normal text-xs text-color-2 my-2'>Confirm Password</Text>
                <PasswordInput
                    name='confirmPassword'
                    value={password.confirmPassword}
                    onChange={handleInputchange}
                    size='md'
                    styles={{
                        input: inputStyles
                    }}
                    placeholder='Password'
                    icon={<BiLockAlt />} />

                <Button
                    loading={loading}
                    loaderPosition='center'
                    // fullWidth
                    mt="xl"
                    size="md"
                    type="submit"
                    radius={"md"}
                    className="
                w-[150px]
                bg-color-1 
                font-normal
                 text-lg
                  text-white 
                  transition 
                  duration-300 
                  ease-in-out 
                  hover:bg-opacity-80
                   hover:bg-color-1"
                >
                    Update
                </Button>
            </form>
        </Flex>
    );
}

export default SecuritySettings;