import React, { useState } from "react"
import { Modal, Text, ScrollArea, Button, Avatar, Select } from '@mantine/core';
import styles from './styles.module.css';
import { AiOutlineClose } from "react-icons/ai";
import { useEffect } from "react";
import { updateSoulForAdmin, updateSoulForMinister } from "../../services/soulServices";
import { useSoulsContext } from "../../context/SoulsContext";
import { toast } from "../../utils/helper";

const TrackSoulModal = ({ opened, closeModal, soul, isAdminUser }) => {
    const [ currentSoulStatus, setCurrentSoulStatus ] = useState(null);
    const {
        souls,
        setSouls,
    } = useSoulsContext();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        setCurrentSoulStatus(soul?.status);
    }, [soul])

    const handleClick = async () => {
        if (!currentSoulStatus || currentSoulStatus?.length < 1) return

        const dataToPost = { 
            status: currentSoulStatus 
        }
        const copyOfSouls = souls.slice();
        const foundIndexOfSoul = copyOfSouls.findIndex(s => s._id === soul._id)
        
        setLoading(true);

        try {
            const updatedSoul = isAdminUser ? 
                (await updateSoulForAdmin(soul._id, dataToPost)).data
            : 
            (await updateSoulForMinister(soul._id, dataToPost)).data;

            if (foundIndexOfSoul !== -1) {
                copyOfSouls[foundIndexOfSoul] = updatedSoul;
                setSouls(copyOfSouls);
            }
            
            toast('Successfully updated soul status').success();
            
            setLoading(false);
            closeModal();

        } catch (error) {
            setLoading(false);   
            toast('An error occurred while trying to update soul status').error();
        }
    }

    if (!soul) return <></>

    return (
        <>
            <Modal
                // className="!h-[500px]"
                mt={20}
                opened={opened}
                withCloseButton={false}
                closeOnEscape={false}
                closeOnClickOutside={false}
                onClose={closeModal}
                title=""
                centered
                size={{ md: '70vw', sm: "100vw" }}
            >
                {/* Modal content */}
                <ScrollArea
                    className={`flex w-full flex-col min-h-[250px] overflow-x-hidden ${styles.modal__Wrapper}`}
                >
                    <div className={styles.modal__Close__Container} onClick={() => closeModal()}>
                        <AiOutlineClose className={styles.modal__Close__Icon} />
                    </div>
                    <Text className='md:text-center text-start font-2 font-medium text-xl md:text-xl my-5'>Track Soul</Text>

                    <div className={styles.soul__Img__Wrapper}>
                        {
                            soul.photo ?
                                <Avatar
                                    src={soul.photo}
                                    alt='soul'
                                    radius={'xl'}
                                    className={styles.avatar__Img}
                                />
                            :
                            <Avatar 
                                color='cyan'
                                radius={'xl'}
                                className={styles.avatar__Img}
                            >
                                {soul?.fullName?.slice(0, 1) + soul?.fullName?.split(' ')[1]?.slice(0, 1)}
                            </Avatar>
                        }
                    </div>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Name: {soul?.fullName}</Text>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Email: {soul?.email}</Text>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Gender: {soul?.gender}</Text>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Phone Number: {soul?.phoneNumber}</Text>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Date of Birth: {new Date(soul?.dateOfBirth).toDateString()}</Text>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Country: {soul?.country}</Text>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Salvation occasion: {soul?.occasion}</Text>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Salvation description: {soul?.salvationDescription}</Text>
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Date saved: {new Date(soul?.dateSaved).toDateString()}</Text>

                    <br />
                    <Text className='md:text-start text-start font-2 font-medium text-sm md:text-md my-5'>Update soul status</Text>
                    <Select 
                        label={''}
                        data={[
                            { value: 'Won', label: 'Won' },
                            { value: 'Saved', label: 'Saved' }
                        ]}
                        value={currentSoulStatus}
                        onChange={(val) => setCurrentSoulStatus(val)}
                    />
                    
                    <Button
                        type="button"
                        className="w-[80px] mx-auto bg-color-1 font-normal text-sm md:text-md mt-7 text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:bg-color-1"
                        onClick={handleClick}
                        style={{ display: 'block' }}
                        disabled={loading ? true : false}
                    >
                        {
                            loading ? 
                                'Updating...'
                            :
                            'Update Status'
                        }
                    </Button>
                </ScrollArea>
            </Modal>
        </>
    );
}

export default TrackSoulModal;