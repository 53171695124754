import { Card } from '@mantine/core';
import React from 'react';

function CardWrapper({ children, bg }) {
    console.log("CardWrapper", bg);
    return (
        <Card
            // h={{ sm: 150 }}
            className={`rounded-xl ${bg ? bg : 'bg-white'} py-6 px-7 sm:h-[150px] h-[170px]`}>
            {children}
        </Card>
    );
}

export default CardWrapper;