/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState, useReducer, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { getUserProfile } from '../services/userService';
// import { useNavigate } from "react-router-dom";
import AppLoader from "../components/Loader"
import { toast } from "../utils/helper"

const UserContext = createContext();
export const REON_USER_TOKEN = 'REON_USER_TOKEN'
export const USER_TOKEN = "USER_TOKEN";
export const USER_PROFILE = "USER_PROFILE"
export const LOGOUT_USER = "LOGOUT_USER";


export const userToken = () => localStorage.getItem(REON_USER_TOKEN)
export const removeUserToken = () => localStorage.removeItem(REON_USER_TOKEN)

export function decodeUserToken(token) {
    try {
        if (token) return { ...jwtDecode(token), token }
        else return null;
    }
    catch (ex) {
        console.log(ex)
    }
}

const userReducer = (state, action) => {
    switch (action.type) {
        case USER_TOKEN:
            localStorage.setItem(REON_USER_TOKEN, action.payload)
            return decodeUserToken(action.payload);
        case USER_PROFILE:
            return action.payload;
        case LOGOUT_USER:
            removeUserToken()
            return {};
        default:
            return null
    }
}


const UserContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    //local user info
    const [userTokenDetails, userTokenDetailsDispatch] = useReducer(userReducer, {}, function () {
        const token = userToken()
        if (token) return decodeUserToken(token);
        else return null;
    })

    //live user info
    const [user, userDispatch] = useReducer(userReducer, {})

    //minsters enlisted by user
    const [ministersEnlisted, setMinistersEnlisted] = useState([])

    const token = userToken()
    useEffect(() => {
        // if (token && user?.name) {
        //     setLoading(false)
        //     return;
        // }
        if (!token || user?.name) {
            setLoading(false)
            return;
        };

        if (token && !user?.name) {
            console.log("getUserProfile");
            setLoading(true)
            getUserProfile().then(res => {
                setLoading(false)
                userDispatch({
                    type: USER_PROFILE,
                    payload: res.data.data
                })
            }).catch(err => {
                setLoading(false)
                userDispatch({
                    type: USER_PROFILE,
                    payload: {}
                })

                userTokenDetailsDispatch({
                    type: LOGOUT_USER,
                })
                toast(err?.response?.data?.message).error()
            })
        }
    }, [token, user?.name])

    return (
        <UserContext.Provider value={{
            user,
            userDispatch,
            userTokenDetails,
            userTokenDetailsDispatch,
            ministersEnlisted,
            setMinistersEnlisted
        }}>
            {loading && <AppLoader />}
            {!loading && children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;


export const useUserContext = () => useContext(UserContext);