import React, { useState } from 'react';
import { Text, Flex, TextInput, PasswordInput, Button, Select } from "@mantine/core";
import { IoMailOutline } from "react-icons/io5";
import { BiLockAlt } from "react-icons/bi"
import { BsFillPersonFill } from "react-icons/bs"
import FormLayer from "../components/FormLayer";
import { inputStyles, toast, userTitles } from '../utils/helper';
import { register } from '../services/userService';
import { useLocation, useNavigate } from 'react-router-dom';

function Register(props) {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        title: ""
    })

    const handleChange = ({ target: { name, value } }) => setUser({ ...user, [name]: value })

    const navigate = useNavigate()
    const { search } = useLocation()

    const referralCode = new URLSearchParams(search).get("referral")

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        register(referralCode ? { ...user, referralCode } : { ...user }).then(res => {
            console.log(res.data)
            setLoading(false)
            setUser({
                name: "",
                email: "",
                password: "",
                title: ""
            })
            toast(`${res.data.message}`,
                "Check your mail to verify your account!",).success();
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }
    return (
        <FormLayer moveUp={true}>
            <Text
                color="dark"
                align="center"
                my={2}
                className='font-bold text-lg font-2'
            >
                Create your REON Minister’s account
            </Text>
            <form onSubmit={handleSubmit}>
                <Flex
                    mt={15}
                    direction={"column"}
                >

                    <Select
                        searchable
                        nothingFound="No options"
                        clearable
                        required
                        value={user.title}
                        onChange={(e) => setUser({ ...user, title: e })}
                        size='md'
                        styles={{
                            input: inputStyles
                        }}
                        placeholder="Title"
                        data={userTitles}
                    />

                    <TextInput
                        required
                        name='name'
                        value={user.name}
                        onChange={handleChange}
                        mt={20}
                        size='md'
                        // className='!bg-color-4 focus:!border-color-1 !border-none'
                        styles={{
                            input: inputStyles
                        }}
                        placeholder='Your Full Name '
                        icon={<BsFillPersonFill className='text-lg' />} />

                    <TextInput
                        required
                        name='email'
                        value={user.email}
                        onChange={handleChange}
                        mt={20}
                        size='md'
                        // className='!bg-color-4 focus:!border-color-1 !border-none'
                        styles={{
                            input: inputStyles
                        }}
                        placeholder='Your Email Address'
                        icon={<IoMailOutline className='text-lg' />} />


                    <PasswordInput
                        required
                        name='password'
                        onChange={handleChange}
                        mt={20}
                        size='md'
                        styles={{
                            input: inputStyles
                        }}
                        placeholder='Create a Strong Password'
                        icon={<BiLockAlt />} />

                    <Text className='text-center mt-7 text-color-2'>Already have an account? <span className='text-color-1 cursor-pointer' onClick={() => navigate("/login")}>Login</span></Text>

                    <Button
                        loading={loading}
                        loaderPosition='center'
                        fullWidth
                        mt="xl"
                        size="md"
                        type="submit"
                        radius={"md"}
                        className="bg-color-1 font-normal text-lg text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:bg-color-1"
                    >
                        Create Account
                    </Button>
                </Flex>

            </form>
        </FormLayer>
    );
}

export default Register;