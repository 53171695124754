import React from 'react';
import { Card, Text, Flex, Select, Button, Table, Image, ScrollArea, TextInput } from '@mantine/core';
import { AiOutlinePlus } from "react-icons/ai"
import { BsSearch, BsFilter } from "react-icons/bs"
import { useDisclosure } from '@mantine/hooks';
import EnlistModal from './EnlistModal';
import { useUserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

function NetworkManager(props) {
    const { ministersEnlisted } = useUserContext()
    const { t } = useTranslation();

    const formateDate = (createdAt) => {
        const date = new Date(createdAt);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const rows = ministersEnlisted?.map((minister) => (
        <tr key={minister?.id}>
            <td>
                <Flex className='w-full'>
                    <Image
                        sx={{
                            borderTopLeftRadius: "10px",
                            borderBottomRightRadius: "10px"
                        }}
                        className='cursor-pointer rounded-t-xl rounded-b-xl'
                        // radius={'50%'}
                        width={50}
                        height={50}
                        src={
                            minister?.image ? minister?.image :
                                'https://res.cloudinary.com/dyxk7wdj7/image/upload/v1680467188/christian-buehner-DItYlc26zVI-unsplash_btgj0p.jpg'} />
                    <Flex direction={'column'} justify={'space-between'} className='ml-5 w-full'>
                        <Text className='font-medium text-black text-base'>
                            {minister?.name}
                        </Text>

                        <Text className='font-medium text-color-2 text-sm'>{minister?.ministry?.name}</Text>
                    </Flex>

                </Flex>
            </td>
            <td className='text-color-2 text-sm'>{minister?.email}</td>
            <td className=' text-color-2 text-sm'>{formateDate(minister?.createdAt)}</td>
        </tr>
    ));

    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Card
                className="col-span-12 rounded-xl xl:col-span-8">
                <Flex className='w-full p-0 sm:p-5' direction={'column'}>
                    <Card className='shadow-md rounded border-0 h-[180px] sm:h-[150px] w-full py-6 px-7'>
                        <Flex direction={'row'} justify={'space-between'}>
                            <Flex direction={'column'} justify={'space-between'}>
                                <Text className='font-2 text-[#45464E] mt-0 sm:mt-3'>{t('Ministers')} {t('enlisted')}</Text>

                                <Text className='absolute bottom-12 sm:bottom-5 font-2 text-5xl text-[#45464E]'>{ministersEnlisted?.length}</Text>
                            </Flex>


                            <Flex direction={'column'} justify={'space-between'}>
                                <Select
                                    className='w-[100px] border-none'
                                    placeholder={t('This year')}
                                    data={[
                                        { value: "This year", label: t("This year") },
                                        { value: "This year", label: t("This year") },
                                        { value: "This year", label: t("This year") },
                                    ]} />
                                <br />
                                <Button
                                    onClick={open}
                                    leftIcon={<AiOutlinePlus className='text-white' size={20} />}
                                    className='bg-[#219653] w-[180px]
                                absolute
                                bottom-2
                                sm:bottom-5
                                 right-6 
                            mt-5
                            sm:mt-0
                            font-semibold
                            h-8
                            sm:h-10
                            font-3
                            text-sm
                            rounded
                             sm:rounded-2xl
                             hover:opacity-80
                             transition duration-300 ease-in-out
                             hover:bg-[#219653]
                             '
                                >{t('Enlist')} {t('a')} {t('minister')}</Button>
                            </Flex>
                        </Flex>

                    </Card>


                    <Text className='font-2 font-medium text-2xl py-5 text-center'>{t('My ministers network')}</Text>
                    <Flex
                        bg={'#FAFAFA'}
                        className='px-0 md:px-5 py-5 h-[500px]'
                        direction={"column"}
                    // justify={'center'}
                    >
                        <form className='w-full mb-5'>
                            <TextInput icon={<BsSearch />} placeholder="Search" />
                        </form>

                        <ScrollArea >
                            <hr />
                            <Table
                                verticalSpacing="sm"
                                // horizontalSpacing='lg'
                                highlightOnHover>
                                <thead>
                                    <tr>
                                        <th>

                                            <Flex direction={'row'} className='items-center'>
                                                <Text>{t('Name')}</Text> &nbsp; &nbsp;
                                                <BsFilter />
                                            </Flex>

                                        </th>
                                        <th> <Flex direction={'row'} className='items-center'>
                                            <Text>{t('Email')}</Text> &nbsp; &nbsp;
                                            <BsFilter />
                                        </Flex></th>
                                        <th> <Flex direction={'row'} className='items-center'>
                                            <Text>{t('Date')} {t('joined')}</Text> &nbsp; &nbsp;
                                            <BsFilter />
                                        </Flex></th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>

                            </Table>
                        </ScrollArea>
                    </Flex>
                </Flex>
            </Card>
            <EnlistModal close={close} opened={opened} />
        </>
    );
}

export default NetworkManager;