import {
  IoChevronBackOutline,
  IoChevronForward,
  IoPersonOutline,
} from "react-icons/io5";
import { useMinistersContext } from "../../context/MinisterContext";
import styles from "../AdminSoulManager/styles.module.css";
import peopleImg from "../../images/raphsody.png";
import {
  Table,
  Flex,
  Avatar,
  Space,
  ActionIcon,
  ScrollArea,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getAllMinisters } from "../../services/userService";
import { getDaysDifferenceFromPresentDate } from "./utils/util";
import { AiOutlineSearch } from "react-icons/ai";
import {
  downloadCSV,
  getInitials,
  isJSONStringRegex,
  truncateStringAtMiddle,
} from "../../utils/helper";
import { LuDownload } from "react-icons/lu";
import { Modal, Tabs } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { formatDate } from "date-fns";

const AdminMinisterManager = ({ translateComponent }) => {
  const {
    ministers,
    setMinisters,
    ministersLoading,
    setMinistersLoading,
    ministersLoaded,
    setMinistersLoaded,
  } = useMinistersContext();
  const [searchVal, setSearchVal] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(5);
  const [endIndex, setEndIndex] = useState(numberOfItemsPerPage);
  const [buttonOptionsToShow, setButtonOptionsToShow] = useState(1);
  const [paginationBtnLowerIndex, setPaginationBtnLowerIndex] = useState(0);
  const [paginationBtnUpperIndex, setPaginationBtnUpperIndex] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    if (!ministersLoaded) {
      setMinistersLoading(true);

      getAllMinisters()
        .then((res) => {
          console.log(res.data);
          setMinistersLoading(false);
          setMinistersLoaded(true);

          setMinisters(res.data);
        })
        .catch((err) => {
          console.log(err);
          setMinistersLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    setEndIndex(numberOfItemsPerPage);

    setPaginationBtnLowerIndex(0);
    setPaginationBtnUpperIndex(5);

    handlePageBtnClick(1);
  }, [numberOfItemsPerPage]);

  useEffect(() => {
    const updatedButtonCount =
      Math.round(ministers.length / numberOfItemsPerPage) +
      (ministers.length % numberOfItemsPerPage);

    if (isNaN(updatedButtonCount)) return setButtonOptionsToShow(1);

    setButtonOptionsToShow(updatedButtonCount);
  }, [ministers, numberOfItemsPerPage]);

  const handleAddPage = () => {
    setPaginationBtnLowerIndex(paginationBtnLowerIndex + 1);
    setPaginationBtnUpperIndex(paginationBtnUpperIndex + 1);
  };

  const handleRemovePage = () => {
    setPaginationBtnLowerIndex(paginationBtnLowerIndex - 1);
    setPaginationBtnUpperIndex(paginationBtnUpperIndex - 1);
  };

  const handlePageBtnClick = (pageToGet) => {
    setCurrentPage(pageToGet);

    const newEndIndex = numberOfItemsPerPage * pageToGet;
    const newStartIndex = newEndIndex - numberOfItemsPerPage;

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  };

  return (
    <>
      <main className={styles.main__Content}>
        <section>
          <div className={styles.top__Content}>
            <h1>
              {translateComponent("Ministers")}{" "}
              {translateComponent("Dashboard")}
            </h1>
            {/* <button className={styles.add__New__Btn} onClick={() => setShowModal(true)}>
                        <AiOutlinePlus />
                        <span>{translateComponent('Add')} {translateComponent('New')} {translateComponent('Soul')}</span>
                    </button> */}
          </div>
          {ministersLoading ? (
            <p style={{ fontSize: "0.8rem" }}>
              {translateComponent("Loading")} {translateComponent("ministers")}
              ...
            </p>
          ) : !ministersLoaded ? (
            <p style={{ fontSize: "0.8rem" }}>
              {translateComponent("Ministers")} {translateComponent("failed")}{" "}
              {translateComponent("to")} {translateComponent("load")}.{" "}
              {translateComponent("Please")} {translateComponent("refresh")}{" "}
              {translateComponent("this")} {translateComponent("page")}
            </p>
          ) : ministers.length < 1 ? (
            <>
              <p style={{ fontSize: "0.8rem" }}>
                {translateComponent("There")} {translateComponent("are")}{" "}
                {translateComponent("no")} {translateComponent("ministers")}{" "}
                {translateComponent("yet")}
              </p>
            </>
          ) : (
            <>
              <div className={styles.stat__Wrapper}>
                <div className={styles.stat__Card}>
                  <div className={styles.person}>
                    <IoPersonOutline />
                  </div>
                  <div className={styles.stats}>
                    <div>
                      <p>All ministers</p>
                      <h1>{ministers.length}</h1>
                    </div>
                    <div>
                      <p>Joined this week</p>
                      <h1>
                        {
                          ministers.filter(
                            (minister) =>
                              getDaysDifferenceFromPresentDate(
                                minister.createdAt
                              ) <= 7
                          ).length
                        }
                      </h1>
                    </div>
                    <div>
                      <p>Joined this month</p>
                      <h1>
                        {
                          ministers.filter(
                            (minister) =>
                              getDaysDifferenceFromPresentDate(
                                minister.createdAt
                              ) <= 30
                          ).length
                        }
                      </h1>
                    </div>
                  </div>
                </div>
                <div className={styles.stat__Img}>
                  <img src={peopleImg} alt="" />
                </div>
              </div>

              <div className={styles.search__Wrap}>
                <AiOutlineSearch />
                <input
                  type="text"
                  value={searchVal}
                  onChange={({ target }) => setSearchVal(target.value)}
                  placeholder="Search Minister"
                />
              </div>

              <div className={styles.pre__Table__Wrap}>
                <div className={styles.count__Wrap}>
                  <p>Showing </p>
                  <select
                    value={numberOfItemsPerPage}
                    onChange={({ target }) =>
                      setNumberOfItemsPerPage(target.value)
                    }
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={70}>70</option>
                    <option value={100}>100</option>
                  </select>
                  <p> results per page</p>
                </div>
                <Flex align={"center"}>
                  <ActionIcon
                    color="dark"
                    size={"md"}
                    onClick={() =>
                      downloadCSV(ministers, "minsters", ["image"])
                    }
                  >
                    <LuDownload size={20} />
                  </ActionIcon>
                  <Space mx={"xs"} />
                  <div className={styles.pagination__Wrap}>
                    {paginationBtnLowerIndex > 0 && (
                      <button
                        onClick={handleRemovePage}
                        className={styles.pagin__nav__btn}
                      >
                        <IoChevronBackOutline />
                      </button>
                    )}

                    {React.Children.toArray(
                      new Array(buttonOptionsToShow)
                        .fill("")
                        .map((item, index) => {
                          if (
                            index >= paginationBtnLowerIndex &&
                            index < paginationBtnUpperIndex
                          )
                            return (
                              <button
                                className={`${styles.btn__page} ${
                                  currentPage === index + 1 ? styles.active : ""
                                }`}
                                onClick={() => handlePageBtnClick(index + 1)}
                              >
                                {index + 1}
                              </button>
                            );

                          return <></>;
                        })
                    )}
                    {buttonOptionsToShow > paginationBtnUpperIndex && (
                      <button
                        onClick={handleAddPage}
                        className={styles.pagin__nav__btn}
                      >
                        <IoChevronForward />
                      </button>
                    )}
                  </div>
                </Flex>
              </div>

              <ScrollArea>
                <Table
                  verticalSpacing="sm"
                  highlightOnHover
                  striped
                  className={styles.soul__Table}
                >
                  <thead>
                    <tr>
                      <td>Title</td>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Gender</td>
                      <td>Phone</td>
                      <td>Country</td>
                    </tr>
                  </thead>
                  <tbody>
                    {React.Children.toArray(
                      ministers
                        .slice(startIndex, endIndex)
                        .filter((minister) => {
                          if (searchVal.length < 1) return true;
                          return minister.name
                            .toLocaleLowerCase()
                            .includes(searchVal.toLocaleLowerCase());
                        })
                        .map((minister) => {
                          return (
                            <tr
                              className="cursor-pointer"
                              onClick={() => {
                                setSelectedItem(minister);
                                open();
                              }}
                            >
                              <td>{minister.title || "--"}</td>
                              <td>
                                <Flex align={"center"}>
                                  <Avatar
                                    src={minister?.image}
                                    size={"md"}
                                    radius={"50%"}
                                  >
                                    {getInitials(minister?.name)}
                                  </Avatar>
                                  <Space mx={5} />
                                  <span>{minister.name || "--"}</span>
                                </Flex>
                              </td>
                              <td>
                                <span>{minister.email || "--"}</span>
                              </td>
                              <td>{minister.geneder || "--"}</td>
                              <td>{minister.phone || "--"}</td>
                              <td>
                                {(!!minister?.country &&
                                  (isJSONStringRegex(minister.country)
                                    ? JSON.parse(minister?.country)?.name
                                    : minister?.country)) ||
                                  "--"}
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </Table>
              </ScrollArea>
            </>
          )}
        </section>
      </main>

      {opened && selectedItem && (
        <Modal
          centered
          opened={opened}
          onClose={close}
          title={
            <Text fw={600}>
              {truncateStringAtMiddle(selectedItem?.name, 40)}
            </Text>
          }
        >
          <Tabs defaultValue="profile">
            <Tabs.List>
              <Tabs.Tab value="profile">Profile</Tabs.Tab>
              {selectedItem?.ministry && (
                <Tabs.Tab value="ministry">Ministry</Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="profile" pt="xs">
              <Flex direction={"column"} justify={"center"}>
                <Avatar
                  mb={"xs"}
                  src={selectedItem?.image}
                  w={150}
                  h={150}
                  className="rounded-[150px]"
                >
                  {getInitials(selectedItem?.name)}
                </Avatar>
                <Text fw={600} c={"dimmed"} mb={"xs"}>
                  <span className="text-black">Title: </span>
                  <span>{selectedItem?.title}</span>
                </Text>
                <Text fw={600} c={"dimmed"} mb={"xs"}>
                  <span className="text-black">Name: </span>
                  <span>{selectedItem?.name}</span>
                </Text>
                <Text fw={600} c={"dimmed"} mb={"xs"}>
                  <span className="text-black">Email: </span>
                  <span>{selectedItem?.email}</span>
                </Text>
                <Text fw={600} c={"dimmed"} mb={"xs"}>
                  <span className="text-black">Phone: </span>
                  <span>{selectedItem?.phone || "--"}</span>
                </Text>
                <Text fw={600} c={"dimmed"} mb={"xs"}>
                  <span className="text-black">Gender: </span>
                  <span>{selectedItem?.gender || "--"}</span>
                </Text>
                <Text fw={600} c={"dimmed"} mb={"xs"}>
                  <span className="text-black">Country: </span>
                  <span>
                    {" "}
                    {(selectedItem?.country &&
                      (isJSONStringRegex(selectedItem?.country)
                        ? JSON.parse(selectedItem?.country)?.name
                        : selectedItem?.country)) ||
                      "--"}
                  </span>
                </Text>
                <Text fw={600} c={"dimmed"} mb={"xs"}>
                  <span className="text-black">State: </span>
                  <span>
                    {(selectedItem?.state &&
                      (isJSONStringRegex(selectedItem?.state)
                        ? JSON.parse(selectedItem?.state)?.name
                        : selectedItem?.state)) ||
                      "--"}
                  </span>
                </Text>
                <Text fw={600} c={"dimmed"} mb={"xs"}>
                  <span className="text-black">City: </span>
                  <span>{selectedItem?.city || "--"}</span>
                </Text>
              </Flex>
            </Tabs.Panel>

            {selectedItem?.ministry && (
              <Tabs.Panel value="ministry" pt="xs">
                <Flex direction={"column"} justify={"center"}>
                  <Text fw={600} c={"dimmed"} mb={"xs"}>
                    <span className="text-black">Name: </span>
                    <span>{selectedItem?.ministry?.name || "--"}</span>
                  </Text>
                  <Text fw={600} c={"dimmed"} mb={"xs"}>
                    <span className="text-black">Name: </span>
                    <span>{selectedItem?.ministry?.type || "--"}</span>
                  </Text>
                  <Text fw={600} c={"dimmed"} mb={"xs"}>
                    <span className="text-black">Country: </span>
                    <span>
                      {(selectedItem?.ministry?.country &&
                        (isJSONStringRegex(selectedItem?.ministry?.country)
                          ? JSON.parse(selectedItem?.ministry?.country)?.name
                          : selectedItem?.ministry?.country)) ||
                        "--"}
                    </span>
                  </Text>
                  <Text fw={600} c={"dimmed"} mb={"xs"}>
                    <span className="text-black">State: </span>
                    <span>
                      {/* {selectedItem?.ministry?.state || "--"} */}
                      {(selectedItem?.ministry?.state &&
                        (isJSONStringRegex(selectedItem?.ministry?.state)
                          ? JSON.parse(selectedItem?.ministry?.state)?.name
                          : selectedItem?.ministry?.state)) ||
                        "--"}
                    </span>
                  </Text>
                  {/* <Text fw={600} c={"dimmed"} mb={"xs"}>
                    <span className="text-black">City: </span>
                    <span>{selectedItem?.ministry?.city || "--"}</span>
                  </Text> */}
                  <Text fw={600} c={"dimmed"} mb={"xs"}>
                    <span className="text-black">Date Founded: </span>
                    <span>
                      {formatDate(
                        selectedItem?.ministry?.dateFounded || new Date(),
                        "dd MMMM, yyyy"
                      ) || "--"}
                    </span>
                  </Text>
                </Flex>
              </Tabs.Panel>
            )}
          </Tabs>
        </Modal>
      )}
    </>
  );
};

export default AdminMinisterManager;
