import axios from "axios";

const baseURL = "https://reon-h2nm.onrender.com/api"
// const baseURL = "http://localhost:8000/api"
const instance = axios.create({
    baseURL
})

instance.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    // Unexpected errors (network down, server down, db down, bug)
    // - Log them
    // - Display generic and friendly error message to user

    if (!expectedError) {
        console.log(error);
    }
    return Promise.reject(error);
});

instance.interceptors.request.use(async config => {
    const token = localStorage.getItem('REON_USER_TOKEN')
    if (!token) return config;
    config.headers['auth-token'] = token;
    return config;
})

export default instance;
