import {
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineMessage,
  AiOutlineShareAlt,
} from "react-icons/ai";
import styles from "../styles.module.css";
import { useState } from "react";
import { toast } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";

const SingleDevotionalCard = ({ devotional }) => {
  const [showFullDevotionalContent, setShowFullDevotionalContent] =
    useState(false);
  const navigate = useNavigate();

  const handleShareDevotional = async () => {
    const link = `${window.location.origin}/public/devotional/${devotional._id}`;

    try {
      await navigator.clipboard.writeText(link);
      toast("Successfully copied share link to clipboard!").success();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.single__Devotional}>
      <div className={styles.devotional__Actions}>
        <AiOutlineEdit />
        <AiOutlineShareAlt
          onClick={() => {
            if(devotional?.fullDevotion) return;
            handleShareDevotional();
          }}
        />
      </div>
      <img
        src={
          "https://img.freepik.com/free-vector/party-silhouettes-light-background_1048-5144.jpg?size=626&ext=jpg&ga=GA1.1.225976907.1673277028&semt=country_rows_v1"
        }
        alt="devotional"
        className={styles.devotional__img}
      />
      <div>
        <h4>
          {devotional.title.length > 25
            ? devotional.title.slice(0, 25) + "..."
            : devotional.title}
        </h4>
        <p className={styles.devotional__Date}>
          {new Date(devotional.dateForDevotional).toDateString()}
        </p>
      </div>
      <div className={styles.devotional__Stats}>
        <div>
          <AiOutlineEye />
          <p>{devotional.timesViewed ? devotional.timesViewed : 0}</p>
        </div>
        <div className={styles.comments}>
          <AiOutlineMessage />
          <p>{devotional?.comments?.length || 0}</p>
        </div>
      </div>
      <div className={styles.devotional__Verse__Content}>
        <p>
          {devotional.newDevotionalStructure ? (
            <span>{devotional?.bibleVerse || "--"}</span>
          ) : (
            <span>
              {devotional.book || "--"} {devotional.chapter || "--"}:
              {devotional.verse || "--"}
            </span>
          )}
        </p>
      </div>
      {devotional?.devotionalContent && (
        <>
          <p className={styles.devtional__Content}>
            {devotional.devotionalContent.length > 100 ? (
              <>
                {showFullDevotionalContent ? (
                  <>{devotional.devotionalContent}</>
                ) : (
                  <>
                    {devotional.devotionalContent.slice(devotional, 100) +
                      "..."}
                  </>
                )}
              </>
            ) : (
              devotional.devotionalContent
            )}
          </p>
          {devotional.devotionalContent.length > 100 && (
            <button
              onClick={() =>
                setShowFullDevotionalContent(!showFullDevotionalContent)
              }
              className={styles.see__Devotional__Content__Btn}
            >
              {showFullDevotionalContent ? "See less" : "See more"}
            </button>
          )}
        </>
      )}

      {!devotional?.fullDevotion && (
        <button
          onClick={() => navigate(`/admin-devotional/${devotional?._id}`)}
          className={styles.view__Devotional__Content__Btn}
        >
          {"View devotional"}
        </button>
      )}
    </div>
  );
};

export default SingleDevotionalCard;
