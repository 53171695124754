import React, { useEffect, useState } from 'react';
import { AppShell, useMantineTheme } from '@mantine/core';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardHeader from "./DashboardHeader";
import { useUserContext } from '../../context/UserContext';
import { getEnlistedMinisters } from '../../services/userService';
import { toast } from '../../utils/helper';


function DashboardLayout({ isAdmin, translateComponent}) {
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const navigate = useNavigate();

    const { user, setMinistersEnlisted } = useUserContext()

    useEffect(() => {
        if (user?.accountType === 'ADMIN') return navigate('/admin-dashboard')
        
        getEnlistedMinisters().then(res => {
            console.log(res.data)
            setMinistersEnlisted(res.data.data)
        }).catch(err => {
            console.log(err)
            toast("Couldn't fetch enlisted ministers!").error()
        })
    }, [])

    return (
        <AppShell
            layout='alt'
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            }}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            header={<DashboardHeader opened={opened} setOpened={setOpened} theme={theme} translateComponent={translateComponent} />}
            navbar={<Sidebar isAdminUser={isAdmin} opened={opened} setOpened={setOpened} translateComponent={translateComponent} />}
        >
            <Outlet />
        </AppShell>
    );
}

export default DashboardLayout;