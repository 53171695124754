import React from 'react';
import UpcomingEventsAndMission from '../../components/dashboard/UpcomingEventsAndMission';
import NetworkManager from '../../components/dashboard/NetworkManager';

function MyNetwork(props) {
    return (
        <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-[7.5px] 2xl:gap-[7.5] items-start">
            <NetworkManager />
            <UpcomingEventsAndMission />
        </div>
    );
}

export default MyNetwork;