/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Text, Flex, TextInput, Select, Image, Button } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-number-input";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { inputStyles, toast, userTitles } from "../../utils/helper";
import { USER_PROFILE, useUserContext } from "../../context/UserContext";
import { updateAccount } from "../../services/userService";
import { isJSONStringRegex } from "../../utils/helper";

function AccountSettings({ loading, setLoading }) {
  const { user, userDispatch } = useUserContext();

  const username = user?.name ? user?.name?.split(" ") : "";
  const openRef = useRef(null);

  const [selectedCountryStates, setSelectedCountryStates] = useState([]);
  const [selectedStateCities, setSelectedStateCities] = useState([]);
  const [info, setInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    country: "",
    state: "",
    title: "",
    image: "",
  });

  const handleInputchange = (name, value) =>
    setInfo({ ...info, [name]: value });

  useEffect(() => {
    if (user?.name && !info.email) {
      console.log(user);
      setInfo({
        ...user,
        firstName: username[0],
        lastName: username[1],
      });
    }
  }, [user?.name]);

  useEffect(() => {
    if (!info.country) return;
    if (!isJSONStringRegex(info.country)) return;
    const { isoCode } = JSON.parse(info.country);
    setSelectedCountryStates(State.getStatesOfCountry(isoCode));
  }, [info.country]);

  useEffect(() => {
    if (!info.state) return;
    if (!isJSONStringRegex(info.country) || isJSONStringRegex(info.state))
      return;
    const { isoCode: countryCode } = JSON.parse(info.country);
    const { isoCode: stateCode } = JSON.parse(info.state);
    setSelectedStateCities(City.getCitiesOfState(countryCode, stateCode));
  }, [info.state]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!info.phone) {
      toast("Fill all fields!").error();
      return;
    }

    const formdata = new FormData();
    formdata.append("title", info.title);
    formdata.append("name", `${info.firstName} ${info.lastName}`);
    formdata.append("email", info.email);
    formdata.append("phone", info.phone);
    // formdata.append("country", JSON.parse(info.country)?.name)
    formdata.append("country", info?.country);
    formdata.append("state", info.state);
    // formdata.append("state", JSON.parse(info.state)?.name)
    formdata.append("city", info.city);
    formdata.append("address", info.address);

    if (typeof info.image !== "string") {
      formdata.append("image", info.image);
    }
    setLoading(true);
    updateAccount(formdata)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        toast(res.data?.message).success();
        userDispatch({
          type: USER_PROFILE,
          payload: res.data?.data,
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast(err?.response?.data?.message).error();
      });
  };

//   useEffect(() => {
//     handleInputchange("state", "");
//   }, [info?.country]);

//   useEffect(() => {
//     handleInputchange("city", "");
//   }, [info.state]);

  return (
    <Flex
      direction={"row"}
      justify={"space-between"}
      mt={20}
      className="md:px-5"
    >
      <form onSubmit={handleSubmit}>
        <Flex
          className="flex md:flex-row sm:flex-row justify-around flex-col"
          // direction={'row'} justify={"space-around"}
        >
          <Flex
            direction={"column"}
            className="md:min-w-[300px] w-[80%] sm:w-full"
          >
            <Text className="fon-medium text-xl font-2 mb-3">
              Account Settings
            </Text>

            <Text className="font-normal text-xs text-color-2 mb-2">Title</Text>
            <Select
              required
              size="md"
              placeholder="Title"
              searchable
              nothingFound="No options"
              clearable
              value={info.title}
              onChange={(value) => handleInputchange("title", value)}
              data={userTitles}
              sx={{
                input: inputStyles,
              }}
            />

            <Text className="font-normal text-xs text-color-2 my-2">
              First Name
            </Text>
            <TextInput
              required
              size="md"
              value={info.firstName}
              onChange={({ target: { value } }) =>
                handleInputchange("firstName", value)
              }
              sx={{
                input: inputStyles,
              }}
            />

            <Text className="font-normal text-xs text-color-2 my-2">
              Last Name
            </Text>
            <TextInput
              required
              size="md"
              value={info.lastName}
              onChange={({ target: { value } }) =>
                handleInputchange("lastName", value)
              }
              sx={{
                input: inputStyles,
              }}
            />

            <Text className="font-normal text-xs text-color-2 my-2">Email</Text>
            <TextInput
              required
              size="md"
              value={info.email}
              onChange={({ target: { value } }) =>
                handleInputchange("email", value)
              }
              sx={{
                input: inputStyles,
              }}
            />

            <Text className="font-normal text-xs text-color-2 my-2">Phone</Text>
            {/* <TextInput value={info.email} /> */}
            <PhoneInput
              placeholder="Enter phone"
              value={info.phone}
              limitMaxLength
              onChange={(value) => handleInputchange("phone", value)}
            />

            <Flex className="md:flex-row flex-col w-full justify-between">
              <Flex direction={"column"} className="md:w-[48%] w-full">
                <Text className="font-normal text-xs text-color-2 my-2">
                  Country
                </Text>
                <Select
                  required
                  size="md"
                  placeholder="Country"
                  searchable
                  nothingFound="No options"
                  clearable
                  value={info.country}
                  onChange={(value) => {
                    handleInputchange("country", value);
                  }}
                  data={[
                    ...Country.getAllCountries().map((data) => {
                      return {
                        value: JSON.stringify({
                          isoCode: data.isoCode,
                          name: data.name,
                        }),
                        label: data.name,
                      };
                    }),
                  ]}
                  sx={{
                    input: inputStyles,
                  }}
                />
              </Flex>

              <Flex direction={"column"} className="md:w-[48%] w-full">
                <Text className="font-normal text-xs text-color-2 my-2">
                  State
                </Text>
                <Select
                  size="md"
                  placeholder="State"
                  searchable
                  nothingFound="No options"
                  clearable
                  value={info.state}
                  onChange={(value) => {
                    handleInputchange("state", value);
                    // handleInputchange("city", "");
                  }}
                  data={[
                    ...selectedCountryStates.map((data) => {
                      return {
                        value: JSON.stringify({
                          isoCode: data.isoCode,
                          name: data.name,
                        }),
                        label: data?.name,
                      };
                    }),
                  ]}
                  sx={{
                    input: inputStyles,
                  }}
                />
              </Flex>
            </Flex>

            <Text className="font-normal text-xs text-color-2 my-2">City</Text>
            <TextInput
              required
              size="md"
              value={info.city}
              onChange={({ target: { value } }) =>
                handleInputchange("city", value)
              }
              sx={{
                input: inputStyles,
              }}
            />
            {/* <Select
                            size='md'
                            className="w-full mt-0 md:mt-5"
                            placeholder="City"
                            searchable
                            nothingFound="No options"
                            clearable
                            value={info.city}
                            onChange={(value) => handleInputchange("city", value)}
                            data={[...selectedStateCities.map(data => {
                                return { value: data?.name, label: data?.name }
                            })]}
                            sx={{
                                input: inputStyles
                            }} /> */}

            <Text className="font-normal text-xs text-color-2 my-2">
              Address
            </Text>
            <TextInput
              required
              size="md"
              placeholder="Address"
              value={info.address}
              onChange={({ target: { value } }) =>
                handleInputchange("address", value)
              }
              sx={{
                input: inputStyles,
              }}
            />
          </Flex>

          <Flex direction={"row"} className="md:ml-20 md:mt-0 mt-5 ">
            <Dropzone
              openRef={openRef}
              className="hidden"
              onDrop={(files) => {
                handleInputchange("image", files[0]);
                console.log("accepted files", files);
              }}
              onReject={(files) => {
                console.log("rejected files", files);
                toast("Selected file must be an image!").error();
              }}
              accept={["image/png", "image/jpeg", "image/sgv+xml", "image/gif"]}
            >
              {/* children */}
            </Dropzone>
            <Image
              src={
                typeof info.image === "string"
                  ? info?.image
                  : URL.createObjectURL(info.image)
              }
              width={200}
              height={200}
              radius={10}
            />

            <Flex
              className="w-[30px] h-[30px] bg-[#FFF2E2] rounded-sm -ml-[45px] mt-2 z-10 cursor-pointer"
              justify={"center"}
              align={"center"}
              onClick={() => openRef.current()}
            >
              <AiOutlineCloudUpload className="text-black" size={20} />
            </Flex>
          </Flex>
        </Flex>
        <Button
          loading={loading}
          loaderPosition="center"
          // fullWidth
          mt="xl"
          size="md"
          type="submit"
          radius={"md"}
          className="bg-color-1 font-normal text-lg text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:bg-color-1"
        >
          Update
        </Button>
      </form>
    </Flex>
  );
}

export default AccountSettings;
