
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserContext, userToken } from '../../context/UserContext';
import { dashboardRoutes } from '../../utils/routes';

function PrivateRoutes({ children }) {
    const token = userToken()
    const { user } = useUserContext()
    const location = useLocation()



    const redirectUrl = () => {
        if (location.pathname === dashboardRoutes.settings) {
            const settingType = new URLSearchParams(window.location.search).get("type")
            if (settingType) {
                return `${location.pathname}?type=${settingType}`
            } else return location.pathname
        }
        return location.pathname
    }

    if (!user?.name || !token) {
        return <Navigate to={`/login?redirectUrl=${redirectUrl()}`} />
    }
    return children
}

export default PrivateRoutes;
