import React, { useEffect, useState, useRef } from "react"
import { Modal, Text, Flex, TextInput, Select, Textarea, Group, Image, Button, ScrollArea } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { Country, State } from 'country-state-city';
import { inputStyles, outreachType, toast } from "../../../utils/helper";
import PhoneInput from 'react-phone-number-input'
import { FaCalendarAlt } from "react-icons/fa";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { FiUploadCloud } from "react-icons/fi";
import { FcCancel } from "react-icons/fc";
import styles from './styles.module.css';
import { AiOutlineClose, AiOutlineMail } from "react-icons/ai";
import { addNewSoul } from "../../../services/soulServices";
import { useSoulsContext } from "../../../context/SoulsContext";

const NewSoulModal = ({ opened, closeModal }) => {
    const [loading, setLoading] = useState(false);
    const [ newSoulDetails, setNewSoulDetails ] = useState(initialSoulDetails);
    const [uploadedImg, setUploadedImg] = useState(null);
    const {
        souls, 
        setSouls,
    } = useSoulsContext();

    const openRef = useRef(null);
    
    const handleUpdateNewSoulObj = (name, val) => {
        setNewSoulDetails((prevVal) => {
        return {
            ...prevVal,
            [name]: val,
        }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const [
            copyOFNewSoulDetails,
            copyOfSouls
        ] = [
            structuredClone(newSoulDetails),
            souls.slice(),
        ];
        copyOFNewSoulDetails.country = JSON.parse(newSoulDetails.country)?.name;

        console.log(copyOFNewSoulDetails);
        setLoading(true);

        const formData = new FormData()
        
        Object.keys(copyOFNewSoulDetails || {}).forEach(key => {
            formData.append(`${key}`, copyOFNewSoulDetails[key]);
        })

        formData.append("photo", uploadedImg);

        addNewSoul(formData).then(res => {
            setLoading(false);

            const newSoulAdded = res.data;
            copyOfSouls.unshift(newSoulAdded);
            setSouls(copyOfSouls);
            setNewSoulDetails(initialSoulDetails);
            setUploadedImg(null)

            toast('Successfully added soul').success();
            closeModal();
        }).catch(error => {
            console.log(error)
            setLoading(false)
            toast(
                error.response ?
                    error.response.status === 500 ?
                        'Something went wrong while trying to add your soul'
                    :
                    error.response.data.message
                :
                'Something went wrong while trying to add your soul'
            ).error();
        })
    }

    return (
        <>
            <Modal
                // className="!h-[500px]"
                mt={20}
                opened={opened}
                withCloseButton={false}
                closeOnEscape={false}
                closeOnClickOutside={false}
                onClose={closeModal}
                title=""
                // centered
                size={{ md: '70vw', sm: "100vw" }}
            >
                {/* Modal content */}
                <ScrollArea
                    className="flex w-full flex-col min-h-[550px] overflow-x-hidden"
                >
                    <div className={styles.modal__Close__Container} onClick={() => closeModal()}>
                        <AiOutlineClose className={styles.modal__Close__Icon} />
                    </div>
                    <Text className='md:text-center text-start font-2 font-medium text-sm md:text-xl'>Just won a soul? Add the record now for accountability !</Text>
                    <form onSubmit={handleSubmit}>
                        <Flex className='flex-col md:flex-row justify-around mt-4'>

                            <Flex className='flex-col w-full md:w-[45%]'>
                                <Text className="font-medium text-base text-color-2 mb-2">Soul Details</Text>
                                <TextInput
                                    required
                                    value={newSoulDetails.fullName}
                                    onChange={({ target }) => handleUpdateNewSoulObj(target.name, target.value)}
                                    type="text"
                                    size='md'
                                    placeholder='Full Name'
                                    sx={{
                                        input: inputStyles
                                    }}
                                    name="fullName"
                                />

                                <Flex className="md:flex-row flex-col justify-between mt-5">
                                    <Select
                                        required
                                        size='md'
                                        className="md:w-[48%] w-full"
                                        placeholder="Country"
                                        searchable
                                        nothingFound="No options"
                                        clearable
                                        value={newSoulDetails.country}
                                        onChange={(value) => handleUpdateNewSoulObj('country', value)}
                                        data={[...Country.getAllCountries().map(data => {
                                            return { value: JSON.stringify({ isoCode: data.isoCode, name: data.name }), label: data.name }
                                        })]}
                                        sx={{
                                            input: inputStyles
                                        }}
                                    />
                                    <TextInput
                                        required
                                        value={newSoulDetails.state}
                                        onChange={({ target }) => handleUpdateNewSoulObj(target.name, target.value)}
                                        type="text"
                                        size='md'
                                        placeholder='State'
                                        sx={{
                                            input: inputStyles
                                        }}
                                        name="state"
                                        className="md:w-[48%] w-full mt-5 md:mt-0"
                                    />
                                </Flex>

                                <div className={styles.form__Item}>
                                    <span>Date Saved</span>
                                    <DatePickerInput
                                        required
                                        // mt={20}
                                        placeholder="Date Saved"
                                        size='md'
                                        sx={{
                                            button: inputStyles
                                        }}
                                        icon={<FaCalendarAlt />}
                                        value={newSoulDetails.dateSaved} 
                                        name="dateSaved"
                                        onChange={({ target }) => handleUpdateNewSoulObj(target.name, target.value)}
                                    />
                                </div>
                                
                                <div className={styles.form__Item}>
                                    <span>Email</span>
                                    <TextInput
                                        required
                                        // mt={20}
                                        placeholder="abc@email.com"
                                        size='md'
                                        sx={{
                                            button: inputStyles
                                        }}
                                        icon={<AiOutlineMail />}
                                        value={newSoulDetails.email} 
                                        name="email"
                                        onChange={({ target }) => handleUpdateNewSoulObj(target.name, target.value)}
                                    />
                                </div>

                                <div className={styles.form__Item}>
                                    <span>Occasion</span>
                                    <Select
                                        required
                                        placeholder="Occasion"
                                        value={newSoulDetails.occasion}
                                        onChange={(value) => handleUpdateNewSoulObj('occasion', value)}
                                        size='md'
                                        sx={{
                                            input: inputStyles
                                        }}
                                        data={occasions}
                                    />
                                </div>
                                
                                <Textarea
                                    required
                                    value={newSoulDetails.salvationDescription}
                                    onChange={({ target }) => handleUpdateNewSoulObj(target.name, target.value)}
                                    mt={20}
                                    sx={{
                                        textarea: { ...inputStyles, height: "150px" }
                                    }}
                                    placeholder="Describe the event of the salvation"
                                    name="salvationDescription"
                                />



                            </Flex>

                            <Flex className="w-full md:w-[45%] flex-col md:mt-0 mt-5">
                                <Text className="font-medium text-base text-color-2 mb-2">Personal Info</Text>
                                <PhoneInput
                                    placeholder="Enter phone"
                                    value={newSoulDetails.phoneNumber}
                                    limitMaxLength
                                    onChange={(val) => handleUpdateNewSoulObj('phoneNumber', val)}
                                />

                                <Select
                                    required
                                    value={newSoulDetails.gender}
                                    onChange={(value) => handleUpdateNewSoulObj('gender', value)}
                                    placeholder="Gender"
                                    mt={20}
                                    sx={{
                                        input: inputStyles
                                    }}
                                    size='md'
                                    data={[
                                        { value: "Male", label: "Male" },
                                        { value: "Female", label: "Female" }
                                    ]}
                                />

                                <div className={styles.form__Item}>
                                    <span>Date of birth (optional)</span>
                                    <DatePickerInput
                                        placeholder="Date of birth (optional)"
                                        size='md'
                                        sx={{
                                            input: inputStyles
                                        }}
                                        icon={<FaCalendarAlt />}
                                        value={newSoulDetails.dateOfBirth} 
                                        onChange={({ target }) => handleUpdateNewSoulObj(target.name, target.value)}
                                        name="dateOfBirth"
                                    />
                                </div>
                                
                                <Dropzone
                                    openRef={openRef}
                                    mt={20}
                                    className="border border-solid w-full md:w-[50%] mx-auto h-[150px] rounded-md"
                                    onDrop={(files) => {
                                        setUploadedImg(files[0])
                                        console.log('accepted files', files)
                                    }}
                                    onReject={(files) => console.log('rejected files', files)}
                                    maxSize={3 * 1024 ** 2}
                                    accept={IMAGE_MIME_TYPE}
                                >
                                    <Group position="center" spacing="xl"
                                    >
                                        <Dropzone.Accept>
                                            <FcCancel size={'3.2rem'} className="text-[red]" />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <FcCancel size={'3.2rem'} className="text-[red]" />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            {!uploadedImg ?
                                                <FiUploadCloud size={"3.2rem"} className="text-[gray] absolute bottom-10" /> :

                                                <Image src={URL.createObjectURL(uploadedImg)} height={120} className="" />
                                            }

                                        </Dropzone.Idle>

                                    </Group>

                                </Dropzone>
                                <Flex className="flex-col mt-2">
                                    <Text className="font-2 font-medium text-sm md:text-xl text-center">Upload photo of soul</Text>
                                    <Text className="font-normal text-xs md:text-sm text-center">Optional</Text>
                                </Flex>

                            </Flex>

                        </Flex>

                        <div className=" text-center">
                            <Button
                                loading={loading}
                                loaderPosition="center"
                                type="submit"
                                className="w-[250px] !mx-auto bg-color-1 font-normal text-sm md:text-lg mt-7 text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:bg-color-1"
                            >
                                {
                                    loading ? 'Please wait...'
                                    :
                                    'Upload'
                                }
                            </Button>
                        </div>
                    </form>

                </ScrollArea>
            </Modal>
        </>
    );
}

const occasions = [
    { label: 'Outreach Meeting', value: 'Outreach Meeting' },
]

const initialSoulDetails = {
    fullName: '',
    country: '',
    state: '',
    dateSaved: new Date(),
    email: '',
    occasion: '',
    gender: '',
    salvationDescription: '',
    phoneNumber: '',
    dateOfBirth: new Date(),
}

export default NewSoulModal;