import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/dashboard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import NotFound from "./pages/NotFound";
import VerifyAccount from "./pages/VerifyAccount";
import PrivateRoutes from "./components/routes/PrivateRoutes";
import PublicRoutes from "./components/routes/PublicRoutes";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import { DevotionalContextProvider } from "./context/DevotionalContext";
import SingleDevotionalPage from "./pages/SingleDevotionalPage/SingleDevotionalPage";
import Resources from "./pages/dashboard/Resources";
import { dashboardRoutes } from "./utils/routes";
import React from "react";
import MyNetwork from "./pages/dashboard/MyNetwork";
import Settings from "./pages/dashboard/Settings";
import AllDevotionalsPage from "./pages/AllDevotionalsPage/AllDevotionalsPage";
import AdminResourcesPage from "./pages/ResourcesPage/AdminResourcesPage";
import { ResourcesContextProvider } from "./context/ResourcesContext";
import MinisterResourcesPage from "./pages/ResourcesPage/MinisterResourcesPage";
import { useTranslation } from "react-i18next";
import { SoulsContextProvider } from "./context/SoulsContext";
import { EventContextProvider } from "./context/EventContext";
import { OutreachContextProvider } from "./context/OutreachContext";
import SoulManager from "./pages/dashboard/SoulManager/SoulManager";
import AdminSoulManager from "./pages/AdminSoulManager/AdminSoulManager";
import { MinistersContextProvider } from "./context/MinisterContext";
import AdminMinisterManager from "./pages/AdminMinisterManager/AdminMinisterManager";
import OutreachManager from "./pages/dashboard/OutreachManager/OutreachManager";
import DonationPage from "./pages/dashboard/DonationPage";
import UploadedEvents from "./pages/UploadedEvents";
import ChurchManager from "./components/dashboard/ChurchManager";
import UploadedMinistersOutreaches from "./pages/UploadedMinistertsOutreaches";
import UploadedChurches from "./pages/UploadedChurches";

const dashboardPages = [
  { path: dashboardRoutes["/"], Component: Dashboard },
  { path: dashboardRoutes.resources, Component: Resources },
  { path: dashboardRoutes.my_network, Component: MyNetwork },
  { path: dashboardRoutes.settings, Component: Settings },
  { path: dashboardRoutes.all_resources, Component: MinisterResourcesPage },
  { path: dashboardRoutes.souls, Component: SoulManager },
  { path: dashboardRoutes.outreaches, Component: OutreachManager },
  { path: "/dashboard/church-manager", Component: ChurchManager },
  { path: "/partnership", Component: DonationPage },
];

function App() {
  const { t } = useTranslation();

  return (
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <Notifications position="top-right" zIndex={2077} />
      {/* <Notifications position="top-right" zIndex={3000} /> */}
      <BrowserRouter basename="/">
        <Routes>
          {/* <Route index element={<Navigate to="/login" />} /> */}
          <Route
            path="login"
            index
            element={
              <PublicRoutes>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="register"
            element={
              <PublicRoutes>
                <Register />
              </PublicRoutes>
            }
          />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/verify-account/:verificationCode"
            element={<VerifyAccount />}
          />
          <Route
            element={
              <PrivateRoutes>
                <DashboardLayout isAdmin={false} translateComponent={t} />
              </PrivateRoutes>
            }
          >
            {dashboardPages.map((item, key) => (
              <Route
                path={item.path}
                element={
                  <DevotionalContextProvider>
                    <ResourcesContextProvider>
                      <SoulsContextProvider>
                        <EventContextProvider>
                          <OutreachContextProvider>
                            <MinistersContextProvider>
                              <React.Fragment key={key}>
                                <item.Component translateComponent={t} />
                              </React.Fragment>
                            </MinistersContextProvider>
                          </OutreachContextProvider>
                        </EventContextProvider>
                      </SoulsContextProvider>
                    </ResourcesContextProvider>
                  </DevotionalContextProvider>
                }
              />
            ))}

            <Route
              path="devotional/:id"
              element={<SingleDevotionalPage normalUser={true} />}
            />
          </Route>

          <Route
            element={
              <PrivateRoutes>
                <DashboardLayout isAdmin={true} translateComponent={t} />
              </PrivateRoutes>
            }
          >
            <Route path="/events" element={<UploadedEvents />} />
            <Route
              path="/outreaches"
              element={<UploadedMinistersOutreaches />}
            />
            <Route path="/churches" element={<UploadedChurches />} />
            <Route
              path="admin-dashboard"
              element={
                <DevotionalContextProvider>
                  <ResourcesContextProvider>
                    <SoulsContextProvider>
                      <EventContextProvider>
                        <OutreachContextProvider>
                          <MinistersContextProvider>
                            <AdminDashboard translateComponent={t} />
                          </MinistersContextProvider>
                        </OutreachContextProvider>
                      </EventContextProvider>
                    </SoulsContextProvider>
                  </ResourcesContextProvider>
                </DevotionalContextProvider>
              }
            />
            <Route
              path="admin-devotional/:id"
              element={<SingleDevotionalPage />}
            />
            <Route
              path="admin-devotional/all"
              element={
                <DevotionalContextProvider>
                  <ResourcesContextProvider>
                    <SoulsContextProvider>
                      <EventContextProvider>
                        <OutreachContextProvider>
                          <MinistersContextProvider>
                            <AllDevotionalsPage translateComponent={t} />
                          </MinistersContextProvider>
                        </OutreachContextProvider>
                      </EventContextProvider>
                    </SoulsContextProvider>
                  </ResourcesContextProvider>
                </DevotionalContextProvider>
              }
            />
            <Route
              path="admin-resources/all"
              element={
                <DevotionalContextProvider>
                  <ResourcesContextProvider>
                    <SoulsContextProvider>
                      <EventContextProvider>
                        <OutreachContextProvider>
                          <MinistersContextProvider>
                            <AdminResourcesPage translateComponent={t} />
                          </MinistersContextProvider>
                        </OutreachContextProvider>
                      </EventContextProvider>
                    </SoulsContextProvider>
                  </ResourcesContextProvider>
                </DevotionalContextProvider>
              }
            />
            <Route
              path="admin-souls/all"
              element={
                <DevotionalContextProvider>
                  <ResourcesContextProvider>
                    <SoulsContextProvider>
                      <EventContextProvider>
                        <OutreachContextProvider>
                          <MinistersContextProvider>
                            <AdminSoulManager translateComponent={t} />
                          </MinistersContextProvider>
                        </OutreachContextProvider>
                      </EventContextProvider>
                    </SoulsContextProvider>
                  </ResourcesContextProvider>
                </DevotionalContextProvider>
              }
            />
            <Route
              path="admin-ministers/all"
              element={
                <DevotionalContextProvider>
                  <ResourcesContextProvider>
                    <SoulsContextProvider>
                      <EventContextProvider>
                        <OutreachContextProvider>
                          <MinistersContextProvider>
                            <AdminMinisterManager translateComponent={t} />
                          </MinistersContextProvider>
                        </OutreachContextProvider>
                      </EventContextProvider>
                    </SoulsContextProvider>
                  </ResourcesContextProvider>
                </DevotionalContextProvider>
              }
            />
            <Route
              path="admin-settings"
              element={
                <DevotionalContextProvider>
                  <ResourcesContextProvider>
                    <SoulsContextProvider>
                      <EventContextProvider>
                        <OutreachContextProvider>
                          <MinistersContextProvider>
                            <Settings isAdmin={true} translateComponent={t} />
                          </MinistersContextProvider>
                        </OutreachContextProvider>
                      </EventContextProvider>
                    </SoulsContextProvider>
                  </ResourcesContextProvider>
                </DevotionalContextProvider>
              }
            />
          </Route>

          <Route
            path="/public/devotional/:id"
            element={<SingleDevotionalPage publicUser={true} />}
          />
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
