/* eslint-disable array-callback-return */
import React, { useRef, useState } from 'react';
import { Modal, Text, Button, Flex, TextInput } from '@mantine/core';
import { inputStyles, toast } from '../../utils/helper';
import { FaCopy } from "react-icons/fa"
import { ImCancelCircle } from "react-icons/im"
import { useUserContext } from "../../context/UserContext"
import { inviteMinisters } from '../../services/userService';

function EnlistModal({ opened, close }) {
    const [emails, setEmails] = useState([])
    const [loading, setLoading] = useState(false)
    const { user } = useUserContext()

    const inputRef = useRef()
    const urlProviders = [".com", ".net", ".ng", ".ru", ".co.uk", ".fr", ".br", ".in", ".org"];
    const handleInput = ({ target: { value } }) => {
        const email = String(value).toString()
        if (
            (email.includes('@') &&
                (urlProviders.find(x => email.includes(x))) &&
                !emails.some(item => item?.email === email) &&
                user?.email !== email
            )
        ) {
            // if (emails.some(item => item?.email === email)) return;
            setEmails([...emails, { email }])
            inputRef.current.value = "";
        }
        if (email.includes(",")) {
            const data = email.split(",")
            let copiedData = []

            data.map(user => {
                if ((user.includes('@') &&
                    (urlProviders.find(x => user.includes(x))))
                    && !emails.some(item => item?.email === user)
                    && user?.email !== user) {
                    copiedData.push({ email: user })
                }
            })

            setEmails([...emails, ...copiedData])
            inputRef.current.value = "";
        }
    }


    const removeEmail = (email) => {
        setEmails(emails.filter(items => items?.email !== email))
    }

    const handleMinistersEnlisting = () => {
        console.log(emails)
        inviteMinisters(emails).then(res => {
            console.log(res)
            setEmails([])
            toast(res.data?.message).success()
        }).catch(err => {
            console.log(err)
            toast(err?.response?.data?.message).error()
        })
    }

    return (
        <Modal
            closeButtonProps={{ size: "md" }}
            opened={opened}
            onClose={close}
            title={
                <Text className='font-2 font-medium text-xl'>Invite a minister</Text>
            }
            centered
        >
            <Text className='text-color-2'>You can invite multiple ministers by adding their
                emails separated by a comma</Text>
            {emails.length > 0 && <Flex direction={'row'}
                className='w-full border min-h-[70px] items-center px-3 overflow-x-scroll ' justify={'flex-start'}>
                {emails.map(item => (
                    <Button
                        onClick={() => removeEmail(item?.email)}
                        rightIcon={<ImCancelCircle />}
                        // mt="xl"
                        size="sm"
                        type="submit"
                        radius={"md"}
                        className="mr-3 bg-blue-600 font-normal text-lg text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:bg-blue-600"
                    >
                        {item?.email}
                    </Button>
                )
                )}

            </Flex>}
            <TextInput
                ref={inputRef}
                onChange={handleInput}
                multiple
                size='lg'
                placeholder='Enter email(s)'
                className='my-5'
                sx={{
                    input: inputStyles
                }}
            />

            <Text className='font-bold text-color-3'>
                Your referral link:
            </Text>

            <Flex className='items-center cursor-pointer w-[70%]'>
                <Text className='text-color-2'>
                    {`https://portal.reoninternational.org/register?referral=${user?.referralCode}`}
                </Text> &nbsp;
                <FaCopy className='text-color-2' />
            </Flex>

            <div className='w-full text-center my-5'>
                <Button
                    loading={loading}
                    loaderPosition='center'
                    onClick={handleMinistersEnlisting}
                    className='w-[150px] bg-color-1 font-normal text-lg text-white transition duration-300 ease-in-out hover:bg-opacity-80 hover:bg-color-1'>
                    Enlist
                </Button>
            </div>

        </Modal>
    );
}

export default EnlistModal;