import React, { useState } from "react";
import DevotionalCard from "../../components/DevotionalCard/DevotionalCard";
import Raphsody from "../../components/dashboard/Raphsody";
import UpcomingEventsAndMission from "../../components/dashboard/UpcomingEventsAndMission";
import { useDevotionalContext } from "../../context/DevotionalContext";
import { getDevotionalsForMinister } from "../../services/devotionalService";
import { useEffect } from "react";
import { useResourcesContext } from "../../context/ResourcesContext";
import { getMinisterResources } from "../../services/resourcesService";
import ResourceCard from "../ResourcesPage/ResourceCard";
import './style.css';
import { useNavigate } from "react-router-dom";
import { dashboardRoutes } from "../../utils/routes";

const Resources = ({ translateComponent }) => {
    const [currentDevotional, setCurrentDevotional] = useState(null);
    const {
        devotionals,
        setDevotionals,
        setDevotionalsLoaded,
        setDevotionalsLoading,
        devotionalsLoading,
        devotionalsLoaded,
    } = useDevotionalContext();
    const {
        resources,
        setResources,
        resourcesLoading,
        setResourcesLoading,
        resourcesLoaded,
        setResourcesLoaded,
    } = useResourcesContext();
    const today = new Date();
    const navigate = useNavigate();

    useEffect(() => {
        if (!devotionalsLoaded) {
            setDevotionalsLoading(true);

            getDevotionalsForMinister().then(res => {
                const devotionalsFetched = res.data;
    
                setDevotionals(devotionalsFetched);
                setDevotionalsLoading(false);
                setDevotionalsLoaded(true);
    
                const dailyDevotionalIndex = devotionalsFetched.findIndex(
                    devotional =>
                        devotional.dateForDevotional &&
                        !(new Date(devotional.dateForDevotional) == 'Invalid Date') &&
                        new Date(devotional.dateForDevotional).toDateString() === today.toDateString()
                );
                if (dailyDevotionalIndex === -1) return
    
                setCurrentDevotional(dailyDevotionalIndex);
            }).catch(err => {
                console.log(err);
                setDevotionalsLoading(false);
            })
        }

        if (!resourcesLoaded) {
            setResourcesLoading(true);

            getMinisterResources().then(res => {
                setResourcesLoading(false);
                setResourcesLoaded(true);

                setResources(res.data);
            }).catch(err => {
                console.log(err);
                setResourcesLoading(false);
            })
        }

        if (devotionalsLoaded) {
            const dailyDevotionalIndex = devotionals.findIndex(
                devotional =>
                    devotional.dateForDevotional &&
                    !(new Date(devotional.dateForDevotional) == 'Invalid Date') &&
                    new Date(devotional.dateForDevotional).toDateString() === today.toDateString()
            );
            if (dailyDevotionalIndex === -1) return

            setCurrentDevotional(dailyDevotionalIndex);
        }
        
    }, [])


    return (
        <>
            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-[7.5px] 2xl:gap-[7.5] items-start">
                {/* <Raphsody /> */}
                <section
                    className="col-span-12 rounded-xl xl:col-span-8"
                >
                    <div>
                        <h2 className="title__I">
                            <span>{translateComponent('Resources')}</span>
                            <button onClick={() => navigate(dashboardRoutes.all_resources)}>{translateComponent('View')} {translateComponent('all')}</button>
                        </h2>
                        <div>
                            {
                                resourcesLoading ? <p  style={{ fontSize: '0.8rem' }}>{translateComponent('Loading')} {translateComponent('resources')}...</p>
                                :
                                !resourcesLoaded ? <p  style={{ fontSize: '0.8rem' }}>{translateComponent('Failed')} {translateComponent('to')} {translateComponent('load')} {translateComponent('resources')}. {translateComponent('Please')} {translateComponent('refresh')}</p>
                                :
                                resources.length < 1 ? <p  style={{ fontSize: '0.8rem' }}>{translateComponent('No')} {translateComponent('resource')} {translateComponent('to')} {translateComponent('display')} {translateComponent('yet')}</p>
                                :
                                <div className="resource__Wrapper">
                                    {
                                        React.Children.toArray(resources.slice(0, 4).map(resource => {
                                            return <ResourceCard resource={resource} />
                                        }))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <h2 className="title__I">{translateComponent('Devotional')} {translateComponent('for')} {translateComponent('today')}</h2>
                        <DevotionalCard
                            className={''}
                            devotional={devotionals[currentDevotional]}
                            devotionalLoading={devotionalsLoading}
                            translateComponent={translateComponent}
                        />
                    </div>
                </section>
                
                <UpcomingEventsAndMission />
            </div>
        </>
    );
}

export default Resources;