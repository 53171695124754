import { createContext, useContext, useState } from "react";

const ResourcesContext = createContext({});

export const useResourcesContext = () => useContext(ResourcesContext);

export const ResourcesContextProvider = ({ children }) => {
    const [ resources, setResources ] = useState([]);
    const [ resourcesLoading, setResourcesLoading ] = useState(true);
    const [ resourcesLoaded, setResourcesLoaded ] = useState(false);

    return <>
        <ResourcesContext.Provider value={{
            resources,
            setResources,
            resourcesLoading,
            setResourcesLoading,
            resourcesLoaded,
            setResourcesLoaded,
        }}>
            { children }
        </ResourcesContext.Provider>
    </>
}