import { createContext, useContext, useState } from "react";

const SoulsContext = createContext({});

export const useSoulsContext = () => useContext(SoulsContext);

export const SoulsContextProvider = ({ children }) => {
    const [ souls, setSouls ] = useState([]);
    const [ soulsLoading, setSoulsLoading ] = useState(true);
    const [ soulsLoaded, setSoulsLoaded ] = useState(false);

    return <>
        <SoulsContext.Provider value={{
            souls,
            setSouls,
            soulsLoading,
            setSoulsLoading,
            soulsLoaded,
            setSoulsLoaded,
        }}>
            { children }
        </SoulsContext.Provider>
    </>
}