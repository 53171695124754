import {
  Button,
  Card,
  Flex,
  Select,
  NumberInput,
  Title,
  Table,
  Modal,
  Badge,
  Text,
  Skeleton,
  Center,
} from "@mantine/core";
import axiosClient from "../../services/axiosService";
import { toast } from "../../utils/helper";
import { useEffect, useState } from "react";
import { useUserContext } from "../../context/UserContext";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useDisclosure } from "@mantine/hooks";
import { formatDate } from "date-fns";

const DonationPage = () => {
  const { user } = useUserContext();
  const [donations, setDonations] = useState([]);
  const [loadingDonations, setLoadingDonations] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [details, setDetails] = useState({
    currency: "",
    amount: 0,
  });
  const [loading, setLoading] = useState(false);

  const fetchDonations = async () => {
    try {
      setLoadingDonations(true);
      const res = await axiosClient.get("/donations/all");
      setDonations(res.data || []);
      setLoadingDonations(false);
    } catch (error) {
      setLoadingDonations(false);
      toast(error?.response?.data?.message).error();
    }
  };

  useEffect(() => {
    fetchDonations();
  }, []);

  let config = {
    public_key: "FLWPUBK_TEST-dd2d16765409820ed23fc48067ad0278-X",
    tx_ref: `${Date.now()}`,
    // ...details,
    payment_options: "card",
    customer: {
      email: user?.email,
      phone_number: user?.phone || null,
      name: user?.name,
    },
    customizations: {
      title: "REON",
      description: "Partnership",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const handleDonation = async (e) => {
    e.preventDefault();
    try {
      config = { ...config, ...details };
      setLoading(true);
      handleFlutterPayment({
        callback: async (response) => {
          console.log("flw response", response);
          const transactionSuccessful = response.status === "successful";
          const res = await axiosClient.post("/donations/make-donation", {
            ...details,
            status: response.status,
          });
          if (res.data?.data) {
            toast(
              transactionSuccessful
                ? "Successfully made donation!"
                : "Payment failed!"
            ).success();
          }
          setDetails({
            amount: 0,
            currency: "",
          });
          setLoading(false);
          closePaymentModal();
        },
        onClose: () => {
          setLoading(false);
        },
      });
    } catch (error) {
      setLoading(false);
      toast(error?.response?.data?.message).error();
    }
  };

  const handleChange = (name, value) =>
    setDetails({ ...details, [name]: value });

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <>
      <Flex mih={"100%"} w={"100%"} py={"xs"} direction={"column"}>
        <Flex align={"center"} justify={"space-between"}>
          <Title my={"sm"}>Partnership</Title>
          <Button
            size="xs"
            className="bg-color-1 hover:bg-color-1"
            onClick={open}
          >
            Donate
          </Button>
        </Flex>
        <Card maw={"100%"} w={"100%"} shadow="sm">
          {loadingDonations ? (
            <>
              {Array(5)
                .fill(null)
                .map((item) => (
                  <Skeleton w={"100%"} h={40} mb={"xs"} />
                ))}
            </>
          ) : (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {donations?.length > 0 && (
                    <>
                      {donations.map((item) => (
                        <tr>
                          <td>{item?.amount}</td>
                          <td>{item?.currency}</td>
                          <td>{formatDate(item?.date, "dd, MMM YYYY")}</td>
                          <td>
                            <Badge
                              color={
                                item?.status === "success" ? "green" : "red"
                              }
                              size="sm"
                              variant="filled"
                            >
                              {item.status === "success"
                                ? "Successful"
                                : "Failed"}
                            </Badge>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
              {donations?.length === 0 && (
                <Center my={"xl"}>
                  <Text>No data found</Text>
                </Center>
              )}
            </>
          )}
        </Card>
      </Flex>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={<Text fw={600}>New Donation</Text>}
          centered
        >
          <form onSubmit={handleDonation}>
            <NumberInput
              variant="filled"
              onChange={(value) => handleChange("amount", value)}
              label="Amount"
              // defaultValue={}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              formatter={(value) =>
                !Number.isNaN(parseFloat(value))
                  ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  : ""
              }
              mb={"xs"}
            />

            <Select
              allowDeselect={false}
              value={details.currency}
              onChange={(value) => handleChange("currency", value)}
              required
              withinPortal
              mb={"xs"}
              variant="filled"
              label="Select Currency"
              // placeholder="Pick one"
              data={[
                { value: "USD", label: "DOLLARS" },
                { value: "GBP", label: "POUNDS" },
                { value: "ZAR", label: "RANDS" },
                { value: "NGN", label: "NAIRA" },
              ]}
            />
            <Button
              type="submit"
              loading={loading}
              className="bg-color-1 hover:bg-color-1"
              //   size="xs"
            >
              Donate Now
            </Button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default DonationPage;
