import axios from "./axiosService";


export const register = async (user) => await axios.post('/users', user);

export const login = async (user) => await axios.post('/users/login', user)

export const verifyAccount = async (token) => await axios.get(`/users/verify-account/${token}`)

export const getUserProfile = async () => await axios.get('/users/profile');

export const accountSetup = async (data) => await axios.put('/users/account-setup', data)

export const inviteMinisters = async (emails) => await axios.post(`/users/invite-ministers`, { users: emails })

export const getEnlistedMinisters = async () => await axios.get(`/users/enlisted-ministers`);

export const updateAccount = async (data) => await axios.put(`/users/update-account`, data)

export const updateMinistry = async (data) => await axios.put(`/users/update-ministry`, data)

export const updatePassword = async (data) => await axios.put(`/users/update-password`, data)

export const getAllMinisters = async () => await axios.get(`/users/all-ministers`);