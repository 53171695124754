import axios from "./axiosService";

export const addNewSoul = async (data) => {
    return await axios.post(`/souls/new-soul`, data)
}

export const getAdminSouls = async () => {
    return await axios.get(`/souls/admin-souls`)
}

export const getMinisterSouls = async () => {
    return await axios.get(`/souls/minister-souls`)
}

export const updateSoulForMinister = async (soulId, data) => {
    return await axios.patch(`/souls/soul/${soulId}`, data)
}

export const updateSoulForAdmin = async (soulId, data) => {
    return await axios.patch(`/souls/admin-soul/${soulId}`, data)
}
