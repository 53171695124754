import React from "react";
import {
  Text,
  Flex,
  Image,
  Skeleton,
  Paper,
  Tooltip,
  Modal,
} from "@mantine/core";
import freeTrial from "../../images/free-trial.png";
import { useLocation } from "react-router-dom";
import { dashboardRoutes } from "../../utils/routes";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { getMinisterEvents } from "../../services/eventServices";
import { toast, truncateStringAtMiddle } from "../../utils/helper";
import { formatDate } from "date-fns";
import { useDisclosure } from "@mantine/hooks";

// ${pathname === dashboardRoutes.resources && "xl:-mt-20 mt-10"}

const Events = ({ pathname }) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [loadingEvents, setLoadingEvents] = useState(false);

  const fetchEvents = async () => {
    try {
      setLoadingEvents(true);
      const res = await getMinisterEvents();
      setEvents(res?.data);
      setLoadingEvents(false);
    } catch (error) {
      setLoadingEvents(false);
      toast(error?.response?.data?.message).error();
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <Flex
        className={`col-span-12 rounded-xl p-[8px] h-[400px] w-full ${
          pathname === dashboardRoutes["/"] && "xl:col-span-4"
        }
      ${
        pathname === dashboardRoutes.resources ||
        pathname === dashboardRoutes.my_network
          ? "mt-5"
          : ""
      }
         `}
      >
        <Flex direction={"column"} className="w-full">
          <Text className="font-2 font-thin text-lg text-center" mb={"xs"}>
            {t("Upcoming")} {t("Missions")} & {t("Events")}
          </Text>

          {loadingEvents ? (
            <>
              {Array(5)
                .fill(null)
                .map((item) => (
                  <Skeleton h={50} w={"100%"} mb={"xs"} />
                ))}
            </>
          ) : (
            <>
              {events.map((item) => (
                <Paper
                  shadow="sm"
                  mb={"xs"}
                  mih={50}
                  onClick={() => {
                    setSelectedEvent(item);
                    open();
                  }}
                  p={"sm"}
                  className="border-r-4 border-color-1 cursor-pointer"
                >
                  <Flex className="flex" direction={"column"}>
                    <Tooltip
                      label={item?.title}
                      withArrow
                      multiline
                      maw={250}
                      position="bottom"
                      hidden={item?.title?.length < 40}
                    >
                      <Text size={"md"} fw={600}>
                        {truncateStringAtMiddle(item?.title, 40)}
                      </Text>
                    </Tooltip>
                    <Text size={"xs"} c={"dimmed"}>
                      {" "}
                      {formatDate(item?.date, "dd MMMM, yyyy")} at{" "}
                      {formatDate(item?.date, "hh:mma")}
                    </Text>
                  </Flex>
                </Paper>
              ))}
            </>
          )}
        </Flex>
      </Flex>

      {opened && selectedEvent && (
        <Modal
          opened={opened}
          onClose={() => {
            close();
            setSelectedEvent({});
          }}
          classNames={{
            header: "border-b mb-[15px]",
          }}
          title={
            <Text fw={600}>
              {truncateStringAtMiddle(selectedEvent?.title, 40)}
            </Text>
          }
          centered
        >
          {/* Modal content */}
          <Text c={"dimmed"} mb={"xs"}>
            <span className="text-black font-semibold">Title: </span>
            <span className="text-sm">{selectedEvent?.title}</span>
          </Text>
          <Text c={"dimmed"} mb={"xs"}>
            <span className="text-black font-semibold">Date: </span>
            <span className="text-sm">
              {" "}
              {formatDate(selectedEvent?.date, "dd MMMM, yyyy")} at{" "}
              {formatDate(selectedEvent?.date, "hh:mma")}
            </span>
          </Text>
          <Text c={"dimmed"} mb={"xs"}>
            <span className="text-black font-semibold">Link/Address: </span>
            <span className="text-sm">{selectedEvent?.link}</span>
          </Text>
          <Text c={"dimmed"} mb={"xs"}>
            <span className="text-black font-semibold">Details: </span>
            <span className="text-sm">{selectedEvent?.details}</span>
          </Text>
        </Modal>
      )}
    </>
  );
};

function UpcomingEventsAndMission(props) {
  const { pathname } = useLocation();
  return (
    <>
      {pathname === dashboardRoutes.resources ||
      pathname === dashboardRoutes.my_network ? (
        <div className="grid col-span-12 xl:col-span-4 gap-1">
          <div className="col-span-12 xl:mb-0">
            <Image src={freeTrial} width={"100%"} fit="contain" />
          </div>
          <Events pathname={pathname} />
        </div>
      ) : (
        <Events pathname={pathname} />
      )}
    </>
  );
}

export default UpcomingEventsAndMission;
