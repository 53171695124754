import React, { useEffect } from "react";
import { useResourcesContext } from "../../context/ResourcesContext";
import { addNewResource, getAdminResources } from "../../services/resourcesService";
import styles from './styles.module.css';
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useState } from "react";
import { toast } from "../../utils/helper";
import ResourceCard from "./ResourceCard";

const AdminResourcesPage = ({ translateComponent }) => {
    const {
        resources,
        setResources,
        resourcesLoading,
        setResourcesLoading,
        resourcesLoaded,
        setResourcesLoaded,
    } = useResourcesContext();
    const [ showmodal, setShowModal ] = useState(false);
    const [ newResource, setNewResource ] = useState(initialState);
    const [ filesAdded, setFilesAdded ] = useState(null);
    const [ newResourceLoading, setNewResourceLoading ] = useState(false);
    
    useEffect(() => {
        if (!resourcesLoaded) {
            setResourcesLoading(true);

            getAdminResources().then(res => {
                setResourcesLoading(false);
                setResourcesLoaded(true);

                setResources(res.data);
            }).catch(err => {
                console.log(err);
                setResourcesLoading(false);
            })
        }

    }, [])

    const handleUpdateNewResource = (itemKey, itemVal) => {
        setNewResource((prevVal) => {
            return {
                ...prevVal,
                [itemKey]: itemVal
            }
        })
    }

    const handleAddFiles = (val) => {
        if (val.length > 10) return toast("You can only upload up to 10 files").error()
        setFilesAdded(val);
    }

    const handleAddNewResource = async () => {
        if (newResource.title.length < 1) return toast('Please enter a value for title').error();
        if (newResource.resourceType.length < 1) return toast('Please select a resource type').error();
        if (!filesAdded) return toast('Please add a file').error();

        setNewResourceLoading(true);

        const formData = new FormData();
        Array.from(filesAdded).forEach(file => {
            formData.append(`${newResource.resourceType}`, file);
        })
        Object.keys(newResource || {}).forEach(key => {
            formData.append(`${key}`, newResource[key]);
        })

        try {
            const res = await (await addNewResource(formData, newResource.resourceType)).data;

            setResources([res, ...resources]);
            setNewResourceLoading(false);
            setNewResource(initialState);
            setFilesAdded(null);
            setShowModal(false);
            toast('Successfully added new resource!').success();
            
        } catch (error) {
            console.log(error);
            setNewResourceLoading(false);
            toast(
                error.response ?
                    error.response.status === 500 ?
                        'Something went wrong while trying to add your resource'
                    :
                    error.response.data.message
                :
                'Something went wrong while trying to add your resource'
            ).error();
        }
    }
    
    return <>
        <main className={styles.main__Content}>
            <section>
                <div className={styles.top__Content}>
                    <h1>{translateComponent('Your')} {translateComponent('resources')}</h1>
                    <button className={styles.add__New__Btn} onClick={() => setShowModal(true)}>
                        <AiOutlinePlus />
                        <span>{translateComponent('Add')}</span>
                    </button>
                </div>

                {
                    resourcesLoading ? <p  style={{ fontSize: '0.8rem' }}>{translateComponent('Loading')} {translateComponent('Resources')}...</p> :
                    !resourcesLoaded ? <p  style={{ fontSize: '0.8rem' }}>{translateComponent('Resources')} {translateComponent('failed')} {translateComponent('to')} {translateComponent('load')}. {translateComponent('Please')} {translateComponent('refresh')} {translateComponent('this')} {translateComponent('page')}</p> :
                    resources.length < 1 ? <>
                        <p  style={{ fontSize: '0.8rem' }}>{translateComponent('You')} {translateComponent('have')} {translateComponent('not')} {translateComponent('created')} {translateComponent('any')} {translateComponent('resources')} {translateComponent('yet')}</p>
                    </> :
                    <>
                        <div className={styles.resource__Wrapper}>
                            {
                                React.Children.toArray(resources.map(resource => {
                                    return <>
                                        <ResourceCard resource={resource} />
                                    </>
                                }))
                            }
                        </div>
                    </>
                }

                {
                    showmodal && <div className={styles.modal__Wrapper}>
                        <div className={styles.modal__Container}>
                            <div className={styles.modal__Close__Container} onClick={() => setShowModal(false)}>
                                <AiOutlineClose className={styles.modal__Close__Icon} />
                            </div>
                            <div className={styles.modal__Content}>
                                <h1>Add new resource</h1>

                                <label>
                                    <span>Title</span>
                                    <input 
                                        type="text"
                                        value={newResource.title}
                                        name="title"
                                        onChange={({ target }) => handleUpdateNewResource(target.name, target.value)}
                                    />
                                </label>
                                
                                <p className={styles.input__Item}>
                                    <span>Select type of resource</span>
                                    <select 
                                        defaultValue={newResource.resourceType} 
                                        onChange={({ target }) => {
                                                handleUpdateNewResource('resourceType', target.value);
                                                setFilesAdded(null);
                                            }
                                        }
                                    >
                                        <option value={''} disabled>Select</option>
                                        {
                                            React.Children.toArray(resourceTypes.map(resource => {
                                                return <option value={resource}>{resource}</option>
                                            }))
                                        }
                                    </select>
                                </p>

                                <label>
                                    <span>
                                        {
                                            filesAdded ? 
                                            "Added files"
                                            :
                                            "Select files"
                                        }
                                    </span>
                                    {
                                        filesAdded ? <div className={styles.file__Added__Wrapper}>
                                            <AiOutlineClose className={styles.cancel__Icon} onClick={() => setFilesAdded(null)} />
                                            <>
                                                <div className={styles.file__Added__Content}>
                                                    {
                                                        React.Children.toArray(Array.from(filesAdded).map(file => {
                                                            return <>
                                                                <p>{file.name}</p>
                                                            </>
                                                        }))
                                                    }
                                                </div>
                                            </>
                                        </div> :
                                        <input 
                                            type="file"
                                            // value={filesAdded}
                                            name="files"
                                            disabled={newResource.resourceType.length < 1 ? true : false}
                                            onChange={({ target }) => handleAddFiles(target.files)}
                                            accept={
                                                newResource.resourceType === 'audio' ? 'audio/*'
                                                :
                                                newResource.resourceType === 'picture' ? 'image/*'
                                                :
                                                newResource.resourceType === 'document' ? '.pdf'
                                                :
                                                newResource.resourceType === 'video' ? 'image/gif,video/*'
                                                :
                                                ''
                                            }
                                            multiple
                                        />
                                    }
                                    
                                </label>

                                <button 
                                    className={styles.add__New__Btn} 
                                    disabled={newResourceLoading}
                                    onClick={handleAddNewResource}
                                >
                                    {
                                        newResourceLoading ? 
                                        "Adding..." :
                                        "Add"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </main>
    </>
}

const initialState = {
    title: '',
    resourceType: '',
}

const resourceTypes = [
    'audio',
    'picture',
    'document',
    'video',
]

export default AdminResourcesPage;