/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../images/logo.png";
import {
  Navbar,
  UnstyledButton,
  Group,
  Text,
  Image,
  Flex,
  Card,
  ScrollArea,
} from "@mantine/core";
import { BsHandbagFill, BsFolder, BsChatDots, BsGift } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { CiSettings } from "react-icons/ci";
import {
  AiOutlineClose,
  AiOutlineFolderOpen,
  AiOutlineRead,
} from "react-icons/ai";
import { IoMdHeadset } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import {
  useUserContext,
  LOGOUT_USER,
  USER_PROFILE,
} from "../../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { dashboardRoutes } from "../../utils/routes";
import { FaHandsHelping } from "react-icons/fa";
import "./styles.css";
import { FaPeopleRoof } from "react-icons/fa6";
import { LuCalendarDays, LuChurch } from "react-icons/lu";
import { LiaHandsHelpingSolid } from "react-icons/lia";

function MainLink({ icon, label, link, className }) {
  const navigate = useNavigate();
  return (
    <UnstyledButton
      mb={5}
      onClick={() => navigate(link)}
      sx={(theme) =>
        className && className === "active"
          ? {
              display: "block",
              width: "100%",
              padding: 10,
              borderRadius: 10,
              backgroundColor: "#FAFAFA !important",

              "&:hover": {
                backgroundColor: "#F39B17",
              },
            }
          : {
              display: "block",
              width: "100%",
              padding: 10,
              borderRadius: 10,

              "&:hover": {
                backgroundColor: "#F39B17",
              },
            }
      }
    >
      <Group>
        {/* <ThemeIcon color={color} variant="light"> */}
        {className && className === "active" ? (
          <div className="active__Icon">{icon}</div>
        ) : (
          icon
        )}
        {/* </ThemeIcon> */}

        <Text
          size="sm"
          className={
            className && className === "active" ? "text-black" : "text-white"
          }
        >
          {label}
        </Text>
      </Group>
    </UnstyledButton>
  );
}

const Sidebar = ({ opened, setOpened, isAdminUser, translateComponent }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const data = [
    {
      icon: (
        <svg
          className="fill-current"
          width="20"
          height="20"
          viewBox="0 0 18 18"
          fill="none"
          color="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.10322 0.956299H2.53135C1.5751 0.956299 0.787598 1.7438 0.787598 2.70005V6.27192C0.787598 7.22817 1.5751 8.01567 2.53135 8.01567H6.10322C7.05947 8.01567 7.84697 7.22817 7.84697 6.27192V2.72817C7.8751 1.7438 7.0876 0.956299 6.10322 0.956299ZM6.60947 6.30005C6.60947 6.5813 6.38447 6.8063 6.10322 6.8063H2.53135C2.2501 6.8063 2.0251 6.5813 2.0251 6.30005V2.72817C2.0251 2.44692 2.2501 2.22192 2.53135 2.22192H6.10322C6.38447 2.22192 6.60947 2.44692 6.60947 2.72817V6.30005Z"
            fill=""
          />
          <path
            d="M15.4689 0.956299H11.8971C10.9408 0.956299 10.1533 1.7438 10.1533 2.70005V6.27192C10.1533 7.22817 10.9408 8.01567 11.8971 8.01567H15.4689C16.4252 8.01567 17.2127 7.22817 17.2127 6.27192V2.72817C17.2127 1.7438 16.4252 0.956299 15.4689 0.956299ZM15.9752 6.30005C15.9752 6.5813 15.7502 6.8063 15.4689 6.8063H11.8971C11.6158 6.8063 11.3908 6.5813 11.3908 6.30005V2.72817C11.3908 2.44692 11.6158 2.22192 11.8971 2.22192H15.4689C15.7502 2.22192 15.9752 2.44692 15.9752 2.72817V6.30005Z"
            fill=""
          />
          <path
            d="M6.10322 9.92822H2.53135C1.5751 9.92822 0.787598 10.7157 0.787598 11.672V15.2438C0.787598 16.2001 1.5751 16.9876 2.53135 16.9876H6.10322C7.05947 16.9876 7.84697 16.2001 7.84697 15.2438V11.7001C7.8751 10.7157 7.0876 9.92822 6.10322 9.92822ZM6.60947 15.272C6.60947 15.5532 6.38447 15.7782 6.10322 15.7782H2.53135C2.2501 15.7782 2.0251 15.5532 2.0251 15.272V11.7001C2.0251 11.4188 2.2501 11.1938 2.53135 11.1938H6.10322C6.38447 11.1938 6.60947 11.4188 6.60947 11.7001V15.272Z"
            fill=""
          />
          <path
            d="M15.4689 9.92822H11.8971C10.9408 9.92822 10.1533 10.7157 10.1533 11.672V15.2438C10.1533 16.2001 10.9408 16.9876 11.8971 16.9876H15.4689C16.4252 16.9876 17.2127 16.2001 17.2127 15.2438V11.7001C17.2127 10.7157 16.4252 9.92822 15.4689 9.92822ZM15.9752 15.272C15.9752 15.5532 15.7502 15.7782 15.4689 15.7782H11.8971C11.6158 15.7782 11.3908 15.5532 11.3908 15.272V11.7001C11.3908 11.4188 11.6158 11.1938 11.8971 11.1938H15.4689C15.7502 11.1938 15.9752 11.4188 15.9752 11.7001V15.272Z"
            fill=""
          />
        </svg>
      ),
      label: "Dashboard",
      link: dashboardRoutes["/"],
    },

    {
      icon: <BsHandbagFill className="text-white" size={20} />,
      label: "Outreach Manager",
      link: dashboardRoutes.outreaches,
    },
    {
      icon: <LuChurch className="text-white" size={20} />,
      label: "Church Manager",
      link: "/dashboard/church-manager",
    },
    {
      icon: <FiUsers className="text-white" size={20} />,
      label: "Souls Manager",
      link: dashboardRoutes.souls,
    },
    {
      icon: <BsFolder className="text-white" size={20} />,
      label: "Resources",
      link: "/dashboard/resources/all",
    },
    {
      icon: <BsChatDots className="text-white" size={20} />,
      label: "My network",
      link: dashboardRoutes.my_network,
    },
    {
      icon: <FaHandsHelping size={20} className="text-white" />,
      label: "Partnership",
      link: "/partnership",
    },
    {
      icon: <CiSettings className="text-white" size={20} />,
      label: "Settings",
      link: dashboardRoutes.settings,
    },
  ];

  const adminData = [
    {
      icon: (
        <svg
          className="fill-current"
          width="20"
          height="20"
          viewBox="0 0 18 18"
          fill="none"
          color="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.10322 0.956299H2.53135C1.5751 0.956299 0.787598 1.7438 0.787598 2.70005V6.27192C0.787598 7.22817 1.5751 8.01567 2.53135 8.01567H6.10322C7.05947 8.01567 7.84697 7.22817 7.84697 6.27192V2.72817C7.8751 1.7438 7.0876 0.956299 6.10322 0.956299ZM6.60947 6.30005C6.60947 6.5813 6.38447 6.8063 6.10322 6.8063H2.53135C2.2501 6.8063 2.0251 6.5813 2.0251 6.30005V2.72817C2.0251 2.44692 2.2501 2.22192 2.53135 2.22192H6.10322C6.38447 2.22192 6.60947 2.44692 6.60947 2.72817V6.30005Z"
            fill=""
          />
          <path
            d="M15.4689 0.956299H11.8971C10.9408 0.956299 10.1533 1.7438 10.1533 2.70005V6.27192C10.1533 7.22817 10.9408 8.01567 11.8971 8.01567H15.4689C16.4252 8.01567 17.2127 7.22817 17.2127 6.27192V2.72817C17.2127 1.7438 16.4252 0.956299 15.4689 0.956299ZM15.9752 6.30005C15.9752 6.5813 15.7502 6.8063 15.4689 6.8063H11.8971C11.6158 6.8063 11.3908 6.5813 11.3908 6.30005V2.72817C11.3908 2.44692 11.6158 2.22192 11.8971 2.22192H15.4689C15.7502 2.22192 15.9752 2.44692 15.9752 2.72817V6.30005Z"
            fill=""
          />
          <path
            d="M6.10322 9.92822H2.53135C1.5751 9.92822 0.787598 10.7157 0.787598 11.672V15.2438C0.787598 16.2001 1.5751 16.9876 2.53135 16.9876H6.10322C7.05947 16.9876 7.84697 16.2001 7.84697 15.2438V11.7001C7.8751 10.7157 7.0876 9.92822 6.10322 9.92822ZM6.60947 15.272C6.60947 15.5532 6.38447 15.7782 6.10322 15.7782H2.53135C2.2501 15.7782 2.0251 15.5532 2.0251 15.272V11.7001C2.0251 11.4188 2.2501 11.1938 2.53135 11.1938H6.10322C6.38447 11.1938 6.60947 11.4188 6.60947 11.7001V15.272Z"
            fill=""
          />
          <path
            d="M15.4689 9.92822H11.8971C10.9408 9.92822 10.1533 10.7157 10.1533 11.672V15.2438C10.1533 16.2001 10.9408 16.9876 11.8971 16.9876H15.4689C16.4252 16.9876 17.2127 16.2001 17.2127 15.2438V11.7001C17.2127 10.7157 16.4252 9.92822 15.4689 9.92822ZM15.9752 15.272C15.9752 15.5532 15.7502 15.7782 15.4689 15.7782H11.8971C11.6158 15.7782 11.3908 15.5532 11.3908 15.272V11.7001C11.3908 11.4188 11.6158 11.1938 11.8971 11.1938H15.4689C15.7502 11.1938 15.9752 11.4188 15.9752 11.7001V15.272Z"
            fill=""
          />
        </svg>
      ),
      label: "Dashboard",
      link: "/admin-dashboard",
    },
    {
      icon: <AiOutlineRead className="text-white" size={20} />,
      label: "Devotionals",
      link: "/admin-devotional/all",
    },
    {
      icon: <FiUsers className="text-white" size={20} />,
      label: "Souls",
      link: "/admin-souls/all",
    },
    {
      icon: <FaPeopleRoof className="text-white" size={20} />,
      label: "Ministers",
      link: "/admin-ministers/all",
    },
    {
      icon: <LiaHandsHelpingSolid className="text-white" size={20}/>,
      label: "Outreaches",
      link: "/outreaches",
    },
    {
      icon: <LuChurch className="text-white" size={20} />,
      label: "Church Manager",
      link: "/churches",
    },
    {
      icon: <AiOutlineFolderOpen className="text-white" size={20} />,
      label: "Resources",
      link: "/admin-resources/all",
    },
    {
      icon: <LuCalendarDays className="text-white" size={20} />,
      label: "Events",
      link: "/events",
    },
    {
      icon: <CiSettings className="text-white" size={20} />,
      label: "Settings",
      link: "/admin-settings",
    },
  ];

  const { userDispatch, userTokenDetailsDispatch } = useUserContext();

  const logout = () => {
    userTokenDetailsDispatch({
      type: LOGOUT_USER,
    });

    userDispatch({
      type: USER_PROFILE,
      payload: {},
    });
    navigate("/login");
  };
  return (
    <Navbar
      p="md"
      // className='overflow-y-scroll'
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 250 }}
      bg={"#07499e"}
    >
      <ScrollArea className="min-h-[90vh]">
        <AiOutlineClose
          onClick={() => setOpened(!opened)}
          className="block md:hidden ml-2 cursor-pointer"
          size={30}
          color="white"
        />

        <Navbar.Section>
          <Group position="center">
            <Image src={logo} width={200} />
          </Group>
        </Navbar.Section>

        <Navbar.Section grow mt="md" mb={30}>
          {isAdminUser ? (
            <>
              {adminData.map((item, index) => (
                <MainLink
                  icon={item.icon}
                  label={translateComponent(item.label)}
                  key={index}
                  link={item.link}
                  className={pathname === item?.link ? "active" : ""}
                />
              ))}
            </>
          ) : (
            <>
              {data.map((item, index) => (
                <MainLink
                  icon={item.icon}
                  label={translateComponent(item.label)}
                  key={index}
                  link={item.link}
                  className={pathname === item?.link ? "active" : ""}
                />
              ))}
            </>
          )}
        </Navbar.Section>

        <Navbar.Section mt={20}>
          <Card className="bg-[#00000080] rounded-lg">
            <Flex direction={"row"} align={"center"}>
              <BsGift className="text-color-1" size={18} /> &nbsp;
              <Text
                className="text-color-1"
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => navigate("/dashboard/my-network")}
              >
                REON {translateComponent("Referral")}{" "}
                {translateComponent("Network")}
              </Text>
            </Flex>
            {/* 
                        <Flex direction={'row'} align={'center'} mt={10}>
                            <Text className='text-color-1'>Share, engage ministers</Text> &nbsp;
                            <PiCaretRightBold className='text-color-1' />
                        </Flex> */}
          </Card>
        </Navbar.Section>

        <Navbar.Section mt={10}>
          <Card className="bg-[#00000080] rounded-lg">
            <Flex direction={"row"} align={"center"}>
              <IoMdHeadset className="text-white" size={18} /> &nbsp;
              <Text className="text-white" style={{ fontSize: "15px" }}>
                <a href="mailto:support@reoninternational.org">
                  {translateComponent("Contact")}{" "}
                  {translateComponent("Support")}
                </a>
              </Text>
            </Flex>
          </Card>
        </Navbar.Section>

        <Navbar.Section mt={50}>
          <Flex direction={"row"} align={"center"} className="cursor-pointer">
            <IoLogOut className="text-white" size={20} />
            &nbsp;
            <Text className="text-white" onClick={logout}>
              {translateComponent("Logout")}
            </Text>
          </Flex>
        </Navbar.Section>
      </ScrollArea>
    </Navbar>
  );
};

export default Sidebar;
