
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext, userToken } from '../../context/UserContext';
import { useLocation } from 'react-router-dom';

function PublicRoutes({ children }) {
    const token = userToken()
    const { user } = useUserContext()

    const { search } = useLocation()
    const redirectUrl = new URLSearchParams(search).get("redirectUrl");

    if (user?.name || token) {
        return <Navigate to={
            user?.accountType === 'ADMIN' ? '/admin-dashboard' :
            redirectUrl ? redirectUrl : '/dashboard'
        } />
    }
    return children
}

export default PublicRoutes;