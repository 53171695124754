import { createContext, useContext, useState } from "react";

const EventContext = createContext({});

export const useEventsContext = () => useContext(EventContext);

export const EventContextProvider = ({ children }) => {
    const [ events, setEvents ] = useState([]);
    const [ eventsLoading, setEventsLoading ] = useState(true);
    const [ eventsLoaded, setEventsLoaded ] = useState(false);

    return <>
        <EventContext.Provider value={{
            events,
            setEvents,
            eventsLoading,
            setEventsLoading,
            eventsLoaded,
            setEventsLoaded,
        }}>
            { children }
        </EventContext.Provider>
    </>
}