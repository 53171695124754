import { Loader } from "@mantine/core";

const AppLoader = () => {
    return (
        <div className="flex h-screen items-center justify-center bg-white">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-color-1 border-t-transparent"></div>
            {/* <Loader color="#F39B17" variant="bars" /> */}
            {/* <Loader color="orange" variant="bars" />; */}
        </div>
    );
};

export default AppLoader;
