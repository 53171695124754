import { createContext, useContext, useState } from "react";

const DevotionalContext = createContext({});

export const useDevotionalContext = () => useContext(DevotionalContext);

export const DevotionalContextProvider = ({ children }) => {
    const [ devotionals, setDevotionals ] = useState([]);
    const [ devotionalsLoading, setDevotionalsLoading ] = useState(true);
    const [ devotionalsLoaded, setDevotionalsLoaded ] = useState(false);
    const [ bibles, setBibles ] = useState([]);
    const [ biblesLoading, setBiblesLoading ] = useState(true);
    const [ biblesLoaded, setBiblesLoaded ] = useState(false);
    const [ bibleSelected, setBibleSelected ] = useState(null);
    const [ bookSelected, setBookSelected ] = useState(null);
    const [ chapterSelected, setChapterSelected ] = useState(null);
    const [ verseSelected, setVerseSelected ] = useState(null);
    const [ passageSelected, setPassageSelected ] = useState(null);
    const [ availableBooks, setAvailableBooks ] = useState([]);
    const [ availableBooksLoading, setAvailableBooksLoading ] = useState(false);
    const [ availableChapters, setAvailableChapters ] = useState([]);
    const [ availableChaptersLoading, setAvailableChaptersLoading ] = useState(false);
    const [ availableVerses, setAvailableVerses ] = useState([]);
    const [ availableVersesLoading, setAvailableVersesLoading ] = useState(false);
    const [ passageContentLoading, setPassageContentLoading ] = useState(false);

    const resetStateForNewDevotionalPopup = () => {
        setBibleSelected(null);
        setBookSelected(null);
        setChapterSelected(null);
        setVerseSelected(null);
        setPassageSelected(null);
    }

    return <>
        <DevotionalContext.Provider value={{
            devotionals,
            setDevotionals,
            devotionalsLoading,
            setDevotionalsLoading,
            devotionalsLoaded,
            setDevotionalsLoaded,
            bibles,
            setBibles,
            biblesLoading,
            setBiblesLoading,
            biblesLoaded,
            setBiblesLoaded,
            bibleSelected,
            setBibleSelected,
            bookSelected,
            setBookSelected,
            chapterSelected,
            setChapterSelected,
            verseSelected,
            setVerseSelected,
            passageSelected,
            setPassageSelected,
            availableBooks,
            setAvailableBooks,
            availableBooksLoading,
            setAvailableBooksLoading,
            availableChapters,
            setAvailableChapters,
            availableChaptersLoading,
            setAvailableChaptersLoading,
            availableVerses,
            setAvailableVerses,
            availableVersesLoading,
            setAvailableVersesLoading,
            passageContentLoading,
            setPassageContentLoading,
            resetStateForNewDevotionalPopup,
        }}>
            { children }
        </DevotionalContext.Provider>
    </>
}