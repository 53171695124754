import React, { useEffect, useState } from 'react';
import Card from '../../components/card';
import Raphsody from '../../components/dashboard/Raphsody';
import UpcomingEventsAndMission from '../../components/dashboard/UpcomingEventsAndMission';
import DashboardModal from '../../components/dashboard/DashboardModal';
import DevotionalCard from '../../components/DevotionalCard/DevotionalCard';
import { getDevotionalsForMinister } from '../../services/devotionalService';
import { useDevotionalContext } from '../../context/DevotionalContext';
import { Text } from '@mantine/core';
import { useUserContext } from '../../context/UserContext';
import { useLocation } from 'react-router-dom';
import { dashboardRoutes } from '../../utils/routes';
import { useSoulsContext } from '../../context/SoulsContext';
import { getMinisterSouls } from '../../services/soulServices';
import { useResourcesContext } from '../../context/ResourcesContext';
import { getMinisterResources } from '../../services/resourcesService';
import { useEventsContext } from '../../context/EventContext';
import { getMinisterEvents } from '../../services/eventServices';
import { useOutreachContext } from '../../context/OutreachContext';
import { getMinisterOutreaches } from '../../services/outreachServices';


function Dashboard({ translateComponent }) {
    const { user } = useUserContext()
    const { pathname } = useLocation()
    const { ministersEnlisted } = useUserContext()

    const cardOneData = {
        number: ministersEnlisted?.length,
        title: translateComponent('Ministers') + ' ' + translateComponent('enlisted'),
        view: translateComponent('Enlist') +  ' ' + translateComponent('ministers'),
        path: dashboardRoutes.my_network
    }

    const cardTwoData = {
        number: "600",
        title: translateComponent('Souls') +  ' ' + translateComponent('Won'),
        view: translateComponent('Track') +  ' ' + translateComponent('Souls'),
        path: dashboardRoutes.souls
    }

    const [currentDevotional, setCurrentDevotional] = useState(null);
    const {
        devotionals,
        setDevotionals,
        setDevotionalsLoaded,
        setDevotionalsLoading,
        devotionalsLoading,
        devotionalsLoaded,
    } = useDevotionalContext();

    const {
        souls,
        setSouls,
        setSoulsLoaded,
        setSoulsLoading,
        soulsLoaded,
    } = useSoulsContext();

    
    const {
        setResources,
        setResourcesLoaded,
        setResourcesLoading,
        resourcesLoaded,
    } = useResourcesContext();

    const {
        setEvents,
        setEventsLoaded,
        setEventsLoading,
        eventsLoaded,
    } = useEventsContext();
    
    const {
        setOutreaches,
        setOutreachesLoading,
        outreachesLoaded,
        setOutreachesLoaded,
    } = useOutreachContext();

    const today = new Date();

    useEffect(() => {
        if (devotionalsLoaded) {
            const dailyDevotionalIndex = devotionals.findIndex(
                devotional =>
                    devotional.dateForDevotional &&
                    !(new Date(devotional.dateForDevotional) == 'Invalid Date') &&
                    new Date(devotional.dateForDevotional).toDateString() === today.toDateString()
            );
            if (dailyDevotionalIndex === -1) return

            setCurrentDevotional(dailyDevotionalIndex);
        }

        if (!devotionalsLoaded) {
            getDevotionalsForMinister().then(res => {
                const devotionalsFetched = res.data;
    
                setDevotionals(devotionalsFetched);
                setDevotionalsLoading(false);
                setDevotionalsLoaded(true);
    
                const dailyDevotionalIndex = devotionalsFetched.findIndex(
                    devotional =>
                        devotional.dateForDevotional &&
                        !(new Date(devotional.dateForDevotional) == 'Invalid Date') &&
                        new Date(devotional.dateForDevotional).toDateString() === today.toDateString()
                );
                if (dailyDevotionalIndex === -1) return
    
                setCurrentDevotional(dailyDevotionalIndex);
            }).catch(err => {
                console.log(err);
                setDevotionalsLoading(false);
            })
        }

        if (!soulsLoaded) {
            getMinisterSouls().then(res => {
                setSouls(res.data);

                setSoulsLoaded(true);
                setSoulsLoading(false);
            }).catch(err => {
                console.log(err);
                setSoulsLoading(false);
            })
        }

        if (!resourcesLoaded) {
            getMinisterResources().then(res => {
                setResources(res.data);

                setResourcesLoaded(true);
                setResourcesLoading(false);
            }).catch(err => {
                console.log(err);
                setResourcesLoading(false);
            })
        }

        if (!eventsLoaded) {
            getMinisterEvents().then(res => {
                setEvents(res.data);

                setEventsLoaded(true);
                setEventsLoading(false);
            }).catch(err => {
                console.log(err);
                setEventsLoading(false);
            })
        }

        if (!outreachesLoaded) {
            getMinisterOutreaches().then(res => {
                setOutreaches(res.data);

                setOutreachesLoaded(true);
                setOutreachesLoading(false);
            }).catch(err => {
                console.log(err);
                setOutreachesLoading(false);
            })
        }
        
    }, [])



    return (
        <>

            {user?.name && user?.title && pathname === dashboardRoutes['/'] ? <Text className='font-2 font-medium text-[25px] mb-2'>{translateComponent('Welcome')}, {`${user?.title} ${user?.name}`}</Text> : null}

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-[7.5]">
                <Card number={cardOneData.number} title={cardOneData.title} view={cardOneData.view} path={cardOneData.path} translateComponent={translateComponent} />
                <Card number={souls.length} title={cardTwoData.title} view={cardTwoData.view} translateComponent={translateComponent} path={cardTwoData.path} />
                <Card showUpload={true} translateComponent={translateComponent} />
            </div>

            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-[7.5px] 2xl:gap-[7.5]">
                {/* <Raphsody /> */}
                <DevotionalCard
                    className="col-span-12 rounded-xl xl:col-span-8"
                    devotional={devotionals[currentDevotional]}
                    devotionalLoading={devotionalsLoading}
                    translateComponent={translateComponent}
                />
                <UpcomingEventsAndMission />
            </div>



            <DashboardModal />
        </>

    );
}

export default Dashboard;